export function getUserListByGroupId(userList, NodeGroupId, labelKey = 'videoTitle') {
  let users = userList.filter(user => user.groupId === NodeGroupId)
  let arrUser = users.map(user => {
    let showName = labelKey === 'videoTitle' ? user.video.title : user.info.name
    return {
      id: user.id,
      index: String(user.index),
      name: showName,
      groupId: user.groupId,
      label: showName + ' (' + user.id + ')'
    }
  })
  return arrUser
}

export function getChildrenData(children, userList, groupId, labelKey = 'videoTitle') {
  let arrChildren = []
  if (children) {
    children.forEach((item) => {
      let arrUser = getUserListByGroupId(userList, item.group.id, labelKey)

      let arrNode = item.children
        ? getChildrenData(item.children, userList, item.group.id)
        : []

      if (arrUser.length > 0 || arrNode.length > 0) {
        arrChildren.push({
          id: item.group.id,
          index: String(item.group.id), // 因為group tree並沒有index，故assign id值
          groupId: item.group.id,
          label: item.group.name, // 群組只顯示群組名稱
          children: arrUser.concat(arrNode),
          parent: groupId
        })
      }
    })
  }
  return arrChildren
}

export function filterTree(array, filterText) {
  const getNodes = (result, object) => {
    const sLabel = object.label.toLowerCase() // 轉小寫
    if (sLabel.includes(filterText.toLowerCase())) {
      result.push(object)
      return result
    }
    if (object.children) {
      const children = object.children.reduce(getNodes, [])
      if (children.length) result.push({...object, children})
    }
    return result
  }
  return array.reduce(getNodes, [])
}

export function getGroupObj(userTree) {
  let groupObj = {
    id: userTree.group.id,
    name: userTree.group.name
  }
  groupObj.children = []
  if (userTree.children) {
    userTree.children.forEach(item => {
      groupObj.children.push(getGroupObj(item))
    })
  }
  
  return groupObj
}