import Vue from 'vue'
import i18n from "../../i18n/i18n"
import {
  apiGetBoviaLprEventsAdvancedCount, 
  apiGetBoviaLprEventsAdvanced, 
  apiGetSosEvents,
  apiGetSosEventsCount,
} from '@/api/index.js'
import { handleError } from '@/utils/handleError.js'

const initialState = () => ({
  urgMode: 'chased', // 目前為哪種模式，chased: 圍捕事件, sos: SOS事件
  searchLoading: false,
  urgPageSize: 100, // 事件列表每個分頁事件數量
  urgEventsTotal: 0, // 搜尋的總事件數
  urgCurrentPage: 1, // 事件列表目前分頁所在頁數，從1開始
  urgCurrentIndex: 0, // 事件在事件列表目前分頁的index, 從0開始
  urgFocusIndex: -1, // 點擊事件卡片的index，初始值設為-1, 為了watch其變為０時也能更新focus
  urgEventList: [], // 僅存放該次搜尋的事件ex:100筆
  searchParams: {}, // 取得SOS事件參數
  beforeEventId: null, // 記錄目前分頁before event id, 為該分頁最小的id
  afterEventId: null, // 記錄目前分頁after event id, 為該分頁最大的id
  linkUserList: [], // 勾稽使用者列表
})

const state = initialState()

const getters = {
  urgModalEvent(state) {
    return state.urgEventList[state.urgCurrentIndex]
  },
  totalUrgEventNo(state) {
    // 目前事件在所有事件的編號，從１開始
    return (state.urgCurrentPage - 1) * state.urgPageSize + state.urgCurrentIndex + 1
  }
}

const mutations = {
  resetState(state) {
    const initial = initialState()
    Object.keys(initial).forEach(key => { state[key] = initial[key] })
  },
  updateUrgMode(state, value) {
    state.urgMode = value
  },
  updateSearchLoading(state, value) {
    state.searchLoading = value
  },
  updateUrgPageSize(state, value) {
    state.urgPageSize = value
  },
  updateUrgEventsTotal(state, value) {
    state.urgEventsTotal = value
  },
  updateUrgCurrentPage(state, value) {
    state.urgCurrentPage = value
  },
  updateUrgCurrentIndex(state, value) {
    state.urgCurrentIndex = value
  },
  updateUrgFocusIndex(state, value) {
    state.urgFocusIndex = value
  },
  updateUrgEventList(state, list) {
    state.urgEventList = list
    // add uid for each event
    state.urgEventList.forEach(event => {
      if (state.urgMode === 'chased') event.uid = `urg-${event.id}`
      else if (state.urgMode === 'sos') event.uid = `sos-${event.id}`
    })
  },
  updateSearchParams(state, payload) {
    state.searchParams = payload
  },
  updateBeforeEventId(state, value) {
    state.beforeEventId = value
  },
  updateAfterEventId(state, value) {
    state.afterEventId = value
  },
  updateLinkUserList(state, value) {
    state.linkUserList = value
  },
}

const actions = {
  async searchUrgentEvents({ state, commit, dispatch }, payload) {
    commit('updateSearchLoading', true)
    commit('updateUrgCurrentIndex', 0)
    commit('updateUrgFocusIndex', -1)
    // commit('updateUrgCurrentPage', 1) // 重新搜尋切換到分頁１
    
    const params = { ...state.searchParams, limit: state.urgPageSize }

    let bGoPrev = false // 是否回到前一分頁
    if (payload.page !== 1) {
      if (payload.page > state.urgCurrentPage) {
        delete params.afterId
        params.beforeId = state.beforeEventId
      } else {
        delete params.beforeId
        params.afterId = state.afterEventId
        bGoPrev = true
      }  
    }

    if (state.urgMode === 'chased') {
      params.chased = [3]
    } else {
      delete params.chased
    }

    // 因為搜尋參數中, 若為陣列，則需要將搜尋的參數再處理
    let urlSearchParams = new URLSearchParams()
    Object.entries(params).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach(item => {
          urlSearchParams.append(key, item)
        })
      } else
        urlSearchParams.append(key, value)
    })

    // 取得事件
    try {
      let res = null
      commit('updateUrgEventList', [])
      if (state.urgMode === 'chased') res = await apiGetBoviaLprEventsAdvanced(urlSearchParams) 
      if (state.urgMode === 'sos') res = await apiGetSosEvents(urlSearchParams)
      
      const len = res.data?.length
      if (len > 0) {
        // 若用afterId取資料id會從小到大，須先排序
        res.data.sort((x, y) => y.id - x.id)
        // 資料id由大到小
        commit('updateUrgEventList', res.data)
        commit('updateBeforeEventId', res.data[len - 1].id) // 該分頁最小id
        commit('updateAfterEventId', res.data[0].id) // 該分頁最大id
        commit('updateUrgCurrentPage', payload.page)

        // 若是回到前一分頁，eventIndex更新為前一分頁最後一筆，下一頁則是第一筆
        let eventIndex = bGoPrev ? len - 1 : 0 
        commit('updateUrgCurrentIndex', eventIndex)
        commit('updateUrgFocusIndex', eventIndex)
      } else {
        Vue.prototype.$notify.warning({
          title: i18n.t('search_hint')/*提示*/,
          message: i18n.t('search_hint_nodata')/* 查無資料 */
        })
      }
    } catch (error) {
      handleError(error)
      dispatch('resetHistoryUrgData')
    } finally {
      commit('updateSearchLoading', false)
    }

    // 取得事件總數(當取第一頁並且不是回上頁，則不呼叫)
    try {
      if (payload.page === 1 && !bGoPrev) {
        commit('updateUrgEventsTotal', 0)
        let resTotal = null
        if (state.urgMode === 'chased') resTotal = await apiGetBoviaLprEventsAdvancedCount(urlSearchParams)
        else if (state.urgMode === 'sos') resTotal = await apiGetSosEventsCount(urlSearchParams)
        commit('updateUrgEventsTotal', resTotal?.data?.total ? resTotal.data.total : 0)
      }
    } catch (err) {
      console.log('apiGetBoviaLprEventsAdvancedCount error: ', err)
    }
  },
  resetHistoryUrgData({ commit }) {
    commit('updateUrgEventList', [])
    commit('updateUrgEventsTotal', 0) // 搜尋的總事件數
    commit('updateUrgCurrentPage', 1) // 事件列表目前分頁所在頁數
    commit('updateUrgCurrentIndex', 0) // 事件在事件列表目前分頁的index
    commit('updateUrgFocusIndex', -1) // focus事件在事件列表目前分頁的index
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}