import { apiGetAiBoxes, apiGetAiBoxTasks, apiPostAiBoxTask, apiPutAiBoxTask, apiDeleteAiBoxTask,
  apiGetUserList, apiGetGroupTree, apiGetUserTree } from "../../api" //, apiGetUserByUid
import { getFilterUserTreeList } from '@/utils/lib.js'
import { Notification } from "element-ui"
import i18n from "@/i18n/i18n"

const initialState = () => ({
  mode: 'view', // view, edit
  aiMode: '', // or, fr
  showAiBox: false,  
  showAiboxPortal: false,
  paramAiboxPortal: {}, // info: cancelEdit / deleteTask / resourceNotAvailable, action: closeModal / switchTask(taskId) / switchMode
  taskId: -1,
  aiBoxes: [],
  aiBoxTasks: [],
  initAiBoxTask: {
    id: -99,  // magic number: -99
    ai: 'or',
    aiboxName: '',
    status: '',
    sourceId: '',
    aiboxId: '',
    notice: '',
    config: {
      version: '1.0',
      roi: {
        x1: 0,
        y1: 0,
        x2: 1,
        y2: 1,
      },
      area1: [],
      area2: [],
      area3: [],
      mode: 1, // 1-2, 1: or, 2: motion
      or: {
        personLevel: 3,     // 0-5, 0 為 disable, default: 3
        carLevel: 3,        // 0-5, 0 為 disable, default: 3
        bikeLevel: 3,       // 0-5, 0 為 disable, default: 3
        truckLevel: 3,      // 0-5, 0 為 disable, default: 3
        busLevel: 3,        // 0-5, 0 為 disable, default: 3
        recgFpsLevel: 3,    // 0-5, 0為ASAP , fps value: 3/5/7/10/15, default: 3
        staySec: 5,         // 0-60 (s), default: 5
        repeatInterval: 5,  // 1~60 (mins), default: 5
      },
      md: {
        motionLevel: 2,     // 0-3 0未啟動 1 2(default) 3 
        recgFpsLevel: 3,    // 0-5, 0為ASAP , fps value: 3/5/7/10/15, default: 3
        staySec: 5,         // 0-60 (s), default: 5
        repeatInterval: 5,  // 1~60 (mins), default: 5
      },
    },
    subscribers: [],
  },
  userList: [],
  groupTree: null,
  userTree: null,
  userTreeList: [],
  editRoi: false,
  polygonMode: false,
  setAreaNo: '-1', // 1, 2, 3
  isResetRoi: false,
})

const state = initialState()

const mutations = {
  resetState(state) {
    Object.assign(state, initialState())
  },
  updateMode(state, value) {
    state.mode = value // view, edit
  },
  updateAiMode(state, data) {
    state.aiMode = data // or, fr, lpr
  },
  updateShowAiBox(state, boolValue) {
    state.showAiBox = boolValue
    if (boolValue === false) state.taskId = -1
  },
  updateShowAiboxPortal(state, payload) {
    state.showAiboxPortal = payload
  },
  updateParamAiboxPortal(state, payload) {
    state.paramAiboxPortal = payload
  },
  updateTaskId(state, id) {
    state.taskId = id
  },
  updateAiBoxes(state, list) {
    state.aiBoxes = list
  },
  updateAiBoxTasks(state, list) {
    // 根據createdTime進行排序，較新的排在上面
    list.sort((a, b) => {
      const timeA = new Date(a.createdTime).getTime()
      const timeB = new Date(b.createdTime).getTime()
      return (timeA > timeB) ? -1 : (timeA < timeB) ? 1 : 0 
    })
    state.aiBoxTasks = list
  },
  updateTaskAiBox(state, payload) {
    let index = state.aiBoxTasks.findIndex(item => item.id === state.taskId)
    if (index >= 0) {
      state.aiBoxTasks[index].aiboxId = payload.id
      state.aiBoxTasks[index].aiboxName = payload.name
    }
  },
  updateUserList(state, list) {
    let sortUsers = list.sort((a, b) => a.info.name.localeCompare(b.info.name))
    state.userList = sortUsers
  },
  updateGroupTree(state, data) {
    state.groupTree = data
  },
  updateUserTree(state, data) {
    state.userTree = data
  },
  updateUserTreeList(state, data) {
    state.userTreeList = data
  },
  updateEditRoi(state, payload) {
    state.editRoi = payload
  },
  updatePolygonMode(state, value) {
    state.polygonMode = value
  },
  updateSetAreaNo(state, data) {
    state.setAreaNo = data
  },
  updateIsResetRoi(state, value) {
    state.isResetRoi = value
  },
}

const actions = {
  initTaskSetting({ commit }) {
    commit('updatePolygonMode', false)
    commit('updateSetAreaNo', -1)
  },
  async getUserList({ commit }) {
    try {
      const res = await apiGetUserList('user')
      commit('updateUserList', res.data)
    } catch (err) {
      console.log(err)
    }
  },
  async getGroupTree({ commit }) {
    try {
      const res = await apiGetGroupTree()
      commit('updateGroupTree', res.data)
    } catch (err) {
      console.log(err)
    }
  },
  async getUserTree({ commit }) {
    try {
      const res = await apiGetUserTree()
      commit('updateUserTree', res.data)
    } catch (err) {
      console.log(err)
    }
  },
  async getUserTreeList({ commit, state }) {
    const resUsers = await apiGetUserList('user', 0)
    const resTree = await apiGetGroupTree()
    commit('updateUserList', resUsers.data)
    commit('updateGroupTree', resTree.data)

    const treeList = getFilterUserTreeList(state.userList, state.groupTree, 'infoName')

    commit('updateUserTreeList', treeList)
  },
  async getAiBoxes({ commit }, ai) {
    try {
      const res = await apiGetAiBoxes(ai)
      commit('updateAiBoxes', res.data)
      // if (res.status === 200) {
      //   Notification.success({
      //     title: '辨識盒資料',
      //     message: `取得辨識盒資料`,
      //     type: 'success',
      //     position: 'bottom-left'
      //   })
      // }
    } catch (err) {
      console.log(err)
    }
  },
  async getAiBoxTasks({ commit, getters }) {
    try {
      commit('updateAiBoxTasks', [])
      const res = await apiGetAiBoxTasks(String(getters.deviceIndex)) // deviceIndex為整數，api參數型別需string
      // res.data.forEach(async (item) => {
      //   const response = await apiGetUserByUid(item.editorUserAccount)
      //   item.editorGroupName = response.data.groupName
      // })
      commit('updateAiBoxTasks', res.data)
    } catch (err) {
      console.log(err)
    }
  },
  async initialAiBoxTask({ state, commit, getters, dispatch }, ai) {
    await dispatch('getAiBoxes', ai)
    let newTask = JSON.parse(JSON.stringify(state.initAiBoxTask))
    if (state.aiBoxes.length > 0) {
      newTask.aiboxId = state.aiBoxes[0].id
      newTask.aiboxName = state.aiBoxes[0].name
    }
    newTask.sourceId = String(getters.deviceIndex) // 設定sourceId
    state.aiBoxTasks.unshift(newTask) // 加入第一個
    commit('updateTaskId', -99)
    commit('updateMode', 'edit')
  },
  async addOrUpdateAiBoxTask({ state, dispatch }) {
    if (state.taskId === -99) {
      await dispatch('addAiBoxTask')
    } else if (state.taskId >= 0) {
      await dispatch('editAiBoxTask')
    }
  },
  async addAiBoxTask({ state, commit, dispatch }) {
    const taskData = state.aiBoxTasks.find(item => item.id === -99)
    try {
      delete taskData.id // 移除magic id
      // avoid ROI exceeding canvas 
      if (taskData.config.roi.x1 < 0) taskData.config.roi.x1 = 0
      if (taskData.config.roi.y1 < 0) taskData.config.roi.y1 = 0
      if (taskData.config.roi.x2 > 1) taskData.config.roi.x2 = 1
      if (taskData.config.roi.y2 > 1) taskData.config.roi.y2 = 1

      const res = await apiPostAiBoxTask(taskData)
      if (res.status === 201) {
        Notification.success({
          title: i18n.t('ai_new_recognition'),
          message: i18n.t('ai_recog_task_add_successfully'),
          type: 'success',
          // position: 'bottom-left'
        })
        // 重新取得AiBoxTasks
        await dispatch('getAiBoxTasks')
        commit('updateTaskId', res.data.id) // commit 新增成功task的id
        // commit('updateMode', 'view') // 回到瀏覽模式
      } 
      
    } catch (err) {
      console.log(err)
    }
  },
  async editAiBoxTask({ getters }) {
    try {
      // avoid ROI exceeding canvas 
      if (getters.aiBoxTask.config.roi.x1 < 0) getters.aiBoxTask.config.roi.x1 = 0
      if (getters.aiBoxTask.config.roi.y1 < 0) getters.aiBoxTask.config.roi.y1 = 0
      if (getters.aiBoxTask.config.roi.x2 > 1) getters.aiBoxTask.config.roi.x2 = 1
      if (getters.aiBoxTask.config.roi.y2 > 1) getters.aiBoxTask.config.roi.y2 = 1

      const data = {
        id: getters.aiBoxTask.id,
        sourceId: getters.aiBoxTask.sourceId,
        aiboxId: getters.aiBoxTask.aiboxId,
        notice: getters.aiBoxTask.notice,
        config: getters.aiBoxTask.config
      }
      await apiPutAiBoxTask(data)
    } catch (err) {
      console.log(err)
    }
  },
  // 修改task所使用的辨識盒
  async setTaskAiBox({ getters, commit, dispatch }, aiboxId) {
    try {
      const data = {
        id: getters.aiBoxTask.id,
        aiboxId: aiboxId
      }
      const res = await apiPutAiBoxTask(data)
      if (res.status === 200) {
        dispatch('getAiBoxTasks')
        // commit('updateTaskAiBox', aiboxId)
      }
    } catch (err) {
      // 新增後發現已經無法再加入新辨識任務, server會回傳 403 Permission Denied,
      // 再重新取得刷新一次清單, 如果清單裡面都佔用滿了,就顯示"無可用辨識盒"
      if (err.response.status === 403) {
        await dispatch('getAiBoxes', getters.aiBoxTask.ai)
        commit('updateParamAiboxPortal', { info: 'resourceNotAvailable' })
        commit('updateShowAiboxPortal', true)
      }
      console.log('err.response = ', err.response)
    }
  },
  async deleteAiBoxTask({ state, dispatch }) {
    try {
      if (state.taskId >= 0) {
        await apiDeleteAiBoxTask(state.taskId)
      }
      
      // 重新取得AiBoxTasks
      dispatch('getAiBoxTasks')
    } catch (err) {
      console.log(err)
    }
  },
  resetRoi({ getters, commit }) {
    getters.aiBoxTask.config.roi.x1 = 0
    getters.aiBoxTask.config.roi.y1 = 0
    getters.aiBoxTask.config.roi.x2 = 1
    getters.aiBoxTask.config.roi.y2 = 1
    commit('updateIsResetRoi', true)
  }
}

const getters = {
  deviceIndex(state, getters, rootState, rootGetters) {
    return rootGetters.rightClickUser.id ? rootGetters.rightClickUser.index : ''
  },
  deviceName(state, getters, rootState, rootGetters) {
    return rootGetters.rightClickUser.id ? 
      rootGetters.rightClickUser.video.title + '('+ rootGetters.rightClickUser.id +')' : ''
  },
  aiBoxTask(state) {
    return state.taskId >= 0 || state.taskId === -99 ? 
      state.aiBoxTasks.find(task => task.id === state.taskId) : null
  }
}

export default {
namespaced: true,
state,
mutations,
actions,
getters,  
}