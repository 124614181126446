import crypto from '@/utils/crypto'
import {
  apiCheckStatus,
  apiErrorMsg,
  apiGetFrTagList,
  apiPostFrTag,
  apiDeleteFrTag,
  apiPostFrHumanSearch,
} from '@/api/index.js'
import {euFrFeature, euFrEnabled} from '@/config/frInfo.js'

const initialState = () => ({
  // common
  frTagList: [],
  frTotal: 0,
  frList: null, // [] 搜尋到的 人臉資訊

  // FrInfo
  frInfoExpandNav: true, // 展開
  frInfoFilter: {
    enabled: euFrEnabled.enable,
    name: null,
    idCard: null,
    tag: [],
    updateTimeRange: [],
    feature: euFrFeature.enableOne,
  },
  // 暫存最新三筆人辨姓名於localStorage
  queryFrNames: localStorage.getItem('queryFrNames') ? JSON.parse(crypto.decrypt(localStorage.getItem('queryFrNames'))) : [],

  // FrInfoLv
})

const state = initialState()
const getters = {}
const mutations = {
  updateFrTagList(state, payload) {
    state.frTagList = payload
  },
  cleanFrInfoFilter(state) {
    state.frInfoFilter = {...initialState().frInfoFilter}
  },
  updateFrInfoFilter(state, payload) {
    // payload = {enabled: xxx, name:xxx, tags: yyy, ...}
    const items = Object.keys(payload)
    if (items.length === 0) {
      state.frInfoFilter = {...initialState().frInfoFilter}
    } else {
      items.forEach((key) => {
        const value = payload[key]
        state.frInfoFilter = {...state.frInfoFilter, ...{[key]: value}}
      })
    }
  },
  addQueryFrNames(state, payload) {
    if(!payload) return
    let idx = state.queryFrNames.findIndex(item => item === payload.trim())
    if (idx === -1) {
      state.queryFrNames.unshift(payload.trim())
      state.queryFrNames = state.queryFrNames.slice(0, 3) // 暫存最新三筆
      localStorage.setItem('queryFrNames', crypto.encrypt(JSON.stringify(state.queryFrNames)))
    }
  },
  cleanFrList(state/*, payload*/) {
    state.frList = null
    state.frTotal = 0
  },
  updateFrTotal(state, payload) {
    state.frTotal = payload
  },
  updateFrList(state, payload) {
    if (state.frList === null) state.frList = []

    state.frList = state.frList.concat(payload)

    // ＊ 基礎排序：updateTime(新的在前=top)
    state.frList.sort((a,b) => new Date(b.updateTime).getTime() >= new Date(a.updateTime).getTime()) // TODO: need test order
  },

  updateFrInfoExpandNav(state, payload) {
    // 收合/展開 按鈕
    state.frInfoExpandNav = payload
  }
}
const actions = {
  async fetchFrTagList({ commit /*, state, dispatch*/ }/*, payload*/) {
    try {
      const res = await apiGetFrTagList()
      if (res?.status !== 200) throw res
      commit('updateFrTagList', res.data.tagList)
    } catch (err) {
      console.error(`Store.frDb.fetchFrTagList fail.`, apiErrorMsg(err))
    }
    // finally {}
  },
  async addFrTag(store, payload) {
    try {
      const res = await apiPostFrTag({
        name: payload,
        category: 0,
        locked: 0
      })
      if (!res || res?.status !== 200) throw res
    } catch (err) {
      console.error(`Store.frDb.addFrTag fail.`, apiErrorMsg(err))
    }
  },
  async deleteFrTag(store, payload) {
    try {
      const res = await apiDeleteFrTag({
        id: payload
      })
      if (!res || res?.status !== 200) throw res
    } catch (err) {
      console.error(`Store.frDb.deleteFrTag fail.`, apiErrorMsg(err))
    }
  },
  async searchFr({state, commit}) {
    // 因為 刪除完要重新執行 搜尋, 所以將 搜尋寫在 store
    try {
      // --- param
      let param = {}
      let { enabled, name, idCard, tag } = state.frInfoFilter
      if (name) param = {...param, ...{name}}
      if (tag.length > 0) param = {...param, ...{tag}}
      if (idCard) param = {...param, ...{idCard}}
      // if (updateTimeRange) param = {...param, ...{updateTimeRange}} // TODO:need API
      // if (feature) param = {...param, ...{feature}} // TODO:need API
      if (enabled !== euFrEnabled.all) param = {...param, ...{enabled}}

      // --- API
      let res = await apiPostFrHumanSearch(param)
      if (!apiCheckStatus(res)) throw res

      // ＊ 第一次搜尋結果：取得 total
      let {total, humanList} = res.data
      commit('updateFrTotal', total)
      commit('updateFrList', humanList)

      // ＊ 執行剩下的 搜尋
      while(!(humanList.length < 100)) {
        param = {...param, ...{index: state.frList.length}} // index: offset 的概念
        // console.log(`[onSearch] #2 param:`, param)

        res = await apiPostFrHumanSearch(param)
        if (!apiCheckStatus(res)) throw res
        humanList = [...res.data.humanList]
        commit('updateFrList', humanList)
      }
    } catch (err) {
      // console.log(`[Store.FrDb] err:`, err)
      return err
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}