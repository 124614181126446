import { 
  apiSearchVideo,
  apiGetVideoGPS,
} from '@/api/index.js'

const initialState = () => ({
  searchLoading: false, // 搜尋中
  queryParams: {}, // 搜尋條件
  total: 0, // video 總數
  videoList: [], // video list 影片從新到舊排列
  videoGpsList: [], // 紀錄 video GPS 位置資料
  videoIndex: '', // 回放 video index, 0是最新的影片，越大越舊
  videoTime: '', // 回放 video 時間
  videoFocusIndex: -1, // video focus index 點擊事件卡片的index，初始值設為-1, 為了watch其變為０時也能更新focus
  isBackPlay: false, // 是否回放
  isShowVideoDownloadPanel: false, // 是否顯示下載視窗
  hlsVideoList: [], // 暫存 HLS video list
  isMapDraggable: false, // 地圖是否可拖動(不可拖動則tracking該設備路徑 / 可拖動則可以自由移動地圖)
  playSpecificTime: '', // 播放特定時間
  caller: 'history' // 辨別是 history or dashboard 在使用
})

const state = initialState()
const getters = {
  wholeVideoPath(state) {
    if (state.videoGpsList.length === 0) return []
    return state.videoGpsList.map((item) => ({
      lock: item.lock,
      timestamp: item.timestamp,
      lat: item.latitude,
      lng: item.longitude,
    }))
  },
  videoPath(state) {
    if (state.videoGpsList.length === 0) return []
    return state.videoGpsList
      .filter(item => new Date(state.videoTime).getTime() >= new Date(item.timestamp).getTime())
      .map(item => ({
        lock: item.lock,
        timestamp: item.timestamp,
        lat: item.latitude,
        lng: item.longitude
      }))
  },
}
const mutations = {
  resetState(state) {
    const initial = initialState()
    Object.keys(initial).forEach(key => { state[key] = initial[key] })
  },
  updateSearchLoading(state, value) {
    state.searchLoading = value
  },
  updateQueryParams(state, queryParams) {
    state.queryParams = queryParams
  },
  updateTotal(state, total) {
    state.total = total
  },
  updateVideoList(state, videoList) {
    state.videoList = videoList
  },
  addVideoList(state, list) {
    state.videoList.push(...list)
  },
  updateVideoGpsList(state, list) {
    // gpsList 根據timestamp 由舊到新進行排序
    list.sort((a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime())
    state.videoGpsList = list
  },
  updateVideoIndex(state, videoIndex) {
    state.videoIndex = videoIndex
  },
  updateVideoTime(state, videoTime) {
    state.videoTime = videoTime
  },
  updateVideoFocusIndex(state, videoFocusIndex) {
    state.videoFocusIndex = videoFocusIndex
  },
  updateIsBackPlay(state, value) {
    state.isBackPlay = value
  },
  updateIsShowVideoDownloadPanel(state, value) {
    state.isShowVideoDownloadPanel = value
  },
  addHlsVideoList(state, list) {
    state.hlsVideoList.push(...list)
  },
  updateHlsVideoList(state, list) {
    state.hlsVideoList = list
  },
  updateIsMapDraggable(state, value) {
    state.isMapDraggable = value
  },
  updatePlaySpecificTime(state, value) {
    state.playSpecificTime = value
  },
  updateCaller(state, caller) {
    state.caller = caller
  }
}
const actions = {
  async getVideoList({ commit, state, dispatch }, payload) {
    payload.index = payload.index || 0
    payload.count = payload.count || 20 // count 最大值：100

    commit('updateQueryParams', payload)

    try {
      const res = await apiSearchVideo(payload)
      const mp4Video = res.data.videoList.filter((video) => video.type === 'MP4')
      const notMp4Video = res.data.videoList.filter((video) => video.type !== 'MP4')

      if (notMp4Video.length > 0) {
        commit('addHlsVideoList', notMp4Video)
      }
      commit('addVideoList', mp4Video)
      commit('updateTotal', notMp4Video.length ? res.data.total - state.hlsVideoList.length : res.data.total)
      commit('updateVideoIndex', state.videoList.length - 1) // 從最舊的video開始

      // 還有video，再次呼叫 api
      const fullLen = state.videoList.length + state.hlsVideoList.length
      // console.log(`[getVideoGpsList] videoList, total, fullLen:`, state.videoList.length, state.total, fullLen)
      if (fullLen < state.total) {
        // payload.index = state.videoList.length
        payload.index += payload.count
        await dispatch('getVideoList', payload)
      }
    } catch (err) {
      console.error(`[Store.video.getVideoList] `, err)
    }
  },
  async getVideoGpsList({ commit, state, dispatch }, payload) {
    try {
      commit('updateSearchLoading', true)
      await dispatch('resetVideoGpsPath')
      await dispatch('getVideoList', payload)

      if (state.caller !== initialState().caller) return

      let gpsList = []
      for (let i = 0; i < state.videoList.length; i++) {
        /** 取得video gps list */
        const res = await apiGetVideoGPS(state.videoList[i].id)
        if (res.data.gpsList) // 沒有資料會回傳 res.data.gpsList = null
          gpsList = [...gpsList, ...res.data.gpsList]
      }

      commit('updateVideoGpsList', gpsList)
    } catch (err) {
      console.error(`[Store.video.getVideoGpsList] `, err)
    } finally {
      commit('updateSearchLoading', false)
    }
  },
  resetVideoGpsPath({ commit }) {
    commit('updateVideoIndex', 0)
    commit('updateVideoTime', '')
    commit('updateTotal', 0)
    commit('updateVideoList', [])
    commit('updateHlsVideoList', [])
    commit('updateVideoGpsList', [])
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}