// Note: 維持 Key: [string]; 不換行, 方便語系比對閱讀
const notify_style = 'font-size: 24px; font-style: italic; font-weight: 500; color: #303133'

const ja = {
  /* project */
  system_name: '5G AI パトロールシステム',
  system_language: '言語',
  system_timezone: 'タイムゾーン',
  system_default: 'システムデフォルト',

  /* global */
  yes: 'はい',
  no: 'いいえ',
  cancel: 'キャンセル',
  ok: '確定',
  save: '保存',
  close: '閉じる',
  delete: '削除',
  confirm: '確認',
  finish: '完了',
  error: 'エラー',
  edit: '編集',
  view: '表示',
  none: 'なし',
  custom: 'カスタム',
  total: '合計',
  go: 'に行く',
  loading: '読み込み中',
  selected: '選択した',
  search: '検索',
  pre: '戻る',
  next: '次へ',
  stop: '中止',
  unknown: '知らない',
  tag: 'マーク',
  license_plate_example: '品川,599,さ,1234',
  on: 'オン',
  off: 'オフ',
  device: 'デバイス',
  vehicle: '車両',
  mission: 'ミッション',
  name: '名前',
  attribute: '属性',
  link: 'リンク',
  update_time: 'Update time',
  all: 'All',
  adv_search: 'Adv. Search',
  search_result: 'Result：{count}',
  new_tag_hint: 'New Tag',
  event: 'イベント',
  decibel_meter: 'デシベルメーター',
  hint: 'ヒント',
  collapse: 'Collapse',
  expand: 'Expand',
  search_fail_title: 'Search fail',
  no_subscription: '未登記',
  delete_data: 'Delete data',
  delete_data_confirm: 'Are you sure to delete {count} data(s)?',
  age: '{age}才',

  /* alarm */
  data_error: '資料錯誤',

  /* language */
  lang_zh: '繁體中文',
  lang_en: 'English',
  lang_ja: '日本語',

  /* week & time */
  sun: '日',
  mon: '月',
  tue: '火',
  wed: '水',
  thu: '木',
  fri: '金',
  sat: '土',
  month: '月',
  day: '日',
  one_day: '日',
  hour: '時間',
  min: '分',
  one_min: '分',
  sec: '秒',
  month_ago: 'ヶ月前',
  day_ago: '日前',
  hour_ago: '時間前',
  min_ago: '分前',
  sec_ago: '秒前',
  one_min_later: '分後',
  min_later: '分後',
  today: '今日',
  yesterday: '昨日',
  last_week: 'この1週間',
  last_month: '先月',
  last_three_months: '過去3か月',
  half_year_ago: '過去6か月',
  custom_time: 'カスタム時間',

  /* login */
  login_id: 'ID',
  login_pw: 'パスワード',
  login_hint: 'アカウントとパスワードを入力してください',
  login_account_password_error: 'アカウントまたはパスワードが正しくありません',
  login_npa_hint: '請聯絡系統管理員',

  /* Api */
  api_400: 'Missing required fields.',
  api_401_login: `帳號或密碼錯誤`,
  api_403: `権限がありません`,
  api_404: `Not Found`,
  api_404_login: `帳號或密碼錯誤`,
  api_500: `Internal Server Error`,
  api_error: `Something Error`,
  api_econnaborted: 'サーバー接続が失われました',
  api_timeout: 'リクエストはタイムアウトしました',

  /* left bar */
  left_bar_dashboard: 'ダッシュボード',
  left_bar_history: '事件履歴',
  // left_bar_subscription: '車號管理',
  left_bar_subscription: '比較用データベース',
  left_bar_recognition: '比較用データベース',
  left_bar_lpr_recognition: '自動車登録番号管理',
  left_bar_fr_recognition: '人物管理',
  left_bar_account_management: 'アカウントと権限',
  left_bar_system_setting: 'システムとサービス',
  left_bar_download_app: 'ダウンロードセンター',
  left_bar_export_center: 'エクスポートセンター',
  left_bar_account: 'アカウント',
  left_bar_call: '通話',
  left_bar_joined_call: '通話に参加',
  left_bar_left_call: '通話に離席',
  left_bar_end_call_before: '新しい通話を開始する前に、現在の通話を終了してください',
  left_bar_cant_call_myself: '自分への通話ができません',
  left_bar_15_seconds_hangup: '15秒以内に応答がない場合、自動的に電話を切ります',
  left_bar_failed_sdp_speak: 'SDP speak の交換または音声通知の送信に失敗したため、通話接続が途切れました',
  left_bar_another_webpage_call: '別の Web ページで通話を開始しました。通話を再開するためには、ページを閉じる必要があります',
  left_bar_other_has_hungup: '相手が電話を切りました',
  left_ber_userinfo_change_password: 'Change Password',
  left_bar_userinfo_username_placeholder: 'ユーザー名(20文字以内)',
  left_bar_userinfo_email_placeholder: 'Eメール',
  left_bar_userinfo_phone_placeholder: '電話番号',

  /* top bar */
  top_bar_keep_login: 'ログインを維持する',
  top_bar_online_devices: 'オンライン設備',
  top_bar_calling_volume_off: 'During call, volume muted.',
  top_bar_calling_volume_reverse: 'The call ended and the volume has been restored.',

  /* 下載APP */
  download_notify_app: '通知アプリ',
  download_info: 'お手持ちの携帯電話でQRコードを読み取り、通知アプリをダウンロードしてください',
  download_android: 'アンドロイド',
  download_ios: 'iOS',
  download_manual: 'マニュアル',
  download_manual_fn: 'マニュアル.pdf',
  download_tool: 'ツール',
  download_search_placeholder: '検索',
  download_name: '名称',
  download_version: 'バージョン',
  download_update_time: 'アップデート時間',
  download_size: 'ファイルサイズ',
  download_os: 'オーエス',
  download: 'ダウンロード',
  download_no_info: 'データなし',

  /* 匯出中心 */
  export_category: 'カテゴリー',
  export_filename: 'ファイル名前',
  export_expiration_time: '有効期限',
  export_file_size: 'ファイルサイズ',
  export_status_download: 'ダウンロード',
  export_status_fail: 'エクスポートに失敗しました',
  export_status_exporting: 'エクスポート中',
  export_status_deleting: '削除中',
  export_warning_title: 'データのエクスポートができません',
  export_warning_content: 'エクスポートセンターのデータが最大5件に達したので、古いデータを削除してください。',
  export_custom_filename: 'ファイル名',
  export_search_criteria: '検索条件',
  export_plate_number: '車番号',
  export_time_interval: '時間間隔',
  export_event_category: 'イベントカテゴリー',
  export_mission: 'ミッション',
  export_finished_condition: '完成状態',
  export_category_basic: '通常検索',
  export_category_advanced: '詳細検索',
  export_category_performance: 'Performance Search',
  export_category_audit: 'Audit Search',

  /* 二因子認證-2FA */
  twoFactorAuth_title: '二段階認証',
  twoFactorAuth_installApp: '認証アプリを利用ください',
  twoFactorAuth_installApp_desc1: '保護を強化するため、Google AuthenticatorやDuo Mobileなどのアプリケーションを使用して認証コードを生成してください。',
  twoFactorAuth_installApp_desc2: '認証アプリをまだインストールしていない場合、先にインストールしてから次に進んでください。',
  twoFactorAuth_scanQrCode: 'サードパーティ認証アプリを経由して設定してください',
  twoFactorAuth_scanQrCode_desc: 'DUO や Google Authenticatorなどの認証アプリで QR コードをスキャンしてください。',
  twoFactorAuth_inputCode: '認証コードを入力してください',
  twoFactorAuth_inputCode_login: '認証コードを入力してください',
  twoFactorAuth_inputCode_desc: '認証アプリから認証コードを入力してください。',
  twoFactorAuth_done: 'アクティベートしました。',
  twoFactorAuth_done_desc: 'このアカウントの二段階認証はアクティベートしました',
  twoFactorAuth_enabled_pass: '正常にアクティベートしました',
  twoFactorAuth_enabled_fail: 'アクティベート失敗しました',
  twoFactorAuth_disabled_pass: '無効になりました',
  twoFactorAuth_disabled_fail: '無効化できませんでした',
  twoFactorAuth_validate_pass: 'Successed to validate',
  twoFactorAuth_validate_fail: 'Failed to validate',
  twoFactorAuth_error_getQrCode: 'QRコード取得に失敗しました',
  twoFactorAuth_error_inputNumber: '数字を入力してください',
  twoFactorAuth_error_fullCode: '完全な認証コードを入力してください',

  /* account */
  account_title: 'アカウント',
  account_name: '名前',
  account_role: '役割',
  account_change_password: 'パスワード変更',
  account_disable_2fa: '二段階認証を取り消す',
  account_id_tip: '請輸入{min}-{max}個字元，包含英文、數字與任一特殊符號',
  account_id_length_error: '帳號長度需{min}-{max}個字元。',
  account_id_char_error: '帳號只允許英數，大小寫，及特殊字元',
  account_edit_password: 'パスワード変更',
  account_edit_password_tip1: '1. The length of the password must be {min}～{max} characters.',
  account_edit_password_tip2: '2. Please enter special characters.',
  account_pw_tip: 'The length of the password must be {min}-{max} characters and contain any one special characters.',
  account_new_pw: '新しいパスワード',
  account_reenter_pw: '新しいパスワードを再入力してください',
  account_pw_length_error: 'The length of the password must be {min}-{max} characters.',
  account_pw_char_error: '密碼只允許英數，大小寫，及特殊字元',
  account_pw_special_char_error: 'The password mush have any one special characters.',
  account_pw_error: '2つのパスワードが一致しませんので、ご確認ください',
  account_pw_null: 'パスワードは空欄にできません',
  account_pw_success: 'パスワードの変更が完了しました。',
  account_pw_failed: 'パスワードの変更でエラーが発生しました。',
  account_2fa: '二段階認証',
  account_logout: 'ログアウト',
  account_logout_hint: 'このページを離れますか？',
  account_delete_confirm: '削除の確認？',
  account_notify_edit_title: 'アカウント情報の編集',
  account_notify_edit_success: 'アカウント情報を変更しました',
  account_notify_edit_failed: 'アカウント情報の変更でエラーが発生しました',
  account_add_role: '役割の追加',
  account_edit_role: '役割の編集',
  account_copy_role: '役割のコピー',
  account_delete_role: '役割の削除',
  account_add: 'アカウントの追加',
  account_add_success: 'アカウントを追加しました',
  account_add_failed: 'アカウントの追加でエラーが発生しました',
  account_required: '<span class="required">*</span> 入力必要',
  account_detail_group_accound: 'Group and Account',
  account_detail_group: 'グループ',
  account_detail_id: 'アカウント',
  account_detail_password: 'パスワード',
  account_required_info: '入力必要',
  account_viewing_quota: '視聴割り当て',
  account_multi_select: 'アカウントを複数選択する',
  account_cancel_multi_select: '複数選択モードを取り消し',
  account_selected: '選択したアカウント',
  account_batch_selected_account_only: 'Display Selected Account Only({count})',
  account_batch_edit_items: '項目の一括編集',
  account_add_items: '項目の追加',
  account_abnormal_account: '異常なアカウント',
  account_filter_result: 'Quantity：{count}',
  account_batch_modified: 'が一括で変更されました',
  account_batch_n: '{pass_count} user(s) successfully and {fail_count} user(s) failed.',
  account_n_success: '件成功しました',
  account_n_failed: '件失敗しました',
  account_active: 'アクティベート中',
  account_not_activated: '無効',
  account_enabled: '使用開始',
  account_disabled_until: '一時停止',
  account_disabled: '無効',
  account_suspend: 'つるす',
  account_status: 'スターテス',
  account_status_enabled: '啟用',
  account_status_disable: '停用',
  account_status_locked: '暫停',
  account_group: 'グループ',
  account_id: 'アカウント',
  account_password: 'パスワード',
  account_confirm_password: 'パスワードの確認',
  account_detail_name: 'Name',
  account_all: 'All',
  account_public_title: 'Public Status',
  account_public_all: 'All',
  account_public_0: 'Private',
  account_public_1: 'Public',
  account_default_setting: 'Config Source',
  account_defaultSetting_1: 'System Default',
  account_defaultSetting_0: 'Device Customize',
  account_watermark: 'Watermark',
  account_watermark_0: 'Disabled',
  account_watermark_1: 'Enabled',
  account_detail_linkmode_title: '勾稽',
  account_linkmode_title: 'Lonk Mode',
  account_linkmode_all: 'All',
  account_linkmode_0: 'Disabled',
  account_linkmode_1: 'BoviLive',
  account_linkmode_2: 'BoviLive - forced',
  account_linkmode_3: ' NPA',
  account_linkmode_4: 'NPA - forced',
  account_linkmode_group: 'Group',
  account_linkmode_user: 'User',
  account_type: 'アカウント　タイプ',
  account_kind_default: 'Default',
  account_kind_sso: 'SSO',
  account_kind_device: 'Device',
  account_kind_user: 'User',
  account_kind_0: 'Default',
  account_kind_1: 'SSO',
  account_kind_2: 'Device',
  account_kind_3: 'User',
  account_label: 'アカウント名前',
  account_lang: 'Language',
  account_tz: 'Timezone',
  account_video_title: '動画タイトル',
  account_detail_property: 'Property',
  account_lpr_keep: '事件キープ',
  account_video_keep: '動画キープ',
  account_keep_day: '保留時間',
  account_device_model: 'デバイス　タイプ',
  account_device_model_0: 'Other',
  account_device_series_10: 'BoviCam Series',
  account_device_series_100: 'AiCam Series',
  account_device_series_200: 'Bodyworn Cam Series',
  account_device_series_500: 'FaceArk Series',
  account_device_series_1000: 'Android APP Series',
  account_device_series_2000: 'iOS APP Series',
  account_device_series_3000: 'Mac Series',
  account_ip_source: 'IPソース',
  account_gps: 'GPS',
  account_from: 'アカウントソース',
  account_last_action: 'Latest Action',
  account_time: '時間',
  account_login_time: '時間',
  account_keep_data: 'Keep Data',
  account_updated_time: '更新時間',
  account_register_time: '登録時間',
  account_locked: 'アカウントがロックされました',
  account_15min_later: '15分後',
  account_30min_later: '30分後',
  account_1hr_later: '１時間後',
  account_edit_title: 'Edit Account',
  account_edit_device_model_hint: '請注意, 變更設備類型時會一併清空既存設備參數及事件接收人清單',
  account_edit_failed: 'アカウント情報の編集に失敗しました',
  account_edit_success: '正常に編集されました',
  account_lock_success: '正常にロックされました',
  account_lock_failed: 'ロックにエラーが発生しました',
  account_unlock: 'ロック解除済み',
  account_unlock_success: 'ロック解除しました',
  account_unlock_failed: 'ロック解除にエラーが発生しました',
  account_source_sso: 'SSO',
  account_source_self: 'セルフクリエイト',
  account_forceed_2fa: '2FA',
  account_forceed2fa_0: 'On Demand',
  account_forceed2fa_1: 'Forced',
  account_enabled_2fa: '已啟用雙重驗證',

  /* role permission */
  role_no_permission: '権限なし',
  role_general: '一般',
  role_advanced: '上級',
  role_medium: '中級',
  role_higher: '高等',
  role_off: 'オフ',
  role_on: 'オン',
  role_L0: 'L0：権限なし',
  role_new_name: '新しい役割(1)',
  role_topScope: '視線範囲',
  role_topScope_tooltip: '「表示範囲」としてグループを選択してください。',
  role_topScop_default: 'デフォルト',
  role_topScope_desc1: 'リソースアクセス範囲の設定を提供します',
  role_topScope_desc2: 'グループはツリー構造になっています「表示範囲」を設定することで、アクセス範囲を調整する最上位のグループノードが決定されます。現在の役割は、そのグループとすべての下位グループのリソースにアクセスできます。',
  role_topScope_desc3: '非公開アカウントから得られるリソースは、そのグループが属するグループに帰属する。',
  role_topScope_desc4: '特に指定がない場合、デフォルトのアクセス範囲は "表示範囲" に設定されます。デフォルト： アカウントの所属グループ。',
  role_topScope_L0: 'デフォルト：アカウントの所属するグループ。',
  role_topScope_L1: '指定：指定されたグループ。',
  role_audit: '使用履歴',
  role_audit_tooltip1: 'オフ：自分自身の記録のみ照会可能',
  role_audit_tooltip2: 'オン：他人の記録を照会することができます',
  role_audit_desc: 'アカウントの記録ログへのアクセスを提供します',
  role_audit_L0: 'L0：アカウント自身の利用記録を照会することのみ可能です。',
  role_audit_L1: 'L1：利用記録にアクセスできます。',
  // 勤務派遣
  role_dashboardPermission: '勤務派遣',
  role_dashboard: '勤務派遣',
  role_dashboard_tooltip: '現場でのイベントや状況を集中管理する勤務派遣機能へのアクセスを提供します',
  role_dashboard_desc: '現場でのイベントや状況を集中管理する勤務派遣機能へのアクセスを提供します',
  role_dashboard_L0: 'L0：権限なし。勤務派遣機能ページにアクセスできません。',
  role_dashboard_L1: 'L1：勤務派遣にアクセスできます。',
  role_dashboard_L2: 'L2：公開された設備リストにアクセスできます。',
  // 影像管理
  role_videoPermission: '映像管理',
  role_liveView: 'ライブ映像',
  role_liveView_tooltip1: '一般： リアルタイム映像を視聴できます。',
  role_liveView_tooltip2: '上級：リアルタイム映像の視聴、タイトルの変更、PTZの制御などが可能です。',
  role_liveView_desc: 'ライブ映像、音声、GPSなどの情報へのアクセスを提供します。',
  role_liveView_L1: 'L1：ライブ映像にアクセスできます',
  role_liveView_L2: 'L2：公開ライブ映像にアクセスできます',
  role_video: '歴史映像',
  role_video_title: '履歴の映像検索・再生・ダウンロードを提供します',
  role_video_desc: '履歴の映像検索・再生・ダウンロードを提供します',
  role_video_L1: 'L1：履歴動画にアクセスできます',
  role_video_L2: 'L2：公開履歴動画にアクセスできます',
  role_videoManagement: '動画管理',
  role_videoManagement_tooltip: '履歴動画の管理機能を提供します',
  role_videoManagement_desc: '履歴動画の管理機能を提供します',
  role_videoManagement_L1: 'L1：履歴動画の説明文の修正、動画の保持・削除が可能です。',
  // 設備操控
  role_deviceCtrlPermission: 'デバイス操作',
  role_deviceCtrl: 'デバイス操作',
  role_deviceCtrl_tooltip: 'デバイスの設定と操作機能を提供します。',
  role_deviceCtrl_desc: 'デバイスの設定と操作機能を提供します。',
  role_deviceCtrl_L1: 'L1：動画のタイトルなど、デバイスに関する説明を変更できます。',
  role_deviceCtrl_L2: 'L2：カメラの関連設定、PTZ制御、品質設定などを調整できます。',
  role_deviceCtrl_L3: 'L3：デバイスの詳細設定を行うことができます。',
  role_deviceAISetting: '識別タスク',
  role_deviceAISetting_title: 'デバイスの識別タスク設定機能を提供します。',
  role_deviceAISetting_desc: 'デバイスの識別タスク設定機能を提供します。',
  role_deviceAISetting_L1: 'L1：識別タスクの設定にアクセスできます。',
  // 語音通話
  role_voiceCallPermission: '音声通話',
  role_voiceCall: 'ボイスチャット',
  role_voiceCall_tooltip: '音声通話機能が利用可能です。',
  role_voiceCall_desc: '1対1やグループ通話モードなど、音声インターホンサービスを提供します。',
  role_voiceCall_L1: 'L1：1対1やグループ通話など、音声インターホンにアクセスできます。',
  role_voiceCall_L2: 'L2：公開アドレス帳（ユーザー/デバイス）にアクセスできます。',
  // 車辨事件
  role_lprEventPermission: '車両識別事件',
  // role_lprDashboard: '儀表板功能',
  role_lprDashboard: 'リアルタイムナンバープレート事件',
  role_lprDashboard_tooltip1: '一般：ダッシュボードに比較/タスク/逮捕事件を表示できます。',
  role_lprDashboard_tooltip2: '詳細：ダッシュボードにすべての事件を表示できます。',
  role_lprDashboard_desc: 'リアルタイムのナンバープレート事件のアクセス機能を提供します',
  role_lprDashboard_L1: 'L1：車両情報、タスク、サブスクリプションなどを含む、車両番号のリアルタイムマッチング事件にアクセスできます。',
  role_lprDashboard_L2: 'L2：車両情報、タスク、サブスクリプションなどを含む、公開された車両番号のリアルタイムマッチング事件にアクセスできます。',
  role_lprDashboard_L3: 'L3：すべての車両番号のリアルタイム事件にアクセスできます。',
  // role_lprEventAccess: '事件查詢',
  role_lprEventAccess: '過去の車両番号識別事件 - 一般',
  role_lprEventAccess_tooltip1: '単一の車両番号をクエリできる一般的なイベント',
  role_lprEventAccess_desc: '車両ナンバープレートの履歴事件へのアクセス機能を提供します。',
  role_lprEventAccess_L1: 'L1：一般的な照会にアクセスできます（公開デバイスのみ）。',
  role_lprEventAdvancedAccess: '過去の車両番号識別事件 - 進階',
  role_lprEventAdvancedAccess_tooltip1: '高度な条件を使用して、事件を照会することができます',
  role_lprEventAdvancedAccess_desc: '車両ナンバープレートの履歴事件へのアクセス機能を提供します。',
  role_lprEventAdvancedAccess_L1: 'L1：高度な照会にアクセスできます（プライベートデバイスのみ）。',

  // 車號管理
  role_lprPermission: 'ナンバープレートの管理',
  // role_lprSummary: '一般查詢',
  role_lprSummary: 'ナンバープレートの照会',
  role_lprSummary_tooltip: '一つの車両情報を照会できます',
  role_lprSummary_desc: '車両情報/タスク/サブスクリプションなどを含む車両ナンバープレートのデータベース比較機能を提供します',
  role_lprSummary_L1: 'L1：グローバルなナンバープレートデータを照会できます。',
  role_lprSummary_L2: 'L2：グループ車両データを照会できます。',
  role_lprSubscription: 'サブスクリプション管理',
  role_lprSubscription_tooltip: 'サブスクリプション内容の新規追加/照会/削除できます。',
  role_lprSubscription_desc1: '車両ナンバープレートのサブスクリプション管理機能を提供します。',
  role_lprSubscription_desc2: 'サブスクリプションはグループデータです。',
  role_lprSubscription_desc3: 'フロントエンド機器がサブスクリプションのナンバープレートを認識した場合、アラートは発生しませんが、システムが受信者の携帯電話にインストールされた通知アプリに即時メッセージを送信します。',
  role_lprSubscription_L1: 'L1：サブスクリプションナンバープレートを管理できます。',
  role_lprMissionLv: 'タスク管理',
  role_lprMissionLv_tooltip1: '一般：一般タスク（捜索協力など）を設定できます。',
  role_lprMissionLv_tooltip2: '中級：中級以下のタスク（取調べ・捜索協力など）を設定できます。',
  role_lprMissionLv_tooltip3: '上級：上級以下のタスク（逮捕/取調べ/捜索協力など）を設定できます。',
  role_lprMissionLv_desc1: '車両ナンバープレートのタスク管理機能を提供します。',
  role_lprMissionLv_desc2: 'タスクはグローバルなデータです。',
  role_lprMissionLv_desc3: 'フロントエンド機器がタスクのナンバープレートを認識すると、直ちにアラートを発し、支援を要請するプッシュメッセージを表示します。受信者の携帯電話にインストールされた通知アプリにも即時メッセージが送信されます。',
  role_lprMissionLv_L1: 'L1：(捜索協力)タスクを管理できます。',
  role_lprMissionLv_L2: 'L2：(取調べ/捜索協力)タスクを管理できます。',
  role_lprMissionLv_L3: 'L3：(逮捕/取調べ/捜索協力)タスクを管理できます。',
  role_lprMission: 'タスクタイプ',
  role_lprMission_title: '（預留，目前未使用）',
  role_lprMission_desc: '',
  role_lprMission_L1: 'L1：タスクタイプを管理できます。',
  // role_lprInfoLv: '車輛資訊管理',
  role_lprInfoLv: '車両情報',
  role_lprInfoLv_tooltip: '車両のマークと備考を管理できます。',
  role_lprInfoLv_desc1: '車両情報の管理機能を提供します。',
  role_lprInfoLv_desc2: '車両情報はグローバルなデータです。',
  role_lprInfoLv_L1: 'L1：グローバルな車両情報を管理できます。',
  // role_lprInfo: '標記列表管理',
  role_lprInfo: '車両のマーク',
  role_lprInfo_tooltip: 'マークリストを管理できます。',
  role_lprInfo_desc1: '車両のマークの管理機能を提供します。',
  role_lprInfo_desc2: '車両のマークはグローバルなデータであり、車両情報の分類の参照として使用されます。',
  role_lprInfo_desc3: '単一の車両情報には複数の車両マークを設定できます。',
  role_lprInfo_L1: 'L1：グローバルな車両マークを管理できます。',
  // role_lprAnnouncement: '公告管理',
  role_lprAnnouncement: 'プッシュ通知',
  role_lprAnnouncement_tooltip: '通知コンテンツを管理できます。',
  role_lprAnnouncement_desc1: 'プッシュ通知の管理機能を提供します。',
  role_lprAnnouncement_desc2: 'プッシュ通知はグローバルデータです。',
  role_lprAnnouncement_desc3: '通知メッセージはフロントエンドデバイスに送信され、音声で1回再生され、テキストメッセージがスク役割表示されます。',
  role_lprAnnouncement_L1: 'L1：グローバルプッシュ通知を管理できます。',
  role_lprReport: 'パフォーマンスレポート',
  role_lprReport_tooltip: 'パフォーマンスレポートの管理機能を提供します。',
  role_lprReport_desc1: 'パフォーマンスレポートの管理機能を提供します。',
  role_lprReport_desc2: 'エクスポートされたデバイスリストセットをもとに、毎月自動的にパフォーマンスレポートを作成します。',
  role_lprReport_L1: 'L1：パフォーマンスレポートにアクセスできます。',
  role_lprReport_L2: 'L2：パフォーマンスレポートのエクスポートデバイスを管理できます。',
  // 物件辨識
  // role_orEventPermission: '物件辨識',
  // 人臉辨識
  role_frEventPermission: '顔認識',
  role_frDashboard: '実時間人物イベント',
  role_frDashboard_desc: '実時間人物イベントへのアクセス機能を提供します。',
  role_frDashboard_L1: 'L1：Can access real-time matched person events.',
  role_frDashboard_L2: 'L2：可存取公開人物即時比對符合事件。',
  role_frDashboard_L3: 'L3：可存取全部人物即時事件。',
  role_frEventAccess: '履歴人物イベント',
  role_frEventAccess_desc: '履歴人物イベントへのアクセス機能を提供します。',
  role_frEventAccess_L1: 'L1：履歴人物のイベントにアクセスできます。',
  // 人物管理
  role_frPermission: '人物管理',
  role_frInfoLv: '人物情報',
  role_frInfoLv_desc1: '人物情報の管理機能を提供します。',
  role_frInfoLv_desc2: '人物情報はグローバルデータです。',
  role_frInfoLv_L1: 'L1：グローバルな人物情報を管理できます。',
  role_frInfo: '人物タグ',
  role_frInfo_desc1: '人物タグの管理機能を提供します。',
  role_frInfo_desc2: '人物タグはグローバルデータであり、車両情報の分類に使用されます。',
  role_frInfo_desc3: '単一の人物情報には、複数の人物タグを設定できます。',
  role_frInfo_L1: 'L1：グローバルな顔認識タグを管理できます。',
  // 帳號與授權
  role_accounrPermission: 'アカウントと承認',
  // role_account: '帳號管理',
  role_account: 'ユーザー',
  role_account_tooltip: 'ユーザーアカウント関連設定を管理できます。',
  role_account_desc1: 'ユーザーアカウントの管理機能を提供します。',
  role_account_desc2: 'ユーザーアカウントの確認が必要で、設定された役割権限に基づいて、リソースや機能へのアクセスレベルが決定されます。',
  role_account_L1: 'L1：ユーザーアカウントを表示できます。',
  role_account_L2: 'L2：ユーザーアカウントを管理できます。',
  role_device: 'デバイス',
  role_device_tooltip: 'デバイスアカウント関連の設定を管理できます。',
  role_device_desc1: 'デバイスアカウント管理機能を提供します。',
  role_device_desc2: 'デバイスアカウントは検証を通過し、構成された許可に基づいて提供できる機能とリソースの保持条件を決定します。',
  role_device_L1: 'L1：デバイスアカウントを表示できます。',
  role_device_L2: 'L2：デバイスアカウントを管理できます。',
  role_device_L3: 'L3：デバイスの認証を設定できます。',
  role_device_L4: 'L4：デバイスアカウントを追加できます。',
  // role_group: 'Group management',
  role_group: 'グループ',
  role_group_tooltip: 'グループ関連の設定を管理できます。',
  role_group_desc1: 'グループ管理機能を提供します。',
  role_group_desc2: 'グループはリソースの所属する基本単位であり、ユーザーやデバイスを構成でき、その派生リソース（履歴映像、事件、ログなど）はデフォルトでそのグループに帰属します。',
  role_group_desc3: 'グループは削除できません。',
  role_group_L1: 'L1：グループを表示できます。',
  role_group_L2: 'L2：グループを管理できます。',
  // role_roleManagement: 'Role management',
  role_roleManagement: '役割',
  role_roleManagement_tooltip: '役割を管理できます。',
  role_roleManagement_desc1: '役割管理機能を提供します',
  role_roleManagement_desc2: '『役割（Role）』は、アクセス範囲（Scope）や機能レベル（Level）を定義する権限の集合です。',
  role_roleManagement_desc3: 'ロール管理権限を持つオペレーターは、アクセススコープや権限レベルに対応するロールを作成または管理することができます。',
  role_roleManagement_desc4: 'ロールはグローバルスコープに属し、同じ名前を持つことはできません。',
  role_roleManagement_L1: 'L1：役割を表示できます。',
  role_roleManagement_L2: 'L2：役割を管理できます。',
  // 系統與服務
  role_systemAndServicePermission: 'システムとサービス',
  // role_system: '系統管理',
  role_system: 'システム設定',
  role_system_tooltip: 'システムパラメータを変更できます。',
  role_system_desc1: 'システム設定管理機能を提供します。',
  role_system_desc2: 'システムの設定やカスタマイズを調整するために使用されます。',
  role_system_L1: 'L1：システム設定を管理できます。',

  /* Role Notify / Message */
  role_notify_add_success: `ロールが正常に追加されました`,
  role_notify_add_info: `ロールを追加しました：<span style="${notify_style}">{name}</span>`,
  role_notify_add_fail: `ロールを追加できませんでした`,
  role_notify_edit_success: '編集された役割',
  // role_notify_edit_info: '成功編輯角色',
  role_notify_edit_info: `役割 <span style="${notify_style}">{name}</span> が編集されました。`, // `成功編輯角色 <span style="${notify_style}">{name}</span>`,
  role_notify_delete_confirm: '<span>{name}</span> を削除しますか？', // '確定刪除 <span>{name}</span> ？',
  role_notify_delete_success_title: '役割が正常に削除されました。',
  role_notify_delete_success_msg: `役割を削除：<span style="${notify_style}">{name}</span>`, // `刪除角色：<span style="${notify_style}">{name}</span>`,
  role_message_error_name_title: '名前の変更ができません',
  role_message_error_name_content: 'この名前は既に使用されています。別の名前を入力してください。',
  role_message_error_delete_title: '役割を削除できません',
  role_message_error_delete_prefix: '先に移行してください。',
  role_message_error_delete_suffix: '以下の全アカウントを他の役割に',
  role_message_error_delete_transfer: 'まずは<span>{name}</span> のすべてのユーザーを他の役割に移動してください', // '請先轉移 <span>{name}</span> 底下的所有使用者至其他角色。',

  /* 音效提示 */
  alarm_hint: 'ヒント',
  alarm_announcement: 'アナウンス',
  alarm_info: 'ブラウザーでの音声再生を許可しますか？',

  /* Dashboard: user tree */
  tree_search: 'グループ/機器を検索する',
  tree_online: 'オンライン/デバイスにアクセスする',
  tree_online_all: 'オンライン/すべて',
  tree_select_group: 'このグループのみを選択',
  tree_change_name: '名前を変更する',
  tree_change_video_title: 'ビデオタイトルを変更する',
  tree_change_group_name: 'グループ名を変更する',
  tree_push_talk: 'PTTグループ通話',
  tree_select_account: 'このアカウントのみを選択',
  tree_voice_call: '通話',
  tree_view_device: 'デバイスを表示する',
  tree_view_user: '檢視使用者',
  tree_view_account: '檢視帳號',
  tree_processing_info: '処理中...',
  tree_no_data: 'データなし',
  tree_contacts: '連絡先',

  /* Dashboard: video download */
  video_start_time: '開始時間',
  video_end_time: '終了時間',
  video_length: '動画の長さ',
  video_size: 'ビデオサイズ',
  video_date_placeholder: '開始と終了の時間を選択してください。',
  video_select_all: 'すべて選択する',
  video_unselect: 'キープをキャンセルする',
  video_keep_all: 'すべてをキープする',
  video_keep_select: 'チェックを入れたままにする',
  video_download_select: '選択したものをダウンロードする',
  video_delete_select: '選択したものを削除する',
  video_download: '動画をダウンロードする',
  video_keep: 'キープ',
  video_none_delete: '削除できるビデオはありません！',
  video_delete: '動画が削除されました！',
  video_none_select: '選択したデータはありません！',
  video_set_keep: 'ビデオが保持されました！',
  video_no_keep: 'ビデオの保持が解除されました！',
  video_delete_data: 'データを削除する',
  video_delete_checked: '選択したデータを削除しますか？',
  video_selected: '選択したデータ',
  video_total_of: '合計',
  video_of_records: '件データ',

  /* Video Control Button */
  video_ctrlbtn_ptz: 'リモートカメラの制御',
  video_ctrlbtn_time_axis: '履歴動画',
  video_search_none: '履歴動画が見つかりません！',

  /* Dashboard: event cards */
  event_tab_all: 'すべて',
  event_tab_match: '一致',
  event_tab_urgent: '緊急',
  event_tab_video: '映画',
  event_show_position: 'アカウントの位置を表示する',
  evnet_show_history: '関連する履歴記録を表示する',
  event_source: 'ソース',
  event_result: '一致した結果',
  event_notes: '備考',
  event_emergency: '緊急事態',
  event_switch: 'すべてのイベントを表示',
  event_not_db: 'データベース外の事件',
  event_chasing: '囲み込み',
  event_sos: 'SOS',

  /* PTZ */
  ptz_move_speed: '移動スピード',
  ptz_zoom: 'ズーム',
  ptz_focus: 'フォーカス',
  ptz_preset: '座標リスト',
  ptz_preset_placeholder: '新しい座標',
  ptz_preset_setting_success: '座標設定が成功しました',
  ptz_preset_setting_fail: '座標設定に失敗しました',
  ptz_vertical_mirror: '垂直ミラー',
  ptz_horizontal_mirror: '水平ミラー',
  ptz_infrared: '赤外線',
  ptz_high_sensitivity: '高感度',
  ptz_tooltip_zoom_out: '縮小',
  ptz_tooltip_zoom_in: '拡大',
  ptz_tooltip_open: 'オープン',
  ptz_tooltip_close: 'クローズ',
  ptz_tooltip_auto: 'オート',
  ptz_tooltip_auto_focus: 'オートフォーカス',
  ptz_tooltip_near_focus: 'クローズフォーカス',
  ptz_tooltip_far_focus: 'ロングフォーカス',

  /* History: Search Bar */
  search_tab_history: '通常検索', // 歷史事件
  search_tab_advanced_search: '詳細検索', // 進階搜尋
  search_cause: '事件の調査理由（必須）',
  search_cause_placehold: 'コンビニの駐車場に不審車両事件を調査',
  search_plate_number_required: '車番号（必須）',
  search_time_interval: '時間帯',
  search_select_date: '日付を選択する',
  search_select_time: '時間を選択する',
  search_time_placehold: '開始と終了の時間を選択してください。',
  search_hint: 'ヒント',
  search_hint_reason: '事件の理由を入力してください。',
  search_hint_reason_length: '事件の理由は30文字以内で入力してください',
  search_hint_fill_number: '「ナンバープレート」を入力してください',
  search_hint_fill_device: '「デバイス」を選択してください',
  search_hint_fill_time: '「時間帯」を選択してください。',
  search_hint_nodata: 'データが見つかりません',
  search_export: '結果をエクスポートする',
  search_device: 'デバイス',
  search_device_required: 'デバイス（必要）',
  search_device_placehold: '選択してください',
  search_device_unit: 'デパートメント',
  search_plate_number: '車番号',
  search_car_type: '車両タイプ',
  search_car: '自動車',
  search_motocycle: 'バイク',
  search_event_category: '事件カテゴリー',
  search_please_choose: '選択してください',
  search_all: 'すべて',
  search_subscription: 'サブスクリプション',
  search_mission: 'ミッション',
  search_mark: 'マーキング',
  search_round_up: '逮捕',
  search_not_specified: '未指定',
  search_misjudgment: '誤判定',
  search_non_misjudgment: '非誤判定',
  search_cofind: '捜査',
  search_check: '捜索協力',
  search_arrest: '取調べ',
  search_vehicle_marking: '車両マーキング',
  search_finished_status: '完了ステータス',
  search_finish: '完了',
  search_undone: '未完成',
  search_total_of: '合計',
  search_of_events: '件',
  search_timeout_message: 'コンテンツが多すぎるため、検索範囲を絞ってください',
  search_clear: 'クリア',
  search_adv_filter: 'アドバンス要件',
  search_channel_id: 'レンズ',

  /* History: History Events */
  history_event: 'History Events',
  history_event_lpr: '車番号',
  history_event_fr: 'Person',
  history_event_frid: 'Person ID',
  history_event_previous: '前の事件',
  history_event_next: '次の事件',
  history_event_wheelzoom: 'ホイールズーム',
  history_event_previous_tooltip: '前の事件（左クリック）',
  history_event_next_tooltip: '次の事件（右クリック）',
  history_event_back_tooltip: '閉じる（Esc）',
  history_event_map_goto: 'Google マップを開く',
  history_query_by_event: '事件で照会',
  history_device: '識別装置',
  history_tag: '車両マーキング',
  history_mission: 'ミッション',
  history_push_message: 'プッシュ通知',
  history_channel: 'レンズ',
  history_event_prev_page: '前のページ',
  history_event_next_page: '次のページ',
  history_event_no: '第',
  history_event_pages: '頁ページ',
  history_event_screen_capture: 'スクリーンショットを取得',

  /* History: 人物 */
  history_fr_name: '名前',
  history_fr_id: 'IDコード',
  history_fr_age: '年',
  history_fr_tag: 'キャラクターマーク',
  history_fr_remark: '述べる',
  history_fr_group: 'グループ',
  history_fr_device: '装置',
  history_fr_gps: 'GPSの位置',
  history_fr_open_google_map: 'グーグルマップを開く',
  history_fr_event_category: 'イベントカテゴリ',
  history_fr_cv: '信頼値',

  /* History: Urgent */
  history_device_group: 'グループ',
  history_link_user: 'ユーザー',
  history_user_group: 'ユーザー・グループ',

  /* QR Code Panel */
  qr_title: '携帯電話通報アプリをダウンロード',

  /* Dashboard: tooltips */
  previous_video: '前の動画',
  next_video: '次の動画',
  play: '再生',
  pause: '一時停止',
  mute: 'ミュート',
  unmute: 'ミュート解除',
  play_rate: '再生率',
  zoom_in: '画面を拡大',
  zoom_out: '画面を縮小',
  zoom_ratio: 'Zoom Ratio',
  show_only_events: 'このデバイスの事件のみ表示',
  download_video: '動画のダウンロード',
  full_screen: 'フルスクリーン',
  jump_to_live: 'ライブ画面へ移動します',
  the_day_before: '前日',
  the_day_after: '翌日',
  a_minute_ago: '1分前',
  one_minute_after: '1分後',
  zoom_out_the_play: '再生ウィンドウを縮小',
  enlarge_the_play: '再生ウィンドウを拡大',
  mode_1x1: '1x1 モード',
  mode_2x2: '2x2 モード',
  mode_3x3: '3x3 モード',
  mode_4x4: '4x4 モード',
  reorder_device_list: 'Reorder',
  collapse_the_play: '再生ウィンドウを閉じる',
  expand_the_play: '再生ウィンドウを開く',
  expand_event_list: '事件リストを開く',
  collapse_event_list: '事件リストを折りたたむ',
  collapse_device_list: 'デバイスリストを閉じる',
  expand_device_list: 'デバイスリストを閉じる',
  more: '詳しく',
  automatic_logout: 'アイドル状態の自動ログアウトを防止',
  no_selected_user: 'No selected device',
  no_streaming: 'No Streaming Available',
  no_permission_all: 'ライブ映像および履歴映像の視聴権限なし',
  no_permission_live_view: 'ライブ映像の視聴権限なし',
  no_permission_video: '履歴映像の視聴権限なし',
  no_live_stream: '再生可能な実時間ビデオがありません',
  no_video: '再生可能な過去のビデオがありません',
  video_pause: '一時停止画面にとどまる',
  mode_nxn_switch_hint: 'Please drop to device window for switch!',

  /* Account Management */
  am_title: 'アカウントと承認',
  am_user: 'ユーザー',
  am_device: 'デバイス',
  am_group: 'グループ',
  am_role: '役割',
  am_search_account: 'アカウント/アカウント名の検索',
  am_role_label: '役割',
  am_edit_not_complete: '編集が未完了です',
  am_cancel_edit: 'この編集をキャンセルしますか？',
  am_map_search_hint: '道路名またはGPSの検索',
  am_map_reset: '座標のリセット',
  am_user_create: 'ユーザーの追加',
  am_user_create_confirm: '一度追加したユーザーは、削除することができません。追加しますか？',

  /* Account Management-device */
  am_search_device: 'デバイス/デバイス名の検索',
  am_device_name: '名前',
  am_device_video_name: '動画名',
  am_device_event_keep: '事件のキープ',
  am_device_video_keep: '動画のキープ',
  am_group_tree_search: 'グループの検索',
  am_device_keep_time: 'キープ期間',
  am_device_keep_time_tip: 'システムデフォルトのキープ期間',
  am_device_keep_time_edit: 'キープ期間の編集',
  am_device_create: 'デバイスの追加',
  am_device_create_confirm: '一度追加したデバイスは、削除することができません。追加しますか？',
  am_device_type_default: '一般',
  am_device_type_bwc: 'ボディーカメラ',
  am_device_type_patrol: 'パト役割',
  am_device_type_phone: '電話',
  am_device_type_ptz: 'PTZ カメラ（球体）',
  am_device_type_box: 'PTZ カメラ（ボックス型）',

  /* Account Management-group */
  am_search_group: 'グループ検索',
  am_group_title: 'グループ',
  am_view_quota_title: 'クォータの表示',
  am_live_quota_title: 'クォータを配布',
  am_quota_desc: '使用済/配布済/すべて',
  am_quota_edit: 'クォータの編集',
  am_quota_editing: '編集中',
  am_add_group: 'グループの追加',
  am_parent_group_empty: '所属するグループ名',
  am_parent_group: '所属グループ',
  am_parent_group_hint: '所属するグループ',
  am_group_name: 'グループ名',
  am_group_name_hint: '新しいグループ(1)',
  am_group_id: 'ID',
  am_group_updated_time: '変更日時',
  am_group_create_time: '作成日時',
  am_add_group_fail: 'グループの追加にエラーが発生しました',
  am_add_group_pass: 'グループの追加が正常に完了しました',
  am_edit_group_fail: 'グループの編集にエラーが発生しました',
  am_edit_group_pass: 'グループの編集が正常に完了しました',
  am_quota_short_hint1: 'グループ',
  am_quota_short_hint2: 'クォータ不足',
  am_group_create_confirm: '一度追加したグループは削除できません。追加しますか？',

  /* Account Management-role */
  am_role_helper_title: '役割管理',
  am_role_helper_intro: '「「役割（Role）」は、様々なシステムリソースのアクセス範囲と機能レベルを定義するために設計された権限の集合体である。役割は、ユーザーアカウントに適用して、そのアカウントが操作できる権限レベルを調整することができます。',
  am_role_helper_desc1: '上位レベルには、下位レベルのアクセス範囲および機能項目が含まれます。',
  am_role_helper_desc1_sub1: 'アクセス範囲（スコープ）： レベルは低いものから高いものまであり、レベルが上がるにつれて範囲が広くなります。',
  am_role_helper_desc1_sub2: '機能レベル（レベル）： レベルは低いものから高いものまであり、レベルが上がるとより多くの権限が与えられる。',
  am_role_helper_desc2: '役割はグローバルなものであり、重複して使用することはできません。',

  /* System Setting */
  system_global_setting: 'グローバル設定',
  system_custom_web: 'カスタムウェブページ',
  system_backend_setting: 'バックエンドの設定',
  system_server_capability: 'サーバー機能',
  system_aibox: 'Ai Boxの管理',
  system_custom_system_name: 'システム名',
  system_custom_idle_logout_time: 'アイドルログアウト時間',
  system_custom_logout_not: 'ログアウトしない',
  system_custom_app_download: 'APPのダウンロードリンクを表示する',
  system_custom_manual_download: 'マニュアルのダウンロードリンクを表示する',
  system_custom_customize: 'Customer',
  system_custom_favicon: 'タブ上の小アイコン favicon 32*32',
  system_custom_banner: '左サイドバーの展開バナー 130*56',
  system_custom_logo: '左サイドバーの開閉アイコン 56*56',
  system_custom_upload: '画像をアップロードする',
  system_custom_restore: 'デフォルトに戻す',
  system_custom_restore_info: '上記のアイコン設定をデフォルト値に戻します。',

  /* System Setting - AiBoxMgr */
  setting_aibox_top_box: '識別ボックス',
  setting_aibox_top_ip: 'IP',
  setting_aibox_top_count: '識別ボックスの数',
  setting_aibox_top_abnormal_count: '異常数',
  setting_aibox_page_start: '第',
  setting_aibox_page_end: '件',
  setting_aibox_page_total: '検索結果',
  setting_aibox_total_of: 'データベース総',
  setting_aibox_of_records: '件データ',
  setting_aibox_goto_page: 'ページ送り',
  setting_aibox_state_normal: '正常',
  setting_aibox_state_abnormal: '異常',
  setting_aibox_table_name: '識別ボックス',
  setting_aibox_table_ip: 'IP',
  setting_aibox_table_udid: 'UDID',
  setting_aibox_table_status: 'タスク',
  setting_aibox_table_temp: '温度',
  setting_aibox_table_cpu: 'CPU',
  setting_aibox_table_gpu: 'GPU',
  setting_aibox_table_mem: 'MEM',
  setting_aibox_table_hd: 'HDD',
  setting_aibox_table_recognition_src: '識別リソース',
  setting_aibox_table_updated_time: '更新時間',
  setting_aibox_delete_title: 'データ削除',
  setting_aibox_task_device: 'デバイス',
  setting_aibox_task_recognition_type: '識別カテゴリ',
  setting_aibox_task_recognition_state: '識別ステータス',
  setting_aibox_task_status_0: '識別中',
  setting_aibox_task_status_10: 'デバイス未公開',
  setting_aibox_task_status_11: 'ストリーム接続中',
  setting_aibox_task_status_12: '許可数不足',
  setting_aibox_task_status_13: 'システムエラー',
  setting_aibox_task_status_14: '設定ファイルバージョンがサポートされていません',
  setting_aibox_task_status_15: '識別機能がサポートされていません',
  setting_aibox_task_recognition_or: 'オブジェクト',
  setting_aibox_task_recognition_lpr: '車両',
  setting_aibox_delete_aibox: 'この識別ボックスを削除しますか？?',
  setting_aibox_delete_notify_title: '識別ボックスの削除',
  setting_aibox_delete_pass: '識別ボックスの削除に成功しました',
  setting_aibox_delete_fail: '識別ボックスの削除に失敗しました',
  setting_aibox_delete_aibox_task: 'このデバイスの識別タスクを削除しますか？',
  setting_aibox_task_delete_notify_title: '識別タスクの削除',
  setting_aibox_task_delete_pass: '識別タスクの削除に成功しました',
  setting_aibox_task_delete_fail: '識別タスクの削除に失敗しました',

  /* AI Box */
  ai_recognition_setting: '識別設定',
  ai_local_recognition: 'ローカル識別',
  ai_person_recognition :'人物認識',
  ai_could_recognition: 'クラウド識別',
  ai_object_recognition: 'オブジェクト識別',
  ai_object: 'オブジェクト',
  ai_license_plate_recognition: '車両のナンバープレート識別',
  ai_update_time: '更新時間',
  ai_box: '識別ボックス',
  ai_task_add: '追加中',
  ai_task_edit: '編集中',
  ai_task_delete: '削除中',
  ai_status_0: '識別中',
  ai_status_10: 'デバイス未公開',
  ai_status_11: 'ストリーム接続中',
  ai_status_12: '許可数不足',
  ai_status_13: 'システムエラー',
  ai_status_14: '設定ファイルバージョンがサポートされていません',
  ai_status_15: '識別機能がサポートされていません',
  ai_try_connect: 'ストリームに接続を試みています',
  ai_select_aibox: '識別ボックスを選択してください',
  ai_temperature: '温度',
  ai_memory: 'MEM',
  ai_hard_disk: 'HDD',
  ai_resources: '識別リソース',
  ai_other_resources: 'その他の識別ボックス',
  ai_add_notification_task: '通報タスクの追加',
  ai_image_preview: '画像プレビュー',
  ai_set_time: '設定時間',
  ai_recg_fps_level: '識別頻度',
  ai_repeat_interval: '重複イベントの間隔',
  ai_stay_sec: '滞在時間',
  ai_category: 'カテゴリ',
  ai_setter: '設定者',
  ai_notice: '備考',
  ai_receiver: '受信者',
  ai_unit_name_placeholder: '部署または名前',
  ai_show_checked: 'チェック済みの表示',
  ai_edit_receiver: '受信者の編集',
  ai_mode: 'モード',
  ai_object_detect: 'オブジェクト検出',
  ai_motion_detect: '移動検出',
  ai_detect_target_threshold: '検出目標の閾値',
  ai_motion_level: '検出感度',
  ai_advanced_settings: '高度な設定',
  ai_person_level: '人',
  ai_car_level: '自動車',
  ai_bike_level: '二輪車',
  ai_truck_level: 'トラック',
  ai_bus_level: 'バス',
  ai_warning_area: '警戒区',
  ai_delete_data: 'データを削除する',
  ai_sure_to_delete: 'この識別を削除してもよろしいですか？',
  ai_device: '装置',
  ai_recognition_category: '識別カテゴリ',
  ai_recognition_status: '識別状態',
  ai_level_0: '閉める',
  ai_level_1: '最低',
  ai_level_2: '低',
  ai_level_3: '中',
  ai_level_4: '高',
  ai_level_5: '最高',
  ai_motion_level_1: '低',
  ai_motion_level_2: '中',
  ai_motion_level_3: '高',
  ai_insufficient_recognition_resources: '識別リソースが不足しています',
  ai_no_boxes_available: '申し訳ありませんが、使用可能な識別ボックスがありません。',
  ai_edit_not_complete: '編集が完了していません',
  ai_cancel_edit: 'この編集をキャンセルしてもよろしいですか？',
  ai_area1: 'エリア 1',
  ai_area2: 'エリア 2',
  ai_area3: 'エリア 3',
  ai_new_recognition: '識別タスクを追加',
  ai_recog_task_add_successfully: '識別タスクの追加に成功しました',
  ai_roi_set: '識別範囲の調整',
  ai_roi_lock: '識別範囲をロックする',
  ai_tooltip_roi_reset: 'デフォルトの範囲に復元する',
  ai_tooltip_or: 'オブジェクト検出: 選択されたオブジェクトが検出された場合に通知を送信します',
  ai_tooltip_md: '移動検出: 画面内で移動物体が検出された場合に通知を送信します',
  ai_tooltip_threshold: '閾値が低いほど、画面上のオブジェクトをより多く見つけることができますが、同時に精度が低下する可能性もあります',
  ai_tooltip_motion: '感度が高いほど、画面内の移動変化に対してより敏感になります',
  ai_tooltip_recg_fps_level: 'オブジェクトの移動速度が速いほど、識別頻度を高く設定することをお勧めします',
  ai_tooltip_repeat_interval: 'イベントが再発生すると、この設定時間の間隔で再び通知を送信し、イベントをアップロードします',
  ai_tooltip_stay_sec: 'オブジェクトが警戒区域に滞在する時間が経過した後に通知を送信します',
  ai_tooltip_warning_area: '最大で8つのポイントの多角形で、オブジェクトが警戒区域に触れると通知が送信されます。',

  /* AI Box - recognition setting */
  ai_tag: 'キャラクターマーク',
  ai_remark: '述べる',
  ai_editor: '設定者',
  ai_expert_mode: 'エキスパートモード',
  ai_full_face_mode: 'フルフェイスモード',
  ai_mask_mode: 'マスクモード',
  ai_param_source: 'パラメータソース',
  ai_global_config: 'システムのデフォルト',
  ai_user_config: 'カスタマイズ',
  ai_param_source_tip: '<strong style="color: #FFE99F;">システムのデフォルト</strong>: システムのデフォルトのパラメータを使用します。<br/><strong style="color: #FFE99F;">カスタマイズ</strong>: カスタマイズされたパラメータを使用します。',
  ai_enable: '有効',
  ai_enable_tip: '顔認識機能を有効/無効にすることができます',
  ai_model_country: 'モデルの国',
  ai_model_country_tip: 'モデルの国を選択することで、モデルの精度を向上させることができます',
  ai_face_size_ratio: '顔のサイズ比率',
  ai_face_size_ratio_tip: '顔のサイズ比率を調整することで、顔のサイズを調整することができます',
  ai_detect_mode: '比較モード',
  ai_precise_detect: '正確な比較',
  ai_quick_detect: '簡単な比較',
  ai_detect_mode_tip: '<strong style="color: #FFE99F;">正確な比較</strong>: より高い精度の結果を出力できます; <br/><strong style="color: #FFE99F;">高速比較</strong>: 識別結果を迅速に出力できます',
  ai_trigger_score: 'トリガースコア',
  ai_candidate_score: '候補者のスコア',
  ai_full_face_trigger_score_tip: '正面モードでの類似度の閾値です。閾値が低いほど多くの比較結果が生成されますが、同時に精度が低下する可能性があります。',
  ai_full_face_candidate_score_tip: 'フルフェイスモードにおける候補の閾値です。閾値が低いほど、より多くの候補を参照できます。',
  ai_mask_trigger_score_tip: 'マスクモードでの類似度の閾値です。閾値が低いほど多くの比較結果が生成されますが、同時に精度が低下する可能性があります。',
  ai_mask_candidate_score_tip: 'マスクモードにおける候補文字の閾値です。閾値が低いほど、参照する候補が多くなります。',
  ai_detect_threshold_level: 'ノイズカー閾値',
  ai_detect_threshold_level_tip: '顔検出のしきい値。しきい値が低いほど、画面上でより多くの顔を検出できますが、同時に精度が低下する可能性があります。',
  ai_event_post_mode: 'アップロードモード',
  ai_event_post_mode_tip: '<strong style="color: #FFE99F;">一致一致</strong>: 一致したイベントのみがアップロードされます; <br/><strong style="color: #FFE99F;">すべての識別</strong>: すべての識別結果がアップロードされます',
  ai_event_match: '一致する',
  ai_event_all: '全て',
  ai_notify_filter_mode: 'イベントレポートモード',
  ai_notify_filter_mode_tip: '<strong style="color: #FFE99F;">一致のみ</strong>: 一致するイベントのみが報告されます。<br/><strong style="color: #FFE99F;">すべて認識</strong>: すべてのイベントが通知されます。<br/><strong style="color: #FFE99F;">一致しない</strong>: 一致しないイベントのみが報告されます。<br/><strong style="color: #FFE99F;">報告されません</strong>:イベントは報告されません。',
  ai_notify_match: '一致する',
  ai_notify_all: '全て',
  ai_notify_not_match: '比較が一致しません',
  ai_notify_none: '報告しないこと',
  ai_event_duration: 'イベントの繰り返し間隔',
  ai_noise_event_duration: '噪音事件重複間隔',
  ai_event_duration_tip: 'イベントが繰り返し発生した場合、設定した時間間隔後に再度アップロードイベントが送信されます。',
  ai_noise_event_duration_tip: '當噪音車輛事件重複發生時, 間隔此設定時間後才會再次發出上傳事件',
  ai_repost_data_store_limit: '再投稿イベントの保持制限',
  ai_repost_data_store_limit_tip: 'イベントが繰り返し発生した場合、設定した回数後に再度アップロードイベントが送信されます。',
  ai_lpr_frequency: '識別頻度',
  ai_lpr_frequency_tip: '1秒あたりに認識されるフレーム数',
  ai_lpr_size_ratio: '最小ナンバープレート比率',
  ai_lpr_size_ratio_tip: '検出された最小ナンバー プレート サイズ (画面に対する割合)',
  ai_lpr_detect_mode: '検出モード',
  ai_noise_car_decibel_threshold_tip: '分貝值達閾值時, 發出噪音車事件',
  ai_confirm_count: '確認回数',
  ai_confirm_count_tip: '値が大きいほど結果が出力されにくくなりますが、出力結果の精度は高くなります。',
  ai_accept_diff_num: '許容される数値の差',
  ai_accept_diff_num_tip: '認識結果を修正する桁数',
  ai_indent: '識別境界',
  ai_indent_tip: '検出されたナンバープレートがこの境界を超える場合、認識結果は出力されません',
  ai_is_complete_mode: 'フルモード',
  ai_is_complete_mode_tip: '有効にすると完全な識別結果のみが出力され、それ以外の場合は識別結果のみが出力されます。',
  ai_roi: '識別範囲',
  ai_roi_tip: '境界線を引いて認識範囲を調整可能',
  ai_event_unit: 'ペン',
  ai_low: '低い',
  ai_medium: '中い',
  ai_high: '高い',
  ai_setting_success: '設定成功',
  ai_setting_fail: 'セットアップに失敗しました',

  /* AI Box - LPR */
  ai_lpr_tag: '車両マーキング',

  /* 人辨資料庫 */
  fr_title: '人物管理',
  fr_info: '人物資訊',
  fr_filter_name: 'Name',
  fr_filter_name_hint: '王小明',
  fr_filter_tag: 'Tags',
  fr_filter_time: 'Time Interval',
  fr_filter_id: 'ID Card',
  fr_filter_id_hint: 'Z123456789',
  fr_filter_feature: 'Feature',
  fr_filter_enabled: 'FR Info',
  fr_filter_tooltip: 'Adv. Filter',
  frInfo_idCard_filter: 'ID NO.',
  frInfo_feature_filter:'FR Feature',
  frInfo_feature_filter_all: '全部',
  frInfo_feature_filter_0:'無啟用',
  frInfo_feature_filter_1:'任一啟用',
  frInfo_feature_filter_2:'任一停用',
  frInfo_enabled_filter:'FR Info',
  frInfo_enabled_filter_all: '全部',
  frInfo_enabled_filter_0:'停用',
  frInfo_enabled_filter_1:'啟用',
  fr_new_fr_tooltip: 'Create FR',
  fr_db_info_tooltip: 'DB Info',
  fr_tag_mgr_tooltip: 'FR Tags',

  fr_tag_mgr_title: 'FR Tag Manager',
  fr_tag_add: 'Create FR Tag',
  fr_tag_add_placeholder: 'Create Tag',
  fr_tag_duplicate_hint: 'The tag has existed, please input again.',
  fr_tag_total: '{total} tag(s)',
  fr_tag_total_count: '{count}/{total} tag(s)',
  fr_tag_add_hint: '新增後無法刪除標記，確定新增？',
  fr_tag_add_pass: 'Create FR tag successed.',
  fr_tag_add_fail: 'Create FR tag failed.',

  fr_db_info_title: 'FR DB Info',
  fr_db_info_data_cnt: '{count}筆',
  fr_db_info_data_latest_sn: '最新序號：',
  fr_db_info_data_update_time: '更新時間：',
  fr_db_info_db: '資料庫',
  fr_db_info_data_fr_cnt: '人物筆數：',
  fr_db_info_feature: '特徵值',
  fr_db_info_data_used: '已使用{percent}%',
  fr_db_info_data_reg_cnt: '註冊筆數：',
  fr_db_info_data_quota: '授權配額：',
  fr_db_info_db_package: '資料庫封裝',
  fr_db_info_data_version: 'Version：',

  fr_table_photo: '註冊圖',
  fr_table_name: 'Name',
  fr_table_id: 'ID Card',
  fr_table_tag: 'Tags',
  fr_table_update_time: 'Update Time',
  fr_table_create_time: 'Create Time',
  fr_table_empty_hint: 'Please input query conditions',
  fr_table_delete_pass: 'Delete Succeed',
  fr_table_delete_fail: 'Delete Failed',

  fr_info_data_title: 'FR Info',
  fr_info_data_enable: 'Enable',
  fr_info_data_disable: 'Disable',
  fr_info_data_name: 'Name',
  fr_info_data_gender: 'Gender',
  fr_info_data_id: 'ID',
  fr_info_data_age: 'Age',
  fr_info_data_age_edit: 'Borthday',
  fr_info_data_tag: 'Tag',
  fr_info_data_note: 'Note',
  fr_more: 'MORE',
  fr_less: 'LESS',
  fr_info_data_phone: 'Phobe',
  fr_info_data_birth_place: 'Birth Place',
  fr_info_data_city: 'City',
  fr_info_data_address: 'Address',
  fr_info_data_job: 'Job',
  fr_info_photo_title: '註冊圖',
  fr_info_photo_add: '新增註冊圖',
  fr_info_other_title: 'Data',
  fr_info_other_src: 'Source',
  fr_info_other_src_0: '自建',
  fr_info_other_src_1: '匯入',
  fr_info_other_update_time: 'Update Time',
  fr_info_other_create_time: 'Create Time',
  fr_info_data_required: '必填',
  fr_info_data_phone_hint: '僅允許 數字、加號、減號、括弧',
  fr_info_data_gender_0: '其他',
  fr_info_data_gender_1: '男',
  fr_info_data_gender_2: '女',
  fr_info_data_gender_9: '未知',
  fr_feature_1: '特徵值無效',
  fr_feature_2: '特徵值不可用',
  fr_feature_3: '特徵值較差',
  fr_feature_4: '特徵值可用',
  fr_feature_5: '特徵值最佳',
  fr_mask_0: 'No mask',
  fr_mask_1: 'Had mask',
  fr_action_set_avatar: 'Set Acatar',
  fr_action_download_photo: 'Download Photo',
  fr_action_delete_feature: 'Delete Feature',
  fr_no_birthday: "(Year/Month/Day)",
}

// console.log(`ja:`, Object.keys(ja).length)

export default ja
