const initialState = () => ({
  showPtzPanel: false,
  autoFocus: false,
  cam: '',
  capability: {
    highSensitivity: false,
    step: false
  },
  configured: [],
  flip: false,
  highSensitivity: false,
  icrMode: 0,
  mirror: false,
  preset: 0,
  pt: '',
  step: 1,
  zoomStep: 6,
  focusStep: 6,
  version: '',
  framerate: '',
  resolution: '',
  bitrate: '',
  protocol: '',
})

const state = initialState()

const mutations = {
  updatePtzData(state, payload) {
    Object.entries(payload).forEach(([key, value]) => {
      state[key] = value
    })
  },
  resetPtzData(state) {
    const defaultState = initialState()
    Object.keys(state).forEach(key => {
      state[key] = defaultState[key]
    })
  },
  updatePtzKeyValue(state, key, value) {
    state[key] = value
  },
  updateShowPtzPanel(state, payload) {
    state.showPtzPanel = payload
  },
  updateAutoFocus(state, payload) {
    state.autoFocus = payload
  },
  updateMirror(state, payload) {
    state.mirror = payload
  },
  updateFlip(state, payload) {
    state.flip = payload
  },
  updateHighSensitivity(state, payload) {
    state.highSensitivity = payload
  },
  updateIcrMode(state, payload) {
    state.icrMode = payload
  },
  updateStep(state, payload) {
    state.step = payload
  },
  updateZoomStep(state, payload) {
    state.zoomStep = payload
  },
  updateFocusStep(state, payload) {
    state.focusStep = payload
  },
  updateFramerate(state, payload) {
    state.framerate = payload
  },
  updateResolution(state, payload) {
    state.resolution = payload
  },
  updateConfigured(state, payload) {
    state.configured = payload
  },
}

const actions = {}

const getters = {
  isSupportPtz(state, getters, rootState) {
    // 名稱為R3開頭或內有PTZ的即為PTZ設備
    const live = rootState.liveList?.find((item) => item.id == rootState.singleUrlUserID)
    const type = live ? live.device.info.type : ''
    const regex = /^R3/
    return regex.test(type) || type.includes('PTZ') 
  },
  ptzDeviceId(state, getters, rootState) {
    const live = rootState.liveList?.find((item) => item.id == rootState.singleUrlUserID)
    return live?.device.id
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}