// Note: 維持 Key: [string]; 不換行, 方便語系比對閱讀
const notify_style = 'font-size: 24px; font-style: italic; font-weight: 500; color: #303133'

const tw = {
  /* project */
  system_name: '5G AI Patrol System',
  system_language: '語言',
  system_timezone: '時區',
  system_default: '後台系統預設',

  /* global */
  yes: '是',
  no: '否',
  cancel: '取消',
  ok: '確定',
  save: '儲存',
  close: '關閉',
  delete: '刪除',
  confirm: '確認',
  finish: '完成',
  error: '錯誤',
  edit: '編輯',
  view: '檢視',
  none: '無',
  custom: '自訂',
  total: '共',
  go: '前往',
  loading: '讀取中',
  selected: '已選擇',
  search: '搜尋',
  pre: '上一步',
  next: '下一步',
  stop: '停用',
  unknown: '未知',
  tag: '標記',
  license_plate_example: 'ABC-1234',
  on: '開啟',
  off: '關閉',
  device: '設備',
  vehicle: '車輛',
  mission: '任務',
  name: '名稱',
  attribute: '屬性',
  link: '勾稽',
  update_time: '更新時間',
  all: '全部',
  adv_search: '進階搜尋',
  search_result: '搜尋結果：{count}筆',
  new_tag_hint: '新標記',
  event: '事件',
  decibel_meter: '分貝計',
  hint: '提示',
  collapse: '收合',
  expand: '展開',
  search_fail_title: '搜尋失敗',
  no_subscription: '未登記',
  delete_data: '刪除資料',
  delete_data_confirm: '確定刪除 {count} 筆資料？',
  age: '{age}Y',

  /* alarm */
  data_error: '資料錯誤',

  /* language */
  lang_zh: '繁體中文',
  lang_en: 'English',
  lang_ja: '日本語',

  /* week & time */
  sun: '日',
  mon: '一',
  tue: '二',
  wed: '三',
  thu: '四',
  fri: '五',
  sat: '六',
  month: '月',
  day: '天',
  one_day: '天',
  hour: '小時',
  min: '分鐘',
  one_min: '分鐘',
  sec: '秒',
  month_ago: '個月前',
  day_ago: '天前',
  hour_ago: '小時前',
  min_ago: '分鐘前',
  sec_ago: '秒前',
  one_min_later: '分鐘後',
  min_later: '分鐘後',
  today: '今天',
  yesterday: '昨天',
  last_week: '過去1週',
  last_month: '過去1個月',
  last_three_months: '過去3個月',
  half_year_ago: '過去半年',
  custom_time: '自訂時間',

  /* login */
  login_id: '帳號',
  login_pw: '密碼',
  login_hint: '請輸入帳號及密碼！',
  login_account_password_error: '帳號或密碼不正確',
  login_npa_hint: '請聯絡系統管理員',

  /* Api */
  api_400: '缺少必要資訊',
  api_401_login: `帳號或密碼錯誤`,
  api_403: `沒有權限`,
  api_404: `未提供資源`,
  api_404_login: `帳號或密碼錯誤`,
  api_500: `伺服器錯誤`,
  api_error: `Something Error`,
  api_econnaborted: '伺服器連線中斷。',
  api_timeout: '請求逾時。',

  /* left bar */
  left_bar_dashboard: '勤務派遣',
  left_bar_history: '歷史事件',
  // left_bar_subscription: '車號管理',
  left_bar_subscription: '比對資料庫',
  left_bar_recognition: '比對資料庫',
  left_bar_lpr_recognition: '車號管理',
  left_bar_fr_recognition: '人物管理',
  left_bar_account_management: '帳號與權限',
  left_bar_system_setting: '系統與服務',
  left_bar_download_app: '下載中心',
  left_bar_export_center: '匯出中心',
  left_bar_account: '我的帳號',
  left_bar_call: '通話',
  left_bar_joined_call: '已加入通話',
  left_bar_left_call: '已離開通話',
  left_bar_end_call_before: '請先結束目前的通話，再開啟新通話',
  left_bar_cant_call_myself: '不能打電話給自己',
  left_bar_15_seconds_hangup: '15秒沒人接聽 自動掛斷通話',
  left_bar_failed_sdp_speak: '交換 SDP speak 或發送語音通知失敗，中斷通話連線',
  left_bar_another_webpage_call: '您已經在別的網頁開啟通話中，必須關閉才能重新開啟通話',
  left_bar_other_has_hungup: '對方已掛斷電話',
  left_ber_userinfo_change_password: '變更密碼',
  left_bar_userinfo_username_placeholder: '使用者名稱（限制20個字）',
  left_bar_userinfo_email_placeholder: '電子郵件',
  left_bar_userinfo_phone_placeholder: '電話號碼',

  /* top bar */
  top_bar_keep_login: '保持登入',
  top_bar_online_devices: '上線設備',
  top_bar_calling_volume_off: '通話中，影片已靜音',
  top_bar_calling_volume_reverse: '通話結束，影片音量已恢復',

  /* 下載APP */
  download_notify_app: '通報APP',
  download_info: '請使用個人手機掃描二維條碼並下載通報APP',
  download_android: '安卓版',
  download_ios: 'iOS版',
  download_manual: '使用手冊',
  download_manual_fn: '使用手冊.pdf',
  download_tool: '工具',
  download_search_placeholder: '搜尋名稱',
  download_name: '名稱',
  download_version: '版本',
  download_update_time: '更新時間',
  download_size: '檔案大小',
  download_os: '作業系統',
  download: '下載',
  download_no_info: '無資料',

  /* 匯出中心 */
  export_category: '類別',
  export_filename: '檔名',
  export_expiration_time: '過期時間',
  export_file_size: '檔案大小',
  export_status_download: '下載',
  export_status_fail: '匯出失敗',
  export_status_exporting: '匯出中',
  export_status_deleting: '刪除中',
  export_warning_title: '無法匯出資料',
  export_warning_content: '匯出中心已達5筆資料上限，請清除舊有資料。',
  export_custom_filename: '自訂檔名',
  export_search_criteria: '搜尋條件',
  export_plate_number: '車號',
  export_time_interval: '時間區間',
  export_event_category: '事件類別',
  export_mission: 'mission',
  export_finished_condition: '完成狀態',
  export_category_basic: '歷史事件-一般查詢',
  export_category_advanced: '歷史事件-進階查詢',
  export_category_performance: '績效查詢',
  export_category_audit: '稽核查詢',

  /* 二因子認證-2FA */
  twoFactorAuth_title: '雙重驗證（2FA）',
  twoFactorAuth_installApp: '使用驗證應用程式',
  twoFactorAuth_installApp_desc1: '使用 Google Authenticator 或 Duo Mobile 等應用程式來產生驗證碼，藉此加強防護。',
  twoFactorAuth_installApp_desc2: '如還未安裝驗證應用程式，請先進行安裝，以進行下一步。',
  twoFactorAuth_scanQrCode: '透過第三方驗證應用程式設定',
  twoFactorAuth_scanQrCode_desc: '請使用您的驗證應用程式（例如 DUO 或 Google Authenticator）來掃描 QR 碼。',
  twoFactorAuth_inputCode: '輸入確認碼',
  twoFactorAuth_inputCode_login: '輸入確認碼',
  twoFactorAuth_inputCode_desc: '請輸入驗證應用程式中的確認碼。',
  twoFactorAuth_done: '已啟用',
  twoFactorAuth_done_desc: '此帳號的雙重驗證（2FA）設定已啟用。',
  twoFactorAuth_enabled_pass: '啟用成功',
  twoFactorAuth_enabled_fail: '啟用失敗',
  twoFactorAuth_disabled_pass: '停用成功',
  twoFactorAuth_disabled_fail: '停用失敗',
  twoFactorAuth_validate_pass: '驗證成功',
  twoFactorAuth_validate_fail: '驗證失敗',
  twoFactorAuth_error_getQrCode: '取得 QR Code 失敗',
  twoFactorAuth_error_inputNumber: '請輸入數字',
  twoFactorAuth_error_fullCode: '請輸入完整驗證碼',

  /* account */
  account_title: '帳號',
  account_name: '名稱',
  account_role: '角色',
  account_change_password: '重置密碼',
  account_disable_2fa: '重置雙重驗證（2FA）',
  account_id_tip: '請輸入{min}-{max}個字元，包含英文、數字與任一特殊符號',
  account_id_length_error: '帳號長度需{min}-{max}個字元。',
  account_id_char_error: '帳號只允許英數，大小寫，及特殊字元',
  account_edit_password: '修改密碼',
  account_edit_password_tip1: '1.密碼長度需{min}～{max}個字元。',
  account_edit_password_tip2: '2.請輸入任一特殊字元',
  account_pw_tip: '請輸入{min}-{max}個字元，包含英文、數字與任一特殊符號',
  account_new_pw: '新密碼',
  account_reenter_pw: '再次輸入您的新密碼',
  account_pw_length_error: '密碼長度需{min}～{max}個字元',
  account_pw_char_error: '密碼只允許英數，大小寫，及特殊字元',
  account_pw_special_char_error: '密碼須包含任一特殊字元',
  account_pw_error: '兩個密碼不一致，請確認！',
  account_pw_null: '密碼不能是空值！',
  account_pw_success: '已成功修改密碼。',
  account_pw_failed: '修改密碼發生錯誤',
  account_2fa: '雙重驗證（2FA）',
  account_logout: '登出',
  account_logout_hint: '你確定要離開嗎？',
  account_delete_confirm: '確定刪除',
  account_notify_edit_title: '修改帳號資料',
  account_notify_edit_success: '已成功修改帳號資料',
  account_notify_edit_failed: '修改帳號發生錯誤',
  account_add_role: '新增角色',
  account_edit_role: '編輯角色',
  account_copy_role: '複製角色',
  account_delete_role: '刪除角色',
  account_add: '新增帳號',
  account_add_success: '已成功新增帳號',
  account_add_failed: '新增帳號發生錯誤',
  account_required: '<span class="required">*</span> 為必填項目',
  account_detail_group_accound: '群組與帳號',
  account_detail_group: '群組',
  account_detail_id: '帳號',
  account_detail_password: '密碼',
  account_required_info: '此為必填',
  account_viewing_quota: '收看配額',
  account_multi_select: '多選帳號',
  account_cancel_multi_select: '取消多選模式',
  account_selected: '已選帳號',
  account_batch_selected_account_only: '顯示勾選帳號({count})',
  account_batch_edit_items: '批次修改項目',
  account_add_items: '新增項目',
  account_abnormal_account: '異常帳號',
  account_filter_result: '數量：{count}',
  account_batch_modified: '已批次修改',
  account_batch_n: '{pass_count} 筆成功，{fail_count} 筆失敗。',
  account_n_success: '筆成功',
  account_n_failed: '筆失敗',
  account_active: '啟用中',
  account_not_activated: '停用',
  account_enabled: '啟用',
  account_disabled_until: '暫時停用至',
  account_disabled: '停用',
  account_suspend: '暫停',
  account_status: '啟用狀態',
  account_status_enabled: '啟用',
  account_status_disable: '停用',
  account_status_locked: '暫停',
  account_group: '群組',
  account_id: '帳號',
  account_password: '密碼',
  account_confirm_password: '確認密碼',
  account_detail_name: '名稱',
  account_all: '全部',
  account_public_title: '公開狀態',
  account_public_all: '全部',
  account_public_0: '私有',
  account_public_1: '公開',
  account_default_setting: '參數來源',
  account_defaultSetting_1: '系統預設',
  account_defaultSetting_0: '設備客製',
  account_watermark: '浮水印',
  account_watermark_0: '停用',
  account_watermark_1: '啟用',
  account_detail_linkmode_title: '勾稽',
  account_linkmode_title: '勾稽模式',
  account_linkmode_all: '全部',
  account_linkmode_0: '停用',
  account_linkmode_1: 'BoviLive',
  account_linkmode_2: 'BoviLive - 強制',
  account_linkmode_3: ' NPA',
  account_linkmode_4: 'NPA - 強制',
  account_linkmode_group: '群組',
  account_linkmode_user: '使用者',
  account_type: '帳號類型',
  account_kind_default: '預設',
  account_kind_sso: 'SSO(單簽)',
  account_kind_device: '設備',
  account_kind_user: '使用者',
  account_kind_0: '預設',
  account_kind_1: 'SSO(單簽)',
  account_kind_2: '設備',
  account_kind_3: '使用者',
  account_label: '帳號名稱',
  account_lang: '語系',
  account_tz: '時區',
  account_video_title: '影片標題',
  account_detail_property: '屬性',
  account_lpr_keep: '事件保留',
  account_video_keep: '影片保留',
  account_keep_day: '保留時間',
  account_device_model: '設備類型',
  account_device_model_0: '其他',
  account_device_series_10: 'BoviCam 系列',
  account_device_series_100: 'AiCam 系列',
  account_device_series_200: 'Bodyworn Cam 系列',
  account_device_series_500: 'FaceArk 系列',
  account_device_series_1000: 'Android APP 系列',
  account_device_series_2000: 'iOS APP 系列',
  account_device_series_3000: 'Mac 系列',
  account_ip_source: 'IP來源',
  account_gps: 'GPS位置',
  account_from: '帳號來源',
  account_last_action: '最新活動紀錄',
  account_time: '時間',
  account_login_time: '時間',
  account_keep_data: '資料',
  account_updated_time: '修改時間',
  account_register_time: '創建時間',
  account_locked: '帳號已上鎖',
  account_15min_later: '15分鐘後',
  account_30min_later: '30分鐘後',
  account_1hr_later: '1小時後',
  account_edit_title: '編輯帳號',
  account_edit_device_model_hint: '請注意, 變更設備類型時會一併清空既存設備參數及事件接收人清單',
  account_edit_failed: '編輯帳號資料失敗',
  account_edit_success: '已成功編輯',
  account_lock_success: '已成功上鎖',
  account_lock_failed: '上鎖發生錯誤',
  account_unlock: '解除上鎖',
  account_unlock_success: '已成功解除上鎖',
  account_unlock_failed: '解除上鎖發生錯誤',
  account_source_sso: 'SSO（單簽）',
  account_source_self: '自建',
  account_forced_2fa: '雙重驗證',
  account_forced2fa_0: '按需',
  account_forced2fa_1: '強制',
  account_enabled_2fa: '已啟用雙重驗證',

  /* role permission */
  role_no_permission: '無權限',
  role_general: '一般',
  role_advanced: '進階',
  role_medium: '中等',
  role_higher: '高等',
  role_off: '關',
  role_on: '開',
  role_L0: 'L0：無權限。',
  role_new_name: '新角色（1）',
  role_topScope: '可視範圍',
  role_topScope_tooltip: '選一個群組做為可視範圍',
  role_topScop_default: '預設',
  role_topScope_desc1: '提供設置資源存取範圍。',
  role_topScope_desc2: '群組為樹狀架構，設置『可視範圍』可調整存取範圍之最高階群組節點，當前角色可存取該群組與其所有下階群組的所屬資源。',
  role_topScope_desc3: '非公開帳號產生之衍生資源均歸於所屬群組。',
  role_topScope_desc4: '無特別註明時，預設存取範圍=『可視範圍』',
  role_topScope_L0: '預設：帳號所屬群組。',
  role_topScope_L1: '指定：指定群組。',
  role_audit: '使用記錄',
  role_audit_tooltip1: '關：只可查詢自己的紀錄',
  role_audit_tooltip2: '開：可查詢其他人的紀錄',
  role_audit_desc: '提供存取帳號日誌記錄。',
  role_audit_L0: 'L0：可查詢該帳號自身使用記錄。',
  role_audit_L1: 'L1：可存取使用記錄。',
  // 勤務派遣
  role_dashboardPermission: '勤務派遣',
  role_dashboard: '勤務派遣',
  role_dashboard_tooltip: '提供存取勤務派遣功能，用以統合掌控現場事件與狀態',
  role_dashboard_desc: '提供存取勤務派遣功能，用以統合掌控現場事件與狀態。',
  role_dashboard_L0: 'L0：無權限。無法存取勤務派遣功能頁。',
  role_dashboard_L1: 'L1：可存取勤務派遣。',
  role_dashboard_L2: 'L2：可存取公開設備列表。',
  // 影像管理
  role_videoPermission: '影像管理',
  role_liveView: '即時影音',
  role_liveView_tooltip1: '一般：可觀看即時影音',
  role_liveView_tooltip2: '進階：可觀看即時影音、修改標題、控制PTZ等功能',
  role_liveView_desc: '提供存取現場影像、聲音、GPS等資訊。',
  role_liveView_L1: 'L1：可存取即時影音。',
  role_liveView_L2: 'L2：可存取公開即時影音。',
  role_video: '歷史影音',
  role_video_title: '提供事後影像調閱、回放與下載',
  role_video_desc: '提供事後影像調閱、回放與下載。',
  role_video_L1: 'L1：可存取歷史影音。',
  role_video_L2: 'L2：可存取公開歷史影音。',
  role_videoManagement: '影片管理',
  role_videoManagement_tooltip: '提供歷史影像管理功能',
  role_videoManagement_desc: '提供歷史影像管理功能。',
  role_videoManagement_L1: 'L1：可修改歷史影音描述、保留/刪除影片。',
  // 設備操控
  role_deviceCtrlPermission: '設備操控',
  role_deviceCtrl: '設備操控',
  role_deviceCtrl_tooltip: '提供設備設定與操控功能',
  role_deviceCtrl_desc: '提供設備設定與操控功能。',
  role_deviceCtrl_L1: 'L1：可修改關於設備的描述，如影片標題。',
  role_deviceCtrl_L2: 'L2：可調整攝影機相關設定、PTZ控制、品質設置等。',
  role_deviceCtrl_L3: 'L3：可設置設備進階參數。',
  role_deviceAISetting: '辨識任務',
  role_deviceAISetting_title: '提供設備辨識任務設定功能',
  role_deviceAISetting_desc: '提供設備辨識任務設定功能。',
  role_deviceAISetting_L1: 'L1：可存取辨識任務設定。',
  // 語音通話
  role_voiceCallPermission: '語音通話',
  role_voiceCall: '語音對講',
  role_voiceCall_tooltip: '可使用語音通話功能',
  role_voiceCall_desc: '提供存取語音對講服務，包含1對1以及群呼模式。',
  role_voiceCall_L1: 'L1：可存取語音對講，包含1對1以及群組通話。',
  role_voiceCall_L2: 'L2：可存取公開通訊錄(使用者/設備)。',
  // 車辨事件
  role_lprEventPermission: '車辨事件',
  // role_lprDashboard: '儀表板功能',
  role_lprDashboard: '即時車牌事件',
  role_lprDashboard_tooltip1: '一般：儀表板可顯示 比對/任務/圍捕 事件',
  role_lprDashboard_tooltip2: '進階：儀表板可顯示所有事件',
  role_lprDashboard_desc: '提供即時車牌事件存取功能。',
  role_lprDashboard_L1: 'L1：可存取車牌即時比對符合事件（包含車輛資訊、任務、訂閱等）。',
  role_lprDashboard_L2: 'L2：可存取公開車牌即時比對符合事件（包含車輛資訊、任務、訂閱等）。',
  role_lprDashboard_L3: 'L3：可存取全部車牌即時事件。',
  // role_lprEventAccess: '事件查詢',
  role_lprEventAccess: '歷史車牌事件 - 一般',
  role_lprEventAccess_tooltip1: '可查詢單一車號的事件',
  role_lprEventAccess_desc: '提供歷史車牌事件查詢功能。',
  role_lprEventAccess_L1: 'L1：可操作一般查詢（僅公開設備）。',
  role_lprEventAdvancedAccess: '歷史車牌事件 - 進階',
  role_lprEventAdvancedAccess_tooltip1: '可使用進階條件查詢事件',
  role_lprEventAdvancedAccess_desc: '提供歷史車牌事件查詢功能。',
  role_lprEventAdvancedAccess_L1: 'L1：可操作進階查詢（僅私有設備）。',

  // 車號管理
  role_lprPermission: '車號管理',
  // role_lprSummary: '一般查詢',
  role_lprSummary: '車號查詢',
  role_lprSummary_tooltip: '可查詢單一車輛資料',
  role_lprSummary_desc: '提供比對資料庫之車號查詢功能，包含車輛資訊/任務/訂閱等。',
  role_lprSummary_L1: 'L1：可查詢全域性車號資料。',
  role_lprSummary_L2: 'L2：可查詢群組車輛資料。',
  role_lprSubscription: '訂閱管理',
  role_lprSubscription_tooltip: '可 新增/查詢/刪除 訂閱內容',
  role_lprSubscription_desc1: '提供訂閱車號管理功能。',
  role_lprSubscription_desc2: '訂閱為群組資料。',
  role_lprSubscription_desc3: '當前端設備辨識到此訂閱車號時, 不會示警，但系統會立即推播訊息至接收人手機所安裝的通報APP。',
  role_lprSubscription_L1: 'L1：可管理訂閱車號。',
  role_lprMissionLv: '任務管理',
  role_lprMissionLv_tooltip1: '一般：可設定一般任務（協尋）',
  role_lprMissionLv_tooltip2: '中等：可設定中等以下任務（盤查/協尋）',
  role_lprMissionLv_tooltip3: '高等：可設定高等以下任務（逮補/盤查/協尋）',
  role_lprMissionLv_desc1: '提供任務車號管理功能。',
  role_lprMissionLv_desc2: '任務為全域性資料。',
  role_lprMissionLv_desc3: '當前端設備辨識到此任務車號時，會立即示警並顯示請求協助的推送訊息 ， 並同時推播至接收人手機所安裝的通報APP。',
  role_lprMissionLv_L1: 'L1：可管理（協尋）任務。',
  role_lprMissionLv_L2: 'L2：可管理（協尋/盤查）任務。',
  role_lprMissionLv_L3: 'L3：可管理（協尋/盤查/逮捕）任務。',
  role_lprMission: '任務類型',
  role_lprMission_title: '（預留，目前未使用）',
  role_lprMission_desc: '',
  role_lprMission_L1: 'L1：可管理任務類型。',
  // role_lprInfoLv: '車輛資訊管理',
  role_lprInfoLv: '車輛資訊',
  role_lprInfoLv_tooltip: '可管理車輛標記與備註',
  role_lprInfoLv_desc1: '提供車輛資訊管理功能。',
  role_lprInfoLv_desc2: '車輛資訊為全域性資料。',
  role_lprInfoLv_L1: 'L1：可管理全域車輛資訊。',
  // role_lprInfo: '標記列表管理',
  role_lprInfo: '車輛標記',
  role_lprInfo_tooltip: '可管理標記列表',
  role_lprInfo_desc1: '提供車輛標記管理功能。',
  role_lprInfo_desc2: '車輛標記為全域性資料，做為車輛資訊之分類參考。',
  role_lprInfo_desc3: '單一車輛資訊可設置多重車輛標記。',
  role_lprInfo_L1: 'L1：可管理全域車輛標記。',
  // role_lprAnnouncement: '公告管理',
  role_lprAnnouncement: '推送公告',
  role_lprAnnouncement_tooltip: '可管理公告內容',
  role_lprAnnouncement_desc1: '提供推送公告管理功能。',
  role_lprAnnouncement_desc2: '推送公告為全域性資料。',
  role_lprAnnouncement_desc3: '公告訊息推送至前端設備，並以語音播放 1 次 ，輔以文字訊息捲動持續顯示。',
  role_lprAnnouncement_L1: 'L1：可管理全域推送公告。',
  role_lprReport: '績效報表',
  role_lprReport_tooltip: '提供績效報表管理功能',
  role_lprReport_desc1: '提供績效報表管理功能。',
  role_lprReport_desc2: '系統依據設置之匯出設備清單，每月自動產出系統績效報表。',
  role_lprReport_L1: 'L1：可存取績效報表。',
  role_lprReport_L2: 'L2：可管理績效報表匯出設備。',
  // 物件辨識
  // role_orEventPermission: '物件辨識',
  // 人臉辨識
  role_frEventPermission: '人臉辨識',
  role_frDashboard: '即時人物事件',
  role_frDashboard_desc: '提供即時人物事件存取功能。',
  role_frDashboard_L1: 'L1：可存取人物即時比對符合事件。',
  role_frDashboard_L2: 'L2：可存取公開人物即時比對符合事件。',
  role_frDashboard_L3: 'L3：可存取全部人物即時事件。',
  role_frEventAccess: '歷史人物事件',
  role_frEventAccess_desc: '提供歷史人物事件存取功能。',
  role_frEventAccess_L1: 'L1：可存取人物歷史事件。',
  // 人物管理
  role_frPermission: '人物管理',
  role_frInfoLv: '人物資訊',
  role_frInfoLv_desc1: '提供人物資訊管理功能。',
  role_frInfoLv_desc2: '人物資訊為全域性資料。',
  role_frInfoLv_L1: 'L1：可管理全域人物資訊。',
  role_frInfo: '人物標記',
  role_frInfo_desc1: '提供人物標記管理功能。',
  role_frInfo_desc2: '人物標記為全域性資料，做為車輛資訊之分類參考。',
  role_frInfo_desc3: '單一人物資訊可設置多重人物標記。',
  role_frInfo_L1: 'L1：可管理全域人臉標記。',
  // 帳號與權限
  role_accounrPermission: '帳號與權限',
  // role_account: '帳號管理',
  role_account: '使用者',
  role_account_tooltip: '可管理使用者帳號相關設定',
  role_account_desc1: '提供使用者帳號管理功能。',
  role_account_desc2: '使用者帳號需通過驗證，依據配置之角色權限決定各項資源與功能的存取層級。',
  role_account_L1: 'L1：可檢視使用者帳號。',
  role_account_L2: 'L2：可管理使用者帳號。',
  role_device: '設備',
  role_device_tooltip: '可管理設備帳號相關設定',
  role_device_desc1: '提供設備帳號管理功能。',
  role_device_desc2: '設備帳號需通過驗證，依據配置之授權決定可提供之功能與資源保留條件。',
  role_device_L1: 'L1：可檢視設備帳號。',
  role_device_L2: 'L2：可管理設備帳號。',
  role_device_L3: 'L3：可配置設備授權。',
  role_device_L4: 'L4：可新增設備帳號。',
  // role_group: '群組管理',
  role_group: '群組',
  role_group_tooltip: '可管理群組相關設定',
  role_group_desc1: '提供群組管理功能。',
  role_group_desc2: '群組為資源歸屬的基本單位，可配置使用者與設備，而其所衍生資源（如歷史影片、事件、日誌等）預設歸屬於該群組。',
  role_group_desc3: '群組不可刪除。',
  role_group_L1: 'L1：可檢視群組。',
  role_group_L2: 'L2：可管理群組。',
  // role_roleManagement: '角色管理',
  role_roleManagement: '角色',
  role_roleManagement_tooltip: '可管理角色',
  role_roleManagement_desc1: '提供角色管理功能。',
  role_roleManagement_desc2: '『角色(Role)』為權限的集合，定義各項系統資源的存取範圍（Scope）以及功能層級（Level）。',
  role_roleManagement_desc3: '具備 角色管理 權限的操作者，可建立或管理對應存取範圍或權限層級的角色。',
  role_roleManagement_desc4: '角色屬於全域，不可重名。',
  role_roleManagement_L1: 'L1：可檢視角色。',
  role_roleManagement_L2: 'L2：可管理角色。',
  // 系統與服務
  role_systemAndServicePermission: '系統與服務',
  // role_system: '系統管理',
  role_system: '系統設定',
  role_system_tooltip: '可修改系統參數',
  role_system_desc1: '提供系統設定管理功能。',
  role_system_desc2: '用以調整系統偏好以及客製設定。',
  role_system_L1: 'L1：可管理系統設定。',

  /* Role Notify / Message */
  role_notify_add_success: `已成功新增角色`,
  role_notify_add_info: `新增角色：<span style="${notify_style}">{name}</span>`,
  role_notify_add_fail: `無法新增角色`,
  role_notify_edit_success: '已編輯角色',
  // role_notify_edit_info: '成功編輯角色',
  role_notify_edit_info: `成功編輯角色 <span style="${notify_style}">{name}</span>`,
  role_notify_delete_confirm: '確定刪除 <span>{name}</span> ？',
  role_notify_delete_success_title: '已成功刪除角色',
  role_notify_delete_success_msg: `刪除角色：<span style="${notify_style}">{name}</span>`,
  role_message_error_name_title: '無法修改名稱',
  role_message_error_name_content: '此名稱已被使用，請輸入其他名稱。',
  role_message_error_delete_title: '無法刪除角色',
  role_message_error_delete_prefix: '請先轉移',
  role_message_error_delete_suffix: '底下的所有帳號至其他角色',
  role_message_error_delete_transfer: '請先轉移 <span>{name}</span> 底下的所有使用者至其他角色。',

  /* 音效提示 */
  alarm_hint: '提示',
  alarm_announcement: '公告',
  alarm_info: '允許瀏覽器播放音效?',

  /* Dashboard: user tree */
  tree_search: '搜尋群組 / 設備',
  tree_online: '上線 / 關注設備',
  tree_online_all: '上線 / 全部',
  tree_select_group: '只勾選此群組',
  tree_change_name: '修改名稱',
  tree_change_video_title: '修改影片標題',
  tree_change_group_name: '修改群組名稱',
  tree_push_talk: 'PTT群組通話',
  tree_select_account: '只勾選此設備',
  tree_voice_call: '一對一通話',
  tree_view_device: '檢視設備',
  tree_view_user: '檢視使用者',
  tree_view_account: '檢視帳號',
  tree_processing_info: '處理中...',
  tree_no_data: '無資料',
  tree_contacts: '聯絡人',

  /* Dashboard: video download */
  video_start_time: '開始時間',
  video_end_time: '結束時間',
  video_length: '影片長度',
  video_size: '影片大小',
  video_date_placeholder: '請選擇起迄時間',
  video_select_all: '選取全部',
  video_unselect: '取消保留',
  video_keep_all: '全部保留',
  video_keep_select: '保留勾選',
  video_download_select: '下載勾選',
  video_delete_select: '刪除勾選',
  video_download: '下載影片',
  video_keep: '保留',
  video_none_delete: '無影片可刪除！',
  video_delete: '影片已刪除！',
  video_none_select: '無勾選資料！',
  video_set_keep: '影片已設定保留！',
  video_no_keep: '影片已取消保留！',
  video_delete_data: '刪除資料',
  video_delete_checked: '確定要刪除已勾選資料嗎？',
  video_selected: '已勾選',
  video_total_of: '共',
  video_of_records: '筆資料',

  /* Video Control Button */
  video_ctrlbtn_ptz: '遠端鏡頭控制',
  video_ctrlbtn_time_axis: '歷史影音',
  video_search_none: '查無歷史影片！',

  /* Dashboard: event cards */
  event_tab_all: '全部',
  event_tab_match: '比對',
  event_tab_urgent: '緊急',
  event_tab_video: '影片',
  event_show_position: '顯示帳號位置',
  evnet_show_history: '顯示相關歷史紀錄',
  event_source: '來源',
  event_result: '比對結果',
  event_notes: '備註',
  event_emergency: '緊急事件',
  event_switch: '顯示所有事件',
  event_not_db: '非資料庫事件',
  event_chasing: '圍捕',
  event_sos: 'SOS',

  /* PTZ */
  ptz_move_speed: '移動速度',
  ptz_zoom: '變焦',
  ptz_focus: '對焦',
  ptz_preset: '點位清單',
  ptz_preset_placeholder: '新點位',
  ptz_preset_setting_success: '點位設定成功！',
  ptz_preset_setting_fail: '點位設定失敗！',
  ptz_vertical_mirror: '垂直鏡像',
  ptz_horizontal_mirror: '水平鏡像',
  ptz_infrared: '紅外線',
  ptz_high_sensitivity: '高感度',
  ptz_tooltip_zoom_out: '縮小',
  ptz_tooltip_zoom_in: '放大',
  ptz_tooltip_open: '開啟',
  ptz_tooltip_close: '關閉',
  ptz_tooltip_auto: '自動',
  ptz_tooltip_auto_focus: '自動對焦',
  ptz_tooltip_near_focus: '近焦',
  ptz_tooltip_far_focus: '遠焦',

  /* History: Search Bar */
  search_tab_history: '一般查詢',
  search_tab_advanced_search: '進階查詢',
  search_cause: '偵辦案由 (必填)',
  search_cause_placehold: '偵辦李小明毒品案',
  search_plate_number_required: '車號 (必填)',
  search_time_interval: '時間區間',
  search_select_date: '選擇日期',
  search_select_time: '選擇時間',
  search_time_placehold: '請選擇起迄時間',
  search_hint: '提示',
  search_hint_reason: '請輸入案由',
  search_hint_reason_length: '案由需小於等於30個字',
  search_hint_fill_number: '請填入「車號」',
  search_hint_fill_device: '請選擇「設備」',
  search_hint_fill_time: '請選擇「時間區間」',
  search_hint_nodata: '查無資料',
  search_export: '匯出結果',
  search_device: '設備',
  search_device_required: '設備（必填）',
  search_device_placehold: '請選擇',
  search_device_unit: '單位',
  search_plate_number: '車號',
  search_car_type: '車種',
  search_car: '汽車',
  search_motocycle: '機車',
  search_event_category: '事件類別',
  search_please_choose: '請選擇',
  search_all: '全部',
  search_subscription: '訂閱',
  search_mission: '任務',
  search_mark: '標記',
  search_round_up: '圍捕',
  search_not_specified: '未指定',
  search_misjudgment: '誤判',
  search_non_misjudgment: '非誤判',
  search_cofind: '協尋',
  search_check: '盤查',
  search_arrest: '逮捕',
  search_vehicle_marking: '車輛標記',
  search_finished_status: '完成狀態',
  search_finish: '完成',
  search_undone: '未完成',
  search_total_of: '共',
  search_of_events: '筆',
  search_timeout_message: '內容過多，請縮小查詢範圍',
  search_clear: '清除',
  search_adv_filter: '進階條件',
  search_channel_id: '鏡頭',

  /* History: History Events */
  history_event: '歷史事件',
  history_event_lpr: '車牌',
  history_event_fr: '人物',
  history_event_frid: '身份識別',
  history_event_previous: '上一筆',
  history_event_next: '下一筆',
  history_event_wheelzoom: '滾輪縮放',
  history_event_previous_tooltip: '上一筆（左鍵）',
  history_event_next_tooltip: '下一筆（右鍵）',
  history_event_back_tooltip: '關閉（Esc）',
  history_event_map_goto: '開啟Google map',
  history_query_by_event: '以事件查詢',
  history_device: '辨識設備',
  history_tag: '車輛標記',
  history_mission: '任務',
  history_push_message: '推送訊息',
  history_channel: '鏡頭',
  history_event_prev_page: '上一頁',
  history_event_next_page: '下一頁',
  history_event_no: '第',
  history_event_pages: '頁',
  history_event_screen_capture: '擷取畫面',

  /* History: 人物 */
  history_fr_name: '姓名',
  history_fr_id: '身份識別碼',
  history_fr_age: '年齡',
  history_fr_tag: '人物標記',
  history_fr_remark: '備註',
  history_fr_group: '群組',
  history_fr_device: '設備',
  history_fr_gps: 'GPS位置',
  history_fr_open_google_map: '開啟Google map',
  history_fr_event_category: '事件類別',
  history_fr_cv: '信心值',

  /* History: 緊急 */
  history_device_group: '設備群組',
  history_link_user: '勾稽使用者',
  history_user_group: '使用者群組',

  /* QR Code Panel */
  qr_title: '下載手機通報APP',

  /* Dashboard: tooltips */
  previous_video: '前一段影片',
  next_video: '後一段影片',
  play: '播放',
  pause: '暫停',
  mute: '靜音',
  unmute: '解除靜音',
  play_rate: '播放倍率',
  zoom_in: '放大畫面',
  zoom_out: '縮小畫面',
  zoom_ratio: '縮放倍率',
  show_only_events: '只顯示此設備的事件',
  download_video: '影片下載',
  full_screen: '全螢幕',
  jump_to_live: '跳至直播畫面',
  the_day_before: '前一天',
  the_day_after: '後一天',
  a_minute_ago: '前一分鐘',
  one_minute_after: '後一分鐘',
  zoom_out_the_play: '縮小播放視窗',
  enlarge_the_play: '放大播放視窗',
  mode_1x1: '1x1 模式',
  mode_2x2: '2x2 模式',
  mode_3x3: '3x3 模式',
  mode_4x4: '4x4 模式',
  reorder_device_list: '重新排序',
  collapse_the_play: '收合播放視窗',
  expand_the_play: '展開播放視窗',
  expand_event_list: '展開事件列表',
  collapse_event_list: '收合事件列表',
  collapse_device_list: '收合設備列表',
  expand_device_list: '展開設備列表',
  more: '更多',
  automatic_logout: '防止閒置自動登出',
  no_selected_user: '未選擇設備',
  no_streaming: 'No Streaming Available',
  no_permission_all: '無觀看即時影音與歷史影音權限',
  no_permission_live_view: '無觀看即時影音權限',
  no_permission_video: '無觀看歷史影音權限',
  no_live_stream: '無可播放的即時影音',
  no_video: '無可播放的歷史影音',
  video_pause: '停留在暫停畫面',
  mode_nxn_switch_hint: '請拖放有設備的視窗進行互換！',

  /* Account Management */
  am_title: '帳號與權限',
  am_user: '使用者',
  am_device: '設備',
  am_group: '群組',
  am_role: '角色',
  am_role_label: '角色',
  am_search_account: '搜尋帳號/帳號名稱',
  am_edit_not_complete: '編輯未完成',
  am_cancel_edit: '確定取消此編輯嗎？',
  am_map_search_hint: '搜尋路名或GPS',
  am_map_reset: '重置點位',
  am_user_create: '新增使用者',
  am_user_create_confirm: '使用者新增後將無法刪除，您確定要新增嗎？',

  /* Account Management-device */
  am_search_device: '搜尋設備/設備名稱',
  am_device_name: '名稱',
  am_device_video_name: '影片名稱',
  am_device_event_keep: '事件保留',
  am_device_video_keep: '影片保留',
  am_group_tree_search: '搜尋群組',
  am_device_keep_time: '保留時間',
  am_device_keep_time_tip: '系統預設設定的保留時間。',
  am_device_keep_time_edit: '編輯保留時間',
  am_device_create: '新增設備',
  am_device_create_confirm: '設備新增後將無法刪除，您確定要新增嗎？',
  am_device_type_default: '一般',
  am_device_type_bwc: 'Bodycam',
  am_device_type_patrol: 'Patrol',
  am_device_type_phone: 'Phone',
  am_device_type_ptz: 'PTZ 球機',
  am_device_type_box: 'PTZ 箱機',

  /* Account Management-group */
  am_search_group: '搜尋群組',
  am_group_title: '群組',
  am_view_quota_title: '收看配額',
  am_live_quota_title: '發布配額',
  am_quota_desc: '已使用 / 已分配 / 全部',
  am_quota_edit: '編輯配額',
  am_quota_editing: '編輯中',
  am_add_group: '新增群組',
  am_parent_group_empty: '所屬群組名稱',
  am_parent_group: '所屬群組',
  am_parent_group_hint: '所屬的群組',
  am_group_name: '群組名稱',
  am_group_name_hint: '新群組（1）',
  am_group_id: 'ID',
  am_group_updated_time: '修改時間',
  am_group_create_time: '創建時間',
  am_add_group_fail: '新增群組發生錯誤',
  am_add_group_pass: '已成功新增群組',
  am_edit_group_fail: '修改群組發生錯誤',
  am_edit_group_pass: '已成功修改群組',
  am_quota_short_hint1: '群組',
  am_quota_short_hint2: '配額不足',
  am_group_create_confirm: '群組新增後將無法刪除，您確定要新增嗎？',

  /* Account Management-role */
  am_role_helper_title: '角色管理',
  am_role_helper_intro: '「角色（Role）」為權限的集合，目的為定義各項系統資源的存取範圍以及功能層級。角色可被套用在使用者帳號上，藉此規範此帳號可以操作的權限層級。',
  am_role_helper_desc1: '高階層級包含低階層級的存取範圍以及功能項目。',
  am_role_helper_desc1_sub1: '存取範圍（Scope）：層級由低至高，範圍愈來愈大。',
  am_role_helper_desc1_sub2: '功能層級（Level）：層級由低至高，權限愈來愈多。',
  am_role_helper_desc2: '角色屬於全域，不可重名。',

  /* System Setting */
  system_global_setting: '全域設定',
  system_custom_web: '客製網頁',
  system_backend_setting: '後台設定',
  system_server_capability: '伺服器能力',
  system_aibox: '辨識盒管理',
  system_custom_system_name: '系統名稱',
  system_custom_idle_logout_time: '閒置登出時間',
  system_custom_logout_not: '不登出',
  system_custom_app_download: '顯示APP下載連結',
  system_custom_manual_download: '顯示使用手冊載點',
  system_custom_customize: '客戶',
  system_custom_favicon: '頁籤上小圖 favicon 32*32',
  system_custom_banner: '左側欄展開 banner 130*56',
  system_custom_logo: '左側欄收合小圖 56*56',
  system_custom_upload: '上傳圖片',
  system_custom_restore: '還原預設',
  system_custom_restore_info: '將以上圖示設定還原為預設值',

  /* System Setting - AiBoxMgr */
  setting_aibox_top_box: '辨識盒',
  setting_aibox_top_ip: 'IP',
  setting_aibox_top_count: '辨識盒數量',
  setting_aibox_top_abnormal_count: '異常數',
  setting_aibox_page_start: '第',
  setting_aibox_page_end: '筆',
  setting_aibox_page_total: '搜尋結果',
  setting_aibox_total_of: '資料庫共',
  setting_aibox_of_records: '筆資料',
  setting_aibox_goto_page: '跳頁',
  setting_aibox_state_normal: '正常',
  setting_aibox_state_abnormal: '異常',
  setting_aibox_table_name: '辨識盒',
  setting_aibox_table_ip: 'IP',
  setting_aibox_table_udid: 'UDID',
  setting_aibox_table_status: '任務',
  setting_aibox_table_temp: '溫度',
  setting_aibox_table_cpu: 'CPU',
  setting_aibox_table_gpu: 'GPU',
  setting_aibox_table_mem: 'MEM',
  setting_aibox_table_hd: 'HDD',
  setting_aibox_table_recognition_src: '資源',
  setting_aibox_table_updated_time: '更新時間',
  setting_aibox_delete_title: '刪除資料',
  setting_aibox_task_device: '設備',
  setting_aibox_task_recognition_type: '辨識類別',
  setting_aibox_task_recognition_state: '辨識狀態',
  setting_aibox_task_status_0: '辨識中',
  setting_aibox_task_status_10: '設備未發布',
  setting_aibox_task_status_11: '串流連接中',
  setting_aibox_task_status_12: '授權數不足',
  setting_aibox_task_status_13: '系統性錯誤',
  setting_aibox_task_status_14: '設定檔版本不支援',
  setting_aibox_task_status_15: '辨識功能不支援',
  setting_aibox_task_recognition_or: '物件',
  setting_aibox_task_recognition_lpr: '車輛',
  setting_aibox_delete_aibox: '確定刪除此辨識盒嗎?',
  setting_aibox_delete_notify_title: '刪除辨識盒',
  setting_aibox_delete_pass: '已成功刪除辨識盒',
  setting_aibox_delete_fail: '刪除辨識盒失敗',
  setting_aibox_delete_aibox_task: '確定刪除此設備的辨識任務嗎?',
  setting_aibox_task_delete_notify_title: '刪除辨識任務',
  setting_aibox_task_delete_pass: '已成功刪除辨識任務',
  setting_aibox_task_delete_fail: '刪除辨識任務失敗',

  /* AI Box */
  ai_recognition_setting: '辨識設定',
  ai_local_recognition: '本機辨識',
  ai_person_recognition :'人物辨識',
  ai_could_recognition: '雲端辨識',
  ai_object_recognition: '物件辨識',
  ai_object: '物件',
  ai_license_plate_recognition: '車牌辨識',
  ai_update_time: '更新時間',
  ai_box: '辨識盒',
  ai_task_add: '新增中',
  ai_task_edit: '編輯中',
  ai_task_delete: '刪除中',
  ai_status_0: '辨識中',
  ai_status_10: '設備未發布',
  ai_status_11: '串流連接中',
  ai_status_12: '授權數不足',
  ai_status_13: '系統性錯誤',
  ai_status_14: '設定檔版本不支援',
  ai_status_15: '辨識功能不支援',
  ai_try_connect: '嘗試連接串流',
  ai_select_aibox: '請選擇辨識盒',
  ai_temperature: '溫度',
  ai_memory: 'MEM',
  ai_hard_disk: 'HDD',
  ai_resources: '辨識資源',
  ai_other_resources: '其他辨識盒',
  ai_add_notification_task: '新增通報任務',
  ai_image_preview: '影像預覽',
  ai_set_time: '設定時間',
  ai_recg_fps_level: '辨識頻率',
  ai_repeat_interval: '重複事件間隔',
  ai_stay_sec: '停留秒數',
  ai_category: '類別',
  ai_setter: '設定人',
  ai_notice: '備註說明',
  ai_receiver: '接收人',
  ai_unit_name_placeholder: '單位 或 姓名',
  ai_show_checked: '顯示已勾選',
  ai_edit_receiver: '編輯接收人',
  ai_mode: '模式',
  ai_object_detect: '物件偵測',
  ai_motion_detect: '移動偵測',
  ai_detect_target_threshold: '偵測目標閾值',
  ai_motion_level: '偵測靈敏度',
  ai_advanced_settings: '進階設定',
  ai_person_level: '人',
  ai_car_level: '汽車',
  ai_bike_level: '二輪車',
  ai_truck_level: '卡車',
  ai_bus_level: '公車',
  ai_warning_area: '警戒區',
  ai_delete_data: '刪除資料',
  ai_sure_to_delete: '確定刪除此辨識嗎？',
  ai_device: '設備',
  ai_recognition_category: '辨識類別',
  ai_recognition_status: '辨識狀態',
  ai_level_0: '',
  ai_level_1: '最低',
  ai_level_2: '低',
  ai_level_3: '中',
  ai_level_4: '高',
  ai_level_5: '最高',
  ai_motion_level_1: '低',
  ai_motion_level_2: '中',
  ai_motion_level_3: '高',
  ai_insufficient_recognition_resources: '辨識資源不足',
  ai_no_boxes_available: '很抱歉，無可使用的辨識盒。',
  ai_edit_not_complete: '編輯未完成',
  ai_cancel_edit: '確定取消此編輯嗎？',
  ai_area1: '區域 1',
  ai_area2: '區域 2',
  ai_area3: '區域 3',
  ai_new_recognition: '新增辨識任務',
  ai_recog_task_add_successfully: '辨識任務新增成功',
  ai_roi_set: '調整辨識範圍',
  ai_roi_lock: '鎖定辨識範圍',
  ai_tooltip_roi_reset: '恢復至預設範圍',
  ai_tooltip_or: '物件偵測：偵測到所勾選的物件後發出通報',
  ai_tooltip_md: '移動偵測：偵測到畫面中有移動物體後發出通報',
  ai_tooltip_threshold: '閾值越低，可以找出更多在畫面上的物件，但同時也會有降低精確度的可能性',
  ai_tooltip_motion: '靈敏度越高，對畫面中移動變化會越敏感',
  ai_tooltip_recg_fps_level: '每秒的辨識頻率，建議當物體移動的速度越快、辨識頻率設定越高',
  ai_tooltip_repeat_interval: '當事件重複發生時，間隔此設定時間後才會再次發出通報並上傳事件',
  ai_tooltip_stay_sec: '物件停留在警戒區多久後通報',
  ai_tooltip_warning_area: '最多為8個點的多邊形，物件觸碰到警戒區就會發出通報',

  /* AI Box - recognition setting */
  ai_tag: '人物標記',
  ai_remark: '備註說明',
  ai_editor: '設定人',
  ai_expert_mode: '專家模式',
  ai_full_face_mode: '全臉模式',
  ai_mask_mode: '口罩模式',
  ai_param_source: '參數來源',
  ai_global_config: '系統預設',
  ai_user_config: '設備客製',
  ai_param_source_tip: '<strong style="color: #FFE99F;">系統預設</strong>: 系統預設的辨識參數值, 不可修改。<br/><strong style="color: #FFE99F;">設備客製</strong>: 依據設備使用需求, 可自定義辨識參數值',
  ai_enable: '啟用',
  ai_enable_tip: '可 啟用/停用 人臉辨識功能',
  ai_model_country: '適用國家',
  ai_model_country_tip: '選擇欲支援車牌辨識的國家',
  ai_face_size_ratio: '臉部比例',
  ai_face_size_ratio_tip: '偵測的最小人臉大小佔畫面的百分比',
  ai_detect_mode: '比對模式',
  ai_precise_detect: '精準比對',
  ai_quick_detect: '快速比對',
  ai_detect_mode_tip: '<strong style="color: #FFE99F;">精準比對</strong>: 可輸出準確度較高的結果; <br/><strong style="color: #FFE99F;">快速比對</strong>: 可快速輸出辨識結果',
  ai_trigger_score: '觸發分數',
  ai_candidate_score: '候選分數',
  ai_full_face_trigger_score_tip: '全臉模式下相似度的閾值. 閾值越低, 會有較多的比對結果, 但同時也會有降低精確度的可能性',
  ai_full_face_candidate_score_tip: '全臉模式下候選人物的閾值. 閾值越低, 會有較多候選人物可參考',
  ai_mask_trigger_score_tip: '口罩模式下相似度的閾值. 閾值越低, 會有較多的比對結果, 但同時也會有降低精確度的可能性',
  ai_mask_candidate_score_tip: '口罩模式下候選人物的閾值. 閾值越低, 會有較多候選人物可參考',
  ai_detect_threshold_level: '噪音車輛閾值',
  ai_detect_threshold_level_tip: '人臉偵測的閾值. 閾值越低, 可以找出更多在畫面上的人臉, 但同時也會有降低精確度的可能性',
  ai_event_post_mode: '事件上傳模式',
  ai_event_post_mode_tip: '<strong style="color: #FFE99F;">比對符合</strong>: 比對符合的事件才會被上傳; <br/><strong style="color: #FFE99F;">所有辨識</strong>: 所有的辨識結果都會被上傳',
  ai_event_match: '比對符合',
  ai_event_all: '所有辨識',
  ai_notify_filter_mode: '事件通報模式',
  ai_notify_filter_mode_tip: '<strong style="color: #FFE99F;">比對符合</strong>: 比對符合的事件才會被通報; <br/><strong style="color: #FFE99F;">全部</strong>: 所有的事件都會被通報; <br/><strong style="color: #FFE99F;">比對不符合</strong>: 比對不符合的事件才會被通報; <br/><strong style="color: #FFE99F;">不通報</strong>: 所有事件都不會被通報',
  ai_notify_match: '比對符合',
  ai_notify_all: '全部',
  ai_notify_not_match: '比對不符合',
  ai_notify_none: '不通報',
  ai_event_duration: '重複事件間隔',
  ai_noise_event_duration: '噪音事件重複間隔',
  ai_event_duration_tip: '當事件重複發生時, 間隔此設定時間後才會再次發出上傳事件',
  ai_noise_event_duration_tip: '當噪音車輛事件重複發生時, 間隔此設定時間後才會再次發出上傳事件',
  ai_repost_data_store_limit: '補傳事件保留上限',
  ai_repost_data_store_limit_tip: '無法上傳事件時, 設備保留的事件上限',
  ai_lpr_frequency: '辨識頻率',
  ai_lpr_frequency_tip: '每一秒辨識的幀數',
  ai_lpr_size_ratio: '最小車牌比例',
  ai_lpr_size_ratio_tip: '偵測的最小車牌大小佔畫面的百分比',
  ai_lpr_detect_mode: '偵測模式',
  ai_noise_car_decibel_threshold_tip: '分貝值達閾值時, 發出噪音車事件',
  ai_noise_car_event_duration_tip: '當分貝值持續達閾值時，NoiseCar 事件多久發送一次',
  ai_decibel_offset: '校正值',
  ai_decibel_offset_tip: '分貝數值補償',
  ai_confirm_count: '確認計數',
  ai_confirm_count_tip: '數值越大, 可能會較不容易輸出結果, 但輸出的結果準確性會較高',
  ai_accept_diff_num: '可接受數字差',
  ai_accept_diff_num_tip: '對辨識結果進行修正的數字數',
  ai_indent: '辨識邊界',
  ai_indent_tip: '偵測到的車牌若超出此邊界, 則不輸出辨識結果',
  ai_is_complete_mode: '完整模式',
  ai_is_complete_mode_tip: '若開啟, 只有完整的辨識結果才會被輸出. 反之, 只要有辨識結果就會被輸出',
  ai_roi: '調整辨識範圍',
  ai_roi_tip: '可拉動邊框, 調整辨識範圍',
  ai_event_unit: '筆',
  ai_low: '低',
  ai_medium: '中',
  ai_high: '高',
  ai_setting_success: '設定成功',
  ai_setting_fail: '設定失敗',

  /* AI Box - LPR */
  ai_lpr_tag: '車輛標記',

  /* 人辨資料庫 */
  fr_title: '人物管理',
  fr_info: '人物資訊',
  fr_filter_name: '姓名',
  fr_filter_name_hint: '王小明',
  fr_filter_tag: '人物標記',
  fr_filter_time: '時間區間',
  fr_filter_id: '身份識別碼',
  fr_filter_id_hint: 'Z123456789',
  fr_filter_feature: '特徵值',
  fr_filter_enabled: '人物資訊',
  fr_filter_tooltip: '進階條件',
  frInfo_idCard_filter: '身份識別碼',
  frInfo_feature_filter:'特徵值',
  frInfo_feature_filter_all: '全部',
  frInfo_feature_filter_0:'無啟用',
  frInfo_feature_filter_1:'任一啟用',
  frInfo_feature_filter_2:'任一停用',
  frInfo_enabled_filter:'人物資訊',
  frInfo_enabled_filter_all: '全部',
  frInfo_enabled_filter_0:'停用',
  frInfo_enabled_filter_1:'啟用',
  fr_new_fr_tooltip: '新增人物資訊',
  fr_db_info_tooltip: '資料庫資訊',
  fr_tag_mgr_tooltip: '人物標記管理',

  fr_tag_mgr_title: '人物標記管理',
  fr_tag_add: '新增人物標記',
  fr_tag_add_placeholder: '新增標記',
  fr_tag_duplicate_hint: '此標記名稱已存在，請重新輸入。',
  fr_tag_total: '共{total}筆標記',
  fr_tag_total_count: '共{count}/{total}筆標記',
  fr_tag_add_hint: '新增後無法刪除標記，確定新增？',
  fr_tag_add_pass: '新增人物標記成功',
  fr_tag_add_fail: '新增人物標記失敗',

  fr_db_info_title: '資料庫資訊',
  fr_db_info_data_cnt: '{count}筆',
  fr_db_info_data_latest_sn: '最新序號：',
  fr_db_info_data_update_time: '更新時間：',
  fr_db_info_db: '資料庫',
  fr_db_info_data_fr_cnt: '人物筆數：',
  fr_db_info_feature: '特徵值',
  fr_db_info_data_used: '已使用{percent}%',
  fr_db_info_data_reg_cnt: '註冊筆數：',
  fr_db_info_data_quota: '授權配額：',
  fr_db_info_db_package: '資料庫封裝',
  fr_db_info_data_version: '版本：',

  fr_table_photo: '註冊圖',
  fr_table_name: '姓名',
  fr_table_id: '身份識別碼',
  fr_table_tag: '人物標記',
  fr_table_update_time: '更新時間',
  fr_table_create_time: '建立時間',
  fr_table_empty_hint: '請輸入查尋條件',
  fr_table_delete_pass: '刪除成功',
  fr_table_delete_fail: '刪除失敗',

  fr_info_data_title: '人物資訊',
  fr_info_data_enable: '啟用',
  fr_info_data_disable: '停用',
  fr_info_data_name: '姓名',
  fr_info_data_gender: '性別',
  fr_info_data_id: '身份識別碼',
  fr_info_data_age: '年齡',
  fr_info_data_age_edit: '生日',
  fr_info_data_tag: '人物標記',
  fr_info_data_note: '備註',
  fr_more: '較多',
  fr_less: '較少',
  fr_info_data_phone: '電話',
  fr_info_data_birth_place: '出生地',
  fr_info_data_city: '城市',
  fr_info_data_address: '地址',
  fr_info_data_job: '職業',
  fr_info_photo_title: '註冊圖',
  fr_info_photo_add: '新增註冊圖',
  fr_info_other_title: '資料來源',
  fr_info_other_src: '來源',
  fr_info_other_src_0: '自建',
  fr_info_other_src_1: '匯入',
  fr_info_other_update_time: '更新時間',
  fr_info_other_create_time: '建立時間',
  fr_info_data_required: '必填',
  fr_info_data_phone_hint: '僅允許 數字、加號、減號、括弧',
  fr_info_data_gender_0: '其他',
  fr_info_data_gender_1: '男',
  fr_info_data_gender_2: '女',
  fr_info_data_gender_9: '未知',
  fr_feature_1: '特徵值無效',
  fr_feature_2: '特徵值不可用',
  fr_feature_3: '特徵值較差',
  fr_feature_4: '特徵值可用',
  fr_feature_5: '特徵值最佳',
  fr_mask_0: '無口罩',
  fr_mask_1: '有口罩',
  fr_action_set_avatar: '設定代表照', // || 設定頭像
  fr_action_download_photo: '下載註冊圖',
  fr_action_delete_feature: '刪除特徵值',
  fr_no_birthday: "(年/月/日)",
}

// console.log(`tw:`, Object.keys(tw).length)

export default tw
