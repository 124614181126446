import i18n from '../i18n/i18n'
import moment from 'moment'
import store from '@/store'

export function diffSecs(utcTime) {
  if (!utcTime) return 0
  let timeStamp = new Date(utcTime).getTime()
  let now = new Date().getTime()
  return (now - timeStamp) / 1000  // return seconds
}

export function timeAgo(utcTime, timeSinceLastUpdated) {
  if (!utcTime) return ''
  let timeStamp = new Date(utcTime).getTime()
  let now = new Date().getTime()
  let diff = !timeSinceLastUpdated
    ? now - timeStamp
    : timeSinceLastUpdated * 1000
  let minute = 1000 * 60 // ms
  let hour = minute * 60
  let day = hour * 24
  let month = day * 30

  let secC = diff / 1000
  let minC = diff / minute
  let hourC = diff / hour
  let dayC = diff / day
  let monthC = diff / month
  
  let result = ''
  if (secC < 60) {
    result = '' + parseInt(secC) + i18n.t('sec_ago') 
  } else if (minC < 60) {
    result = '' + parseInt(minC) + i18n.t('min_ago')
  } else if (hourC < 24) {
    result = '' + parseInt(hourC) + i18n.t('hour_ago')
  } else if (dayC < 30) {
    result = '' + parseInt(dayC) + i18n.t('day_ago')
  } else {
    result = '' + parseInt(monthC) + i18n.t('month_ago')
  }

  return result
}

// 函式getDefaultDateRange: 取得預設的日期範圍
// 輸入參數 nDays: 日期範圍的天數
// 輸出參數: [startDate, now]
export function getDefaultDateRange(nDays) {
  let now = new Date()
  let startDate = new Date()
  startDate.setDate(now.getDate() - nDays)
  return [startDate, now]
}

// Helper function to generate formatted datetime string
export function genFormattedDatetime(offset, unit, startOf='day') {
  return moment()
    .startOf(startOf)
    .add(offset, unit)
    .tz(store.getters.timezone)
}

export function genKeepDayOptions() {
  // 保留時間
  const keepOptions = []
  // keepOptions.push({ value: 0, label: `365 ${i18n.t('day')}` })
  for (let i = 0; i <= 365; i++)
    keepOptions.push({ value: i, label: `${i} ${ (i > 1) ? i18n.t('day') : i18n.t('one_day')}` })

  return keepOptions;
}

export function genMinutesOptions(oneStr, multiStr) {
  const minOptions = []

  for (let i = 1; i <= 60; i++) {
    minOptions.push({
      value: `${i}`,
      // label: `${i} ${ (i > 1) ? i18n.t('min') : i18n.t('one_min') }`})
      label: `${i} ${i > 1 ? oneStr : multiStr}`
    })
  }

  return minOptions
}

// Account ID
export const accSpecialChars = '@-_.'
export const accLen = {
  min: 3,
  max: 64
}
export function validateAccountLen(accountId) {
  // 長度需3~64個字元
  if (accountId.length > 0 && accountId.length < accLen.min) {
    return false
  } else if (accountId.length > accLen.max) {
    return false
  }

  return true
}
export function validateAccountIDChar(accountId) {
  if (accountId.length <= 0) {
    return true
  }

  // 包含英數, 大小寫, 特殊字元 (@-_.)
  let ret = true
  const digital = '1234567890'
  const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const lowerCase = upperCase.toLocaleLowerCase()

  const chars = digital + upperCase + lowerCase + accSpecialChars
  for (const char of accountId) {
    if (!chars.includes(char)) {
      ret = false
      break
    }
  }

  return ret
}

// 密碼
export const pswSpecialChars = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'
export const pswLen = {
  min: 8,
  max: 16
}
export function validatePwsLen(password) {
  // 長度需8~16個字元
  if (password.length > 0 && password.length < pswLen.min) {
    return false
  } else if (password.length > pswLen.max) {
    return false
  }
  return true
}
export function validatePswChar(password) {
  if (password.length <= 0) {
    return true
  }

  // 包含英數, 大小寫 => 檢查中文
  let ret = true
  const digital = '1234567890'
  const upperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const lowerCase = upperCase.toLocaleLowerCase()
  const chars = digital + upperCase + lowerCase + pswSpecialChars

  for (const char of password) {
    if (!chars.includes(char)) {
      ret = false
      break
    }
  }

  return ret
}
export function validatePswSpecialChar(password) {
  if (password.length <= 0) {
    return true
  }

  // 任一特殊字元 (~@_/+:)
  let ret = false
  const chars = pswSpecialChars

  for (const char of password) {
    if (chars.includes(char)) {
      ret = true
      break
    }
  }

  return ret
}

/******************************************************************************
 *
 * @param {Array} treeList: tree list data
 * @param {Number} gId: group id
 * @returns {Object}
 */
export const getNode = (treeList, gId) => {
  for (const node of treeList) {
    if (node.id === String(gId)) {
      return node
    }

    if (node.children.length > 0) {
      const newNode = getNode(node.children, gId)
      if (newNode) {
        return newNode
      }
    }
  }
}

/******************************************************************************
 *
 * @param {Object} tree: tree object data
 * @returns {Array}
 */
export const treeToList = (tree) => {
  const result = []

  if (tree) {
    result.push(tree)
    const children = tree.children || []
    children.forEach((child) => {
      const childResult = treeToList(child)
      result.push(...childResult)
    })
  }

  return result
}

const getParent = (parentTreeList, gId) => {
  for (const node of parentTreeList) {
    if (node.children.map((kid) => kid.id).includes(gId)) {
      const { id, name } = node
      return { id, name }
    }

    if (node.children.length > 0) {
      const newNode = getParent(node.children, gId)
      if (newNode) {
        return newNode
      }
    }
  }
}

export const getAncestorList = (treeList, gId) => {
  const ancestor = []

  let parent = getParent(treeList, `${gId}`)
  while (parent) {
    ancestor.push(parent)
    parent = getParent(treeList, parent.id)
  }
  // Add self group
  const selfGrp = getNode(treeList, gId)
  if (selfGrp) {
    ancestor.unshift({ id: selfGrp.id, name: selfGrp.name })
  }

  // 最後一個最上層
  return ancestor
}

export const getNodeFamily = (treeList, gId) => {
  const self = getNode(treeList, gId)
  const descendants = treeToList(self)

  return descendants
}
export const getNodeKids = (treeList, gId) => {
  const self = getNode(treeList, gId)
  const descendants = treeToList(self)
  descendants.splice(0, 1) // Delete self

  return descendants
}
export const filterTreeByName = (tree, name) => {
  // 遞迴遍歷樹結構
  function traverse(node) {
    if (node.name.toLowerCase().indexOf(name.toLowerCase()) >= 0) {
      // 若節點名稱符合，回傳該節點及其子節點
      return node
    }
    if (node.children && node.children.length > 0) {
      // 遞迴處理子節點
      const filteredChildren = node.children.map(child => traverse(child)).filter(Boolean)
      // 建立新的節點物件，只保留過濾後的子節點
      if (filteredChildren.length > 0) {
        return { ...node, children: filteredChildren }
      }
    }
    // 若節點名稱不符合且無子節點，則回傳 null
    return null
  }

  // 建立過濾後的樹結構
  const filteredTree = tree.map(node => traverse(node)).filter(Boolean)
  return filteredTree
}
export const sumNum = (nums) => {
  let sum = 0;

  for (const val of nums) {
    sum += val
  }

  return sum
}
/******************************************************************************
 *
 * @param {Boolean} isDev: is development mode or noe
 * @returns {console}
 * @example `console.set(true)` to open all console.log. Please remember to marke it before release.
 */
export const console = {
  isDev: false, // false: 關掉所有 console.log
  set(isDev) {
    this.isDev = isDev
  },
  get() {
    return this.isDev
  },
  log(...args) {
    if (!this.isDev) return
    window.console.log(`[Debug]`, ...args)
  },
  error(...args) {
    // if (!this.isDev) return
    window.console.error(...args)
  },
  warn(...args) {
    if (!this.isDev) return
    window.console.warn(...args)
  },
  time(...args) {
    if (!this.isDev) return
    window.console.time(...args)
  },
  timeLog(...args) {
    if (!this.isDev) return
    window.console.timeLog(...args)
  },
  timeEnd(...args) {
    if (!this.isDev) return
    window.console.timeEnd(...args)
  }
}

/******************************************************************************
 *
 * @param {Number} ms: micro seconds
 * @returns {Promise}
 */
export const sleep = (ms) => {
  return new Promise((resolve /*, reject*/) => {
    if (!ms || ms < 0) {
      ms = 0
    }
    setTimeout(resolve(), ms)
  })
}

export const formatTime = (time) => {
  if (!time) return ''
  return moment(time).tz(store.getters.timezone).format('YYYY-MM-DD HH:mm:ss')
}

/******************************************************************************
 *
 * @param {Date} time
 * @returns {String} `YYYY-MM-DD hh:mm:ss` format time string
 */
export const formatTimeStr = (time, needSS = false) => {
  const now = time ? new Date(time) : new Date()

  const YYYY = now.getFullYear()
  const MM = `00${now.getMonth() + 1}`.slice(-2)
  const DD = `00${now.getDate()}`.slice(-2)
  const hh = `00${now.getHours()}`.slice(-2)
  const mm = `00${now.getMinutes()}`.slice(-2)
  const ss = `00${now.getSeconds()}`.slice(-2)
  // const ms = `000${now.getMilliseconds()}`.slice(-3)

  if (needSS) {
    return `${YYYY}-${MM}-${DD} ${hh}:${mm}:${ss}`
  }
  return `${YYYY}-${MM}-${DD} ${hh}:${mm}`
}

export const enum2Options = (enumObj) => {
  return Object.keys(enumObj).map((key) => {
    return {
      label: key,
      value: enumObj[key]
    }
  })
}


/***************************************************************************
 * 這個 function 會改變 treeObj 的結構
 * 1. 先將 treeObj 的 children 依照 group.name 排序
 * 2. 再將 children 的 children 依照 group.name 排序
 * 3. 以此類推
 * @param {*} treeObj 
 */
export const sortTreeGroup = (treeObj) => {
  if (treeObj.children && treeObj.children.length > 0) {
    treeObj.children.sort((a, b) => {
      return a.group.name.localeCompare(b.group.name)
    })

    treeObj.children.forEach((child) => {
      sortTreeGroup(child)
    })
  }
}

/******************************************************************************
 * 
 * @param {array} userList 
 * @param {object} groupTree 
 * @param {string} labelKey videoTitle / infoName
 * @param {string} filterKey 
 * @returns {array} filtered user tree list 
 */
export function getFilterUserTreeList(userList, groupTree, labelKey = 'videoTitle', filterKey = '') {
  const users = userList.map(user => {
    let showName = labelKey === 'videoTitle' ? user.video.title : user.info.name
    return {
      id: user.id,
      index: String(user.index),
      name: showName,
      groupId: user.groupId,
      label: showName + ' (' + user.id + ')'
    }
  })

  const treeData = []

  let arrUsers = getUserListByGroupId(
    users,
    groupTree.group.id,
    groupTree.group.name,
    filterKey
  )

  let arrChildren = getChildrenData(
    groupTree.children,
    users,
    groupTree.group.id,
    filterKey
  )

  let tmp = {
    id: groupTree.group.id, 
    groupId: groupTree.group.id, // tree的資料須有一樣的結構，故將id設給groupId
    label: groupTree.group.name, // 群組只顯示群組名稱
    children: arrUsers.concat(arrChildren),
    parent: 0
  }
  treeData.push(tmp)
  return treeData
}

/******************************************************************************
 * 取得過濾後該節點的 user list
 * @param {*} userList 
 * @param {*} NodeGroupId 
 * @param {*} NodeGroupName 
 * @param {*} filterKey 
 * @returns 
 */
function getUserListByGroupId(userList, NodeGroupId, NodeGroupName, filterKey) {
  let fItem = filterKey.toLowerCase() // 轉換為小寫
  // 先檢查NodeGroupName是否含有fItem，若有則返回此節點的所有user;若無則進行search的比對
  let bGroupMatch
  if (NodeGroupName) bGroupMatch = NodeGroupName.toLowerCase().includes(fItem)
  let arrUsers = userList.filter((user) => {
    if (filterKey && !bGroupMatch) {
      return (
        user.groupId == NodeGroupId &&
        (user.label.toLowerCase().includes(fItem) ||
          user.id.toLowerCase().includes(fItem))
      )
    } else {
      return (
        user.groupId == NodeGroupId 
      )
    }
  })

  return arrUsers
}

/******************************************************************************
 * 取得過濾後該節點的 children list
 * @param {*} data 
 * @param {*} userList 
 * @param {*} groupId 
 * @param {*} filterKey 
 * @returns 
 */
function getChildrenData(data, userList, groupId, filterKey) {
  let arrChildren = []
  if (data) {
    data.forEach((item) => {
      let arrUsers = getUserListByGroupId(userList, item.group.id, item.group.name, filterKey)

      let arrNode = item.children
        ? getChildrenData(item.children, userList, item.group.id, filterKey)
        : []

      let fItem = filterKey.toLowerCase() // 轉換為小寫
      let bInclude =
        fItem != '' &&
        (item.group.id.toLowerCase().includes(fItem) ||
          item.group.name.toLowerCase().includes(fItem))

      if (fItem !== '') {
        // 若有filterKey，則只顯示符合條件的節點(設備)
        if (arrUsers.length == 0 && arrNode.length == 0) {
          return []
        }
      }
      if (arrUsers.length > 0 || arrNode.length > 0 || bInclude) {
        arrChildren.push({
          id: item.group.id,
          groupId: item.group.id,
          label: item.group.name, // 群組只顯示群組名稱
          children: arrUsers.concat(arrNode),
          parent: groupId
        })
      }
    })
  }
  return arrChildren
}

// 秒數格式化
export function formatSeconds(totalSeconds) {
  let hours = Math.floor(totalSeconds / 3600)
  totalSeconds %= 3600
  let minutes = Math.floor(totalSeconds / 60)
  let seconds = totalSeconds % 60
  minutes = String(minutes).padStart(2, "0")
  hours = String(hours).padStart(2, "0")
  seconds = String(seconds).padStart(2, "0")
  return hours + ":" + minutes + ":" + seconds
}

/****************************************************************************
 * convert-size-in-bytes-to-kb-mb-gb
 * @param {*} bytes 
 * @param {*} decimals 
 * @returns 
 */
export const formatBytes = (bytes, decimals = 2) => {
  if(!+bytes) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${ parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) } ${sizes[i]}`
}


export function getScore(score) {
  // 四捨五入取整數
  return Math.round(score)
}

export function getAge(birthday) {
  if (!birthday) return ''
  // 計算年齡
  const today = new Date()
  const birthDate = new Date(birthday)
  let age = today.getFullYear() - birthDate.getFullYear()
  const m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age + 'Y'
}

// 字串比對：模糊比對
export function FuzzyStrComp(str1, str2) {
  if (!str1 || !str2) return true

  const s1 = str1.toLocaleLowerCase()
  const s2 = str2.toLocaleLowerCase()

  return s1.includes(s2) || s2.includes(s1)
}

export function generateUUID() {
  // let d = new Date().getTime()
  let uuid = window.crypto.randomUUID()
  /*'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    function (c) {
      let r = (d + Math.random() * 16) % 16 | 0
      d = Math.floor(d / 16)
      return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
    }
  )*/
  return uuid
}

export function genRandom() {
  // Note:應應弱掃 Insecure Randomness 風險, 用 window.crypto.getRandomValues() 取代 Math.random() 取得隨機數值
  // JavaScript的Math.random()函数生成伪随机数，通常用于非加密目的。
  // 如果你需要更安全的随机性，特别是用于加密或安全应用程序，你应该使用crypto.getRandomValues()函数，它提供了更安全的随机数生成方法。

  // 创建一个Uint8Array来存储随机数
  const randomBytes = new Uint8Array(4) // 这里的4表示你需要生成4个字节的随机数，可以根据需要调整长度

  // 使用crypto.getRandomValues()生成安全的随机数
  window.crypto.getRandomValues(randomBytes)

  // 将随机字节转换为数字（0到255之间）
  const randomNumber = randomBytes[0]

  return randomNumber
}

export function getEventFilename(event) {
  // 車牌事件: <車號>_<事件時間>_<設備帳號(設備帳號)>.png
  // 人臉事件: <人物名稱>_<事件時間>_<設備帳號(設備帳號)>.png
  // SOS事件:SOS_<事件時間>_<設備帳號(設備帳號)>.png
  const [type] = event.uid.split('-') // type: lpr, fr, urg, sos
  if (type === 'sos') {
    const gpsTimestamp = moment(event.gps.timestamp).tz(store.getters.timezone).format('YYYY-MM-DD HH-mm-ss')
    const device = store.getters.getEventTitleId(event.userAccount)
    return `SOS_${gpsTimestamp}_${device}.png`
  } else {
    const time = moment(event.detectTime).tz(store.getters.timezone).format('YYYY-MM-DD HH-mm-ss')
    const deviceAccount = store.getters.getEventTitleId(event.user.id)

    if (type === 'lpr' || type === 'urg') {
      return `${event.triggered[0].content}_${time}_${deviceAccount}.png`
    } else if (type === 'fr') {
      const name = event.triggered.length > 0 ? event.triggered[0].name : i18n.t('unknown')
      return `${name}_${time}_${deviceAccount}.png`
    }
    
    return `${time}_${deviceAccount}.png`
  }
}
