import i18n from "../i18n/i18n"

export const frConfig = {
  paramSource: {
    title: i18n.t('ai_param_source')/*參數來源*/,
    default: 1,
    options: [
      { label: i18n.t('ai_global_config')/*系統預設*/, value: 1 },
      { label: i18n.t('ai_user_config')/*設備客製*/, value: 0 },
    ],
    tooltip: i18n.t('ai_param_source_tip'),
  },
  isEnabled: {
    title: i18n.t('ai_enable')/*啟用*/,
    default: 1,
    options: [
      { label: i18n.t('on')/*開啟*/, value: 1, icon: 'checked-green.svg' },
      { label: i18n.t('off')/*關閉*/, value: 0, icon: 'Error.svg' },
    ],
    tooltip: i18n.t('ai_enable_tip'),
  },
  faceSizeRatio: {
    title: i18n.t('ai_face_size_ratio')/*'臉部比例*/,
    default: 10,
    min: 5,
    max: 50,
    unit: '%',
    tooltip: i18n.t('ai_face_size_ratio_tip'),
  },
  detectMode: {
    title: i18n.t('ai_detect_mode')/*比對模式*/,
    default: 1,
    options: [
      { label: i18n.t('ai_precise_detect')/*精準比對*/, value: 1 },
      { label: i18n.t('ai_quick_detect')/*快速比對*/, value: 0 },
    ],
    tooltip: i18n.t('ai_detect_mode_tip')/*比對模式的說明*/,
  },
  eventScore: {
    title: i18n.t('ai_trigger_score')/*觸發分數*/,
    default: 85,
    min: 70,
    max: 95,
    unit: '',
    tooltip: i18n.t('ai_full_face_trigger_score_tip'),
  },
  candidateScore: {
    title: i18n.t('ai_candidate_score')/*候選分數*/,
    default: 80,
    min: 70,
    max: 95,
    unit: '',
    tooltip: i18n.t('ai_full_face_candidate_score_tip'),
  },
  eventScoreWithMask: {
    title: i18n.t('ai_trigger_score')/*觸發分數*/,
    default: 77,
    min: 70,
    max: 95,
    unit: '',
    tooltip: i18n.t('ai_mask_trigger_score_tip'),
  },
  candidateScoreWithMask: {
    title: i18n.t('ai_candidate_score')/*候選分數*/,
    default: 75,
    min: 70,
    max: 95,
    unit: '',
    tooltip: i18n.t('ai_mask_candidate_score_tip'),
  },
  detectThresholdLevel: {
    title: i18n.t('ai_detect_threshold_level')/*偵測閥值*/,
    default: 2,
    min: 0,
    max: 2,
    label: {
      0: i18n.t('ai_low')/*低*/,
      1: i18n.t('ai_medium')/*中*/,
      2: i18n.t('ai_high')/*高*/,
    },  
    unit: '',
    tooltip: i18n.t('ai_detect_threshold_level_tip'),
  },
  eventPostMode: {
    title: i18n.t('ai_event_post_mode')/*事件上傳模式*/,
    default: 0,
    options: [
      { label: i18n.t('ai_event_match')/*比對符合*/, value: 0 },
      { label: i18n.t('ai_event_all')/*所有辨識*/, value: 1 },
    ],
    tooltip: i18n.t('ai_event_post_mode_tip'),
  },
  notifyFilterMode: {
    title: i18n.t('ai_notify_filter_mode')/*事件通報模式*/,
    default: 2,
    options: [
      { label: i18n.t('ai_notify_match')/*比對符合*/, value: 2 },
      { label: i18n.t('ai_notify_all')/*全部*/, value: 3 },
      { label: i18n.t('ai_notify_not_match')/*比對不符合*/, value: 1 },
      { label: i18n.t('ai_notify_none')/*不通報*/, value: 0 },
    ],
    tooltip: i18n.t('ai_notify_filter_mode_tip'),
  },
  eventDuration: {
    title: i18n.t('ai_event_duration')/*重複事件間隔*/,
    default: 5,
    min: 1,
    max: 600,
    unit: i18n.t('sec')/*秒*/,
    tooltip: i18n.t('ai_event_duration_tip'),
  },
  repostDataStoreLimit: {
    title: i18n.t('ai_repost_data_store_limit')/*補傳事件筆數*/,
    default: 1000,
    min: 50,
    max: 1000,
    unit: i18n.t('ai_event_unit')/*筆*/,
    tooltip: i18n.t('ai_repost_data_store_limit_tip'),
  },  
}

export const setFrDefaultValue = (setting) => {
  const camKeys = ['isEnabled', 'faceSizeRatio', 'detectMode', 'eventScore', 'candidateScore', 'eventScoreWithMask', 'candidateScoreWithMask', 'detectThresholdLevel']
  camKeys.forEach(key => {
    if (setting.channel[0].fr[key] === null || setting.channel[0].fr[key] === undefined) 
      setting.channel[0].fr[key] = frConfig[key].default
  })
  const frParams = [ 'eventPostMode', 'eventDuration', 'repostDataStoreLimit' ]
  frParams.forEach(key => {
    if (setting.deviceConfig.fr[key] === null || setting.deviceConfig.fr[key] === undefined) 
      setting.deviceConfig.fr[key] = frConfig[key].default
  })
}