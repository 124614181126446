import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index.js'
import { PermissionLevel } from '@/config/permissions'

Vue.use(VueRouter)

// ref: https://juejin.cn/post/7048568562104926244
const originalReplace = VueRouter.prototype.replace
const originalPush = VueRouter.prototype.push
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch((err) => err)
}
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '/',
    name: 'Entry',
    component: () => import('@/views/Home.vue'),
    redirect: 'dashboard',
    children: [
      {
        path: 'dashboard/:seconds?',
        name: 'Dashboard',
        component: () => import('@/views/Dashboard.vue'),
      },
      {
        path: 'history',
        name: 'History',
        component: () => import('@/views/History.vue'),
      },
      {
        path: 'lpr_recognition', // 車辨: lpr
        name: 'LPR',
        component: () => import('@/views/LprRecognition.vue'),
      },
      {
        path: 'fr_recognition', // 人辨: fr
        name: 'FR',
        component: null // () => import('@/views/FrRecognition.vue'),
      },
      // {
      //   path: 'or_recognition', // 物辨: or
      //   name: 'OR',
      //   component: () => import('@/views/OrRecognition.vue'),
      // },
      {
        path: 'account-management',
        name: 'AccountManagement',
        component: () => import('@/views/AccountManagement.vue'),
      },
      {
        path: 'system-setting',
        name: 'SystemSetting',
        component: () => import('@/views/SystemSetting.vue')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/npa_login',
    name: 'NpaLogin',
    component: () => import('@/views/NpaLogin.vue')
  },
  {
    path: '*',
    redirect: '/login'
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes // 需要有最完整的路徑, 再用 beforeEach 做轉發
})

export const dashboardPermission = (permissionV2) => {
  const { dashboard } = permissionV2
  return dashboard > PermissionLevel.L0
}

export const historyPermission = (permissionV2) => {
  const {
    // emergencyEventAccess,
    // voiceCallEventAccess,
    lprEventAccess,
    lprEventAdvancedAccess,
    frEventAccess,
    // orEventAccess,
  } = permissionV2
  const ret =
    // emergencyEventAccess > PermissionLevel.L0 ||
    // voiceCallEventAccess > PermissionLevel.L0 ||
    lprEventAccess > PermissionLevel.L0 ||
    lprEventAdvancedAccess > PermissionLevel.L0 ||
    frEventAccess > PermissionLevel.L0 // ||
  // orEventAccess > PermissionLevel.L0 ||

  return ret
}

export const lprPermission = (permissionV2) => {
  const {
    lprSummary,
    lprSubscription,
    lprMissionLv,
    lprMission,
    lprInfoLv,
    lprInfo,
    lprAnnouncement,
    lprReport,
    // lprGroupInfoLv,
    // lprGroupInfo
  } = permissionV2
  const lpr =
    lprSummary > PermissionLevel.L0 ||
    lprSubscription > PermissionLevel.L0 ||
    lprMissionLv > PermissionLevel.L0 ||
    lprMission > PermissionLevel.L0 ||
    lprInfoLv > PermissionLevel.L0 ||
    lprInfo > PermissionLevel.L0 ||
    lprAnnouncement > PermissionLevel.L0 ||
    lprReport > PermissionLevel.L0 // ||
  // lprGroupInfoLv ||
  // lprGroupInfo
  return lpr
}
export const frPermission = (permissionV2) => {
  const { frInfoLv, frInfo } = permissionV2
  const fr = frInfoLv > PermissionLevel.L0 || frInfo > PermissionLevel.L0
  return fr
}
// export const orPermission = (permissionV2) => {
//   const { orInfoLv, orInfo } = permissionV2
//   const or = orInfoLv > PermissionLevel.L0 || orInfo > PermissionLevel.L0
//   return or
// }
export const recognitionPermission = (permissionV2) => {
  const lpr = lprPermission(permissionV2)
  const fr = frPermission(permissionV2)
  // const or = orPermission(permissionV2)

  const obj = { lpr, fr /*, or*/ }
  const def = Object.keys(obj).filter((key) => obj[key])[0]
  const ret = lpr || fr //|| or

  return { ret, lpr, fr /* , or*,*/, def }
}

export const accountPermission = (permissionV2) => {
  const { account, device, group, roleManagement } = permissionV2
  const ret =
    account > PermissionLevel.L0 ||
    device > PermissionLevel.L0 ||
    group > PermissionLevel.L0 ||
    roleManagement > PermissionLevel.L0
  return ret
}

export const systemPermission = (permissionV2) => {
  const {
    system,
    systemAibox,
    systemServer,
    systemDevice,
    systemLicense,
    systemLog
  } = permissionV2
  const ret =
    system > PermissionLevel.L0 ||
    systemAibox > PermissionLevel.L0 ||
    systemServer > PermissionLevel.L0 ||
    systemDevice > PermissionLevel.L0 ||
    systemLicense > PermissionLevel.L0 ||
    systemLog > PermissionLevel.L0

  return ret
}

router.beforeEach((to, from, next) => {
  const isLogin = localStorage.getItem('refreshToken')
  const ssoLoginPage = routes.find(
    (router) => router.path === '/npa_login'
  ).path

  if (to.path === ssoLoginPage) {
    store.commit('updateSsoLogin', true)
  }

  let defPage = store.state.ssoLogin ? ssoLoginPage : '/login'
  // console.log(`[Router] to.path, defPage:`, to.path, defPage)

  if (isLogin && to.path !== defPage) {
    // lprDashboard > 0，才有權限進入dashboard頁面
    // lprEventAccess > 0，才有權限進入history頁面; 1: 一般查詢, 2: 進階查詢
    // lprSummary > 0，才有權限進入lpr_recognition頁面

    const dashboard = dashboardPermission(store.state.permissionV2)
    const history = historyPermission(store.state.permissionV2)
    const recognition = recognitionPermission(store.state.permissionV2)
    const account = accountPermission(store.state.permissionV2)
    const system = systemPermission(store.state.permissionV2)

    // console.log(`[Router] dashboard, history, recognition, account, system:`, dashboard, history, recognition, account, system)
    if (to.path.includes('/dashboard') && dashboard) next()
    else if (to.path === '/history' && history) next()
    else if (to.path.includes('_recognition') && recognition.ret) {
      if (to.path === '/lpr_recognition' && recognition.lpr) next()
      else if (to.path === '/fr_recognition' && recognition.fr) next()
      // else if (to.path === '/or_recognition' && recognition.or) next()
      else next(`${recognition.def}_recognition`)
    } else if (to.path === '/account-management' && account) next()
    else if (to.path === '/system-setting' && system) next()
    else {
      // 手打URL，或是沒有權限的頁面
      if (dashboard) next('/dashboard')
      else if (history) next('/history')
      else if (recognition.ret) {
        if (recognition.lpr) next('/lpr_recognition')
        else if (recognition.fr) next('/fr_recognition')
        // else if (recognition.or) next('/or_recognition')
        else next(defPage)
      } else if (account) next('/account-management')
      else if (system) next('/system-setting')
      else next(defPage)
    }
  } else {
    if (to.path !== defPage) next(defPage)
    else next()
  }
})

export default router
