import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import store from '@/store/index.js'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import * as VueGoogleMaps from 'vue2-google-maps'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import PortalVue from 'portal-vue'
import './assets/fonts/font.css'
import VCalendar from 'v-calendar'
import i18n from './i18n/i18n.js'
import clearTabIndex from '@/utils/clearTabIndex.js'
import { getMapUrl } from '@/config/map.js'
import VueLazyload from 'vue-lazyload'

//import { apiLogout } from '@/api/index.js'

Vue.use(PortalVue)
Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(ElementUI)
Vue.prototype.$bus = new Vue();

Vue.prototype.$notify = ElementUI.Notification

Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

Vue.use(VueGoogleMaps, {
  load: {
    key: getMapUrl(),
    libraries: 'places' // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

ElementUI.Tooltip.props.openDelay.default = 300 // Element ui tooltip 延遲0.3秒顯示
ElementUI.Tooltip.props.transition = ''

clearTabIndex.install(Vue)

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('@/assets/icons/no-image.svg'),
  attempt: 1,
  observer: true,
  lazyComponent: true,
})


//const app = 
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
/*
window.App = app;

const timeoutInMS = 1800000 // 30 minutes -> 30 * 60 * 1000
let timeoutId
  
function handleInactive() {
    // Here you want to logout a user and/or ping your token
    try {
      apiLogout()
      window.App.$router.push('/login')
      window.App.$store.commit('setAccessToken', '')
      localStorage.setItem('refreshToken', '')
      window.App.$store.commit('resetState')
    } catch (err) {
      console.log(err)
    }
}

function startTimer() { 
    // setTimeout returns an ID (can be used to start or clear a timer)
    timeoutId = setTimeout(handleInactive, timeoutInMS)
}

function resetTimer() { 
    clearTimeout(timeoutId)
    startTimer()
}
 
function setupTimers () {
    document.addEventListener("keypress", resetTimer, false)
    document.addEventListener("mousemove", resetTimer, false)
    document.addEventListener("mousedown", resetTimer, false)
    document.addEventListener("touchmove", resetTimer, false)
     
    startTimer()
}

setupTimers()
*/