import { 
  apiSearchVideo,
  apiGetVideoGPS,
} from '@/api/index.js'
import { handleError } from '@/utils/handleError.js'

const initialState = () => ({
  queryParams: {},
  searchLoading: false,
  videoTotal: 0, // 搜尋的總video數
  historyVideoList: [], // 僅存放該次搜尋的video, ex:20筆
  videoCurrentPage: 1, // 目前分頁所在頁數，從1開始
  pageSize: 20, // 每個分頁video數量
  videoIndex: 0, // video在目前分頁的index, 從0開始
  videoFocusIndex: -1, // 點擊事件卡片的index，初始值設為-1, 為了watch其變為０時也能更新focus
  hlsVideoList: [], // 暫存 HLS video list
  videoGpsMap: {}, // 紀錄 video GPS 位置資料
})

const state = initialState()

const getters = {
  modalVideo(state) {
    return state.historyVideoList[state.videoIndex]
  },
  totalVideoNo(state) {
    // 目前影片在所有影片的編號，從１開始
    return (state.videoCurrentPage - 1) * state.pageSize + state.videoIndex + 1
  }
}

const mutations = {
  resetState(state) {
    const initial = initialState()
    Object.keys(initial).forEach(key => { state[key] = initial[key] })
  },
  updateQueryParams(state, payload) {
    state.queryParams = payload
  },
  updateSearchLoading(state, value) {
    state.searchLoading = value
  },
  updateVideoTotal(state, value) {
    state.videoTotal = value
  },
  updateHistoryVideoList(state, list) {
    state.historyVideoList = list
  },
  updateVideoCurrentPage(state, value) {
    state.videoCurrentPage = value
  },
  updateVideoIndex(state, value) {
    state.videoIndex = value
  },
  updateVideoFocusIndex(state, value) {
    state.videoFocusIndex = value
  },
  updateHlsVideoList(state, list) {
    state.hlsVideoList = list
  },
  updateVideoGpsMap(state, payload) {
    state.videoGpsMap = payload
  }

}

const actions = {
  async searchVideoList({ commit, state, dispatch }, payload) {
    commit('updateSearchLoading', true)
    commit('updateHistoryVideoList', [])
    commit('updateHlsVideoList', [])
    commit('updateVideoTotal', 0)
    commit('updateVideoIndex', 0)
    commit('updateVideoFocusIndex', -1)
    commit('updateVideoCurrentPage', payload.page)

    try {
      const params = { ...state.queryParams }
      params.count = state.pageSize
      params.index = (payload.page - 1) * state.pageSize
      const res = await apiSearchVideo(params)
      const mp4Video = res.data.videoList.filter((video) => video.type === 'MP4')
      const notMp4Video = res.data.videoList.filter((video) => video.type !== 'MP4')

      if (notMp4Video.length > 0) {
        commit('updateHlsVideoList', notMp4Video)
      }
      commit('updateHistoryVideoList', mp4Video)
      commit('updateVideoTotal', notMp4Video.length ? res.data.total - state.hlsVideoList.length : res.data.total)
      dispatch('getVideoGpsMap')
    } catch (error) {
      handleError(error)
      commit('updateHistoryVideoList', [])
      commit('updateVideoTotal', 0)
    } finally {
      commit('updateSearchLoading', false)
    }
  },
  async getVideoGpsMap({ commit, state }) {
    try {
      const videoGpsMap = {}
      for (const video of state.historyVideoList) {
        const res = await apiGetVideoGPS(video.id)
        videoGpsMap[video.id] = res.data.gpsList || []
      }
      commit('updateVideoGpsMap', videoGpsMap)
    } catch (error) {
      handleError(error)
      commit('updateVideoGpsMap', {})
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}