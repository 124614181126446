import i18n from "../i18n/i18n"

export const configLpr = {
  paramSource: {
    title: i18n.t('ai_param_source')/*參數來源*/,
    default: 1,
    options: [
      { label: i18n.t('ai_global_config')/*系統預設*/, value: 1 },
      { label: i18n.t('ai_user_config')/*設備客製*/, value: 0 },
    ],
    tooltip: i18n.t('ai_param_source_tip'),
  },
  aiModelType : {
    title: i18n.t('ai_model_country')/*適用國家*/,
    default: 'tw',
    options: [
      { label: 'TW', value: 'tw' },
      { label: 'JP', value: 'jp' },
    ],
    tooltip: i18n.t('ai_model_country_tip')
  },
  eventPostMode: {
    title: i18n.t('ai_event_post_mode')/*事件上傳模式*/,
    default: { 
      car: { tw: 0, jp: 0 },
      moto: { tw: 0, jp: 0 },
    },
    options: [
      { label: i18n.t('ai_event_match')/*比對符合*/, value: 0 },
      { label: i18n.t('ai_event_all')/*所有辨識*/, value: 1 },
    ],
    tooltip: i18n.t('ai_event_post_mode_tip'),
  },
  notifyFilterMode: {
    title: i18n.t('ai_notify_filter_mode')/*事件通報模式*/,
    default: { 
      tw: 2,
      jp: 2,
    },
    options: [
      { label: i18n.t('ai_notify_match')/*比對符合*/, value: 2 },
      { label: i18n.t('ai_notify_all')/*全部*/, value: 3 },
      { label: i18n.t('ai_notify_not_match')/*比對不符合*/, value: 1 },
      { label: i18n.t('ai_notify_none')/*不通報*/, value: 0 },
    ],
    tooltip: i18n.t('ai_notify_filter_mode_tip'),
  },
  eventDuration: {
    title: i18n.t('ai_event_duration')/*重複事件間隔*/,
    default: { 
      car: { tw: 210, jp: 210 },
      moto: { tw: 210, jp: 210 },
    },
    min: 1,
    max: 600,
    unit: i18n.t('sec')/*秒*/,
    tooltip: i18n.t('ai_event_duration_tip'),
  },
  repostDataStoreLimit: {
    title: i18n.t('ai_repost_data_store_limit')/*補傳事件保留上限*/,
    default: { 
      car: { tw: 1000, jp: 1000 },
      moto: { tw: 1000, jp: 1000 },
    },
    min: 100,
    max: 100000,
    unit: i18n.t('ai_event_unit')/*筆*/,
    tooltip: i18n.t('ai_repost_data_store_limit_tip'),
  },
  /* 分貝計 */
  noiseCarDecibelThreshold: {
    title: i18n.t('ai_detect_threshold_level')/*偵測閥值*/,
    default: {
      tw: 80,
      jp: 80,
    },
    min: 0,
    max: 200,
    unit: 'db',
    tooltip: i18n.t('ai_noise_car_decibel_threshold_tip'),
  },
  noiseCarEventDuration: {
    title: i18n.t('ai_noise_event_duration')/*重複事件間隔*/,
    default: {
      tw: 10,
      jp: 10,
    },
    min: 5,
    max: 60,
    unit: i18n.t('sec')/*秒*/,
    tooltip: i18n.t('ai_noise_event_duration_tip'),
  },
  decibelOffset: {
    title: i18n.t('ai_decibel_offset')/*校正值*/,
    default: {
      tw: 0,
      jp: 0,
    },
    min: -15,
    max: 15,
    unit: 'db',
    tooltip: i18n.t('ai_decibel_offset_tip'),
  },

  lprFrequency: {
    title: i18n.t('ai_lpr_frequency')/*辨識頻率*/,
    default: {
      car: { tw: [ 10, 20 ], jp: [ 5, 20 ] },
      moto: { tw: [ 30 ], jp: [ 30 ] }, 
    },
    min: 5,
    max: 30,
    unit: 'fps',
    tooltip: i18n.t('ai_lpr_frequency_tip'),
  }, 
  isEnabled: {
    title: i18n.t('ai_enable')/*啟用*/,
    default: [ 1, 1 ],
    options: [
      { label: i18n.t('on')/*開啟*/, value: 1, icon: 'checked-green.svg' },
      { label: i18n.t('off')/*關閉*/, value: 0, icon: 'Error.svg' },
    ],
    tooltip: i18n.t('ai_enable_tip'),
  },
  lprSizeRatio: {
    title: i18n.t('ai_lpr_size_ratio')/*'最小車牌比例*/,
    default: [ 5, 5 ],
    min: 1,
    max: 50,
    unit: '%',
    tooltip: i18n.t('ai_face_size_ratio_tip'),
  },
  detectMode: {
    title: i18n.t('ai_lpr_detect_mode')/*偵測模式*/,
    default: [ 1, 1 ],
    options: [
      { label: i18n.t('ai_precise_detect')/*精準比對*/, value: 1 },
      { label: i18n.t('ai_quick_detect')/*快速比對*/, value: 0 },
    ],
    tooltip: i18n.t('ai_detect_mode_tip')/*偵測模式的說明*/,
  },
  confirmCount: {
    title: i18n.t('ai_confirm_count')/*確認計數*/,
    default: [ 1, 1 ],
    min: 1,
    max: 5,
    tooltip: i18n.t('ai_confirm_count_tip')/*確認計數的說明*/,
  },
  acceptDiffNum: {
    title: i18n.t('ai_accept_diff_num')/*可接受數字差*/,
    default: [ 1, 1 ],
    min: 0,
    max: 3,
    tooltip: i18n.t('ai_accept_diff_num_tip')/*可接受數字差的說明*/,
  },
  indent: {
    title: i18n.t('ai_indent')/*辨識邊界*/,
    default: [ 0.01, 0.01 ],
    min: 0.01,
    max: 0.1,
    step: 0.01,
    tooltip: i18n.t('ai_indent_tip')/*辨識邊界的說明*/,
  },
  isCompleteMode: {
    title: i18n.t('ai_is_complete_mode')/*完整模式*/,
    default: [ 0, 0 ],
    options: [
      { label: i18n.t('on')/*開啟*/, value: 1 },
      { label: i18n.t('off')/*關閉*/, value: 0 },
    ],
    tooltip: i18n.t('ai_is_complete_mode_tip')/*完整模式的說明*/,
  }  
}

/**
 * 設定預設值：若沒有設定，則使用預設值
 * @param {*} setting 
 */
export const setLprDefaultValue = (setting) => {
  // type => 2100: Patrol-Car(iOS), 2200: Patrol-Moto(iOS)
  // type => 131: AiCam-BFV
  let type = ''
  const channelCount = setting.channel.length
  if (setting.type === 2100) type = 'car'
  else if (setting.type === 2200) type = 'moto'
  else if (setting.type === 131) {
    if (channelCount === 2) type = 'car'
    else if (channelCount === 1) type = 'moto'
  }

  if (!setting.aiModelType) setting.aiModelType = configLpr.aiModelType.default
  
  const checkDeviceTags = ['eventDuration', 'eventPostMode', 'repostDataStoreLimit']
  const checkAicamTags = ['noiseCarDecibelThreshold', 'noiseCarEventDuration', 'decibelOffset']
  const modelList = Object.keys(setting.deviceConfig)
  modelList.forEach((model) => {
    if (model === 'lprTw' || model === 'lprJp') {
      const modelTyp = model.slice(3).toLowerCase() // tw, jp
      const deviceConfig = setting.deviceConfig[model]
      checkDeviceTags.forEach((tag) => {
        if (deviceConfig[tag] === null || deviceConfig[tag] === undefined) 
          deviceConfig[tag] = configLpr[tag].default[type][modelTyp]
      })

      // 若是AiCam, 需檢查分貝計資料
      if (setting.type === 131) {
        checkAicamTags.forEach((tag) => {
          if (deviceConfig[tag] === null || deviceConfig[tag] === undefined) 
            deviceConfig[tag] = configLpr[tag].default[modelTyp]
        })
      }
    }
  })

  // 若是AiCam, 需檢查fr.repostDataStoreLimit
  if (setting.type === 131) {
    const dc = setting.deviceConfig
    if (!dc.fr || dc.fr.repostDataStoreLimit === null || dc.fr.repostDataStoreLimit === undefined)
      dc.fr.repostDataStoreLimit = configLpr.repostDataStoreLimit.default[type].tw
  }  

  setting.channel.forEach((channel, index) => {
    const modelList = Object.keys(channel)
    const checkTags = ['isEnabled', 'lprFrequency', 'lprSizeRatio', 'detectMode', 'confirmCount', 'acceptDiffNum', 'indent', 'isCompleteMode']
    // AiCam-BFV 沒有辨識頻率
    if (setting.type === 131) {
      const index = checkTags.indexOf('lprFrequency')
      checkTags.splice(index, 1)
    }
    modelList.forEach((model) => {
      if (model === 'lprTw' || model === 'lprJp') {
        const modelTyp = model.slice(3).toLowerCase() // tw, jp
        const channelConfig = channel[model]
        checkTags.forEach((tag) => {
          if (channelConfig[tag] === null || channelConfig[tag] === undefined) {
            if (tag === 'lprFrequency') {
              channelConfig[tag] = configLpr[tag].default[type][modelTyp][index]
            } else {
              channelConfig[tag] = configLpr[tag].default[index]
            }
          }
        })
      }
      
    })
  })
}