export const DefaultTopScope = 0
export const PermissionLevel = {
  L0: 0,
  L1: 1,
  L2: 2,
  L3: 3,
  L4: 4
}
const levelStr = Object.keys(PermissionLevel)

// const getPermissionTooltip = (defOptions, tooltips) => {
//   return defOptions.map((option, idx) => {
//     return { ...option, tooltip: tooltips[idx + 1] }
//   })
// }

const optsions = Object.keys(PermissionLevel).map((level) => {
  return {
    label: levelStr[PermissionLevel[level]],
    value: PermissionLevel[level],
    disabled: false,
    // 下拉選單的描述
    param: null,
    desc: null
  }
})

const options2 = [...optsions.slice(0, 2)]
const options3 = [...optsions.slice(0, 3)]
const options4 = [...optsions.slice(0, 4)]
const options5 = [...optsions]

const rawPermissionList = [
  {
    param: 'topScope', // 可視範圍
    ui: 'dropDown',
    options: [],
    tooltip: {
      title: 'role_topScope',
      desc: [
        'role_topScope_desc1',
        'role_topScope_desc2',
        'role_topScope_desc3',
        'role_topScope_desc4'
      ],
      level: ['role_topScope_L0', 'role_topScope_L1']
    }
  },
  {
    param: 'audit', // 使用記錄
    ui: 'dropDown',
    options: options2,
    tooltip: {
      title: `role_audit`,
      desc: ['role_audit_desc'],
      level: ['role_audit_L0', 'role_audit_L1']
    }
  },
  // 勤務派遣 =================
  {
    param: 'dashboardPermission',
    ui: null,
    options: [],
    tooltip: null
  },
  {
    group: 'dashboardPermission',
    param: 'dashboard', // 勤務派遣
    ui: 'dropDown',
    options: options3,
    tooltip: {
      title: `role_dashboard`,
      desc: ['role_dashboard_desc'],
      level: ['role_dashboard_L0', 'role_dashboard_L1', 'role_dashboard_L2']
    }
  },
  // {
  //   group: 'dashboardPermission',
  //   param: 'emergencyEventAccess', // 緊急事件
  //   ui: 'dropDown',
  //   options: options3,
  //   tooltip: ['role_emergencyEventAccess_tooltip']
  // },
  // 影像管理 =================
  {
    param: 'videoPermission',
    ui: null,
    options: [],
    tooltip: null
  },
  {
    group: 'videoPermission',
    param: 'liveView', // 即時影音
    ui: 'dropDown',
    options: options3,
    tooltip: {
      title: `role_liveView`,
      desc: ['role_liveView_desc'],
      level: ['role_L0', 'role_liveView_L1', 'role_liveView_L2']
    }
  },
  {
    group: 'videoPermission',
    param: 'video', // 歷史影音
    ui: 'dropDown',
    options: options3,
    tooltip: {
      title: `role_video`,
      desc: ['role_video_desc'],
      level: ['role_L0', 'role_video_L1', 'role_video_L2']
    }
  },
  {
    group: 'videoPermission',
    param: 'videoManagement', // 影片管理
    ui: 'dropDown',
    options: options2,
    tooltip: {
      title: `role_videoManagement`,
      desc: ['role_videoManagement_desc'],
      level: ['role_L0', 'role_videoManagement_L1']
    }
  },
  // 設備操控 =================
  {
    param: 'deviceCtrlPermission',
    ui: null,
    options: [],
    tooltip: null
  },
  {
    group: 'deviceCtrlPermission',
    param: 'deviceCtrl', // 設備操控
    ui: 'dropDown',
    options: options4,
    tooltip: {
      title: `role_deviceCtrl`,
      desc: ['role_deviceCtrl_desc'],
      level: [
        'role_L0',
        'role_deviceCtrl_L1',
        'role_deviceCtrl_L2',
        'role_deviceCtrl_L3'
      ]
    }
  },
  {
    group: 'deviceCtrlPermission',
    param: 'deviceAISetting', // 辨識任務
    ui: 'dropDown',
    options: options2,
    tooltip: {
      title: `role_deviceAISetting`,
      desc: ['role_deviceAISetting_desc'],
      level: ['role_L0', 'role_deviceAISetting_L1']
    }
  },
  // 語音通話 =================
  {
    param: 'voiceCallPermission',
    ui: null,
    options: [],
    tooltip: null
  },
  {
    group: 'voiceCallPermission',
    param: 'voiceCall', // 語音對講
    ui: 'dropDown',
    options: options3,
    tooltip: {
      title: `role_voiceCall`,
      desc: ['role_voiceCall_desc'],
      level: ['role_L0', 'role_voiceCall_L1', 'role_voiceCall_L2']
    }
  },
  // {
  //   group: 'voiceCallPermission',
  //   param: 'voiceCallEventAccess', // 歷史語音
  //   ui: 'dropDown',
  //   options: options3,
  //   tooltip: ['role_voiceCallEventAccess_tooltip']
  // },
  // {
  //   group: 'voiceCallPermission',
  //   param: 'groupVoiceCall', // 群組通話管理
  //   ui: 'dropDown',
  //   options: options2,
  //   tooltip: ['role_groupVoiceCall_tooltip']
  // },
  // 車辨事件 =================
  {
    param: 'lprEventPermission',
    ui: null,
    options: [],
    tooltip: null
  },
  {
    group: 'lprEventPermission',
    param: 'lprDashboard', // 即時車牌事件
    ui: 'dropDown',
    options: options4,
    tooltip: {
      title: `role_lprDashboard`,
      desc: ['role_lprDashboard_desc'],
      level: [
        'role_L0',
        'role_lprDashboard_L1',
        'role_lprDashboard_L2',
        'role_lprDashboard_L3'
      ]
    }
  },
  {
    group: 'lprEventPermission',
    param: 'lprEventAccess', // 歷史車牌事件：一般
    ui: 'dropDown',
    options: options2,
    tooltip: {
      title: `role_lprEventAccess`,
      desc: ['role_lprEventAccess_desc'],
      level: [
        'role_L0',
        'role_lprEventAccess_L1' /*, 'role_lprEventAccess_L2'*/
      ]
    }
  },
  {
    group: 'lprEventPermission',
    param: 'lprEventAdvancedAccess', // 歷史車牌事件:進階
    ui: 'dropDown',
    options: options2,
    tooltip: {
      title: `role_lprEventAdvancedAccess`,
      desc: ['role_lprEventAdvancedAccess_desc'],
      level: ['role_L0', 'role_lprEventAdvancedAccess_L1']
    }
  },
  // 車號管理 =================
  {
    param: 'lprPermission',
    ui: null,
    options: [],
    tooltip: null
  },
  {
    group: 'lprPermission',
    param: 'lprSummary', // 車號查詢
    ui: 'dropDown',
    options: options3,
    tooltip: {
      title: `role_lprSummary`,
      desc: ['role_lprSummary_desc'],
      level: ['role_L0', 'role_lprSummary_L1', 'role_lprSummary_L2']
    }
  },
  {
    group: 'lprPermission',
    param: 'lprSubscription', // 訂閱管理
    ui: 'dropDown',
    options: options2,
    tooltip: {
      title: `role_lprSubscription`,
      desc: [
        'role_lprSubscription_desc1',
        'role_lprSubscription_desc2',
        'role_lprSubscription_desc3'
      ],
      level: ['role_L0', 'role_lprSubscription_L1']
    }
  },
  {
    group: 'lprPermission',
    param: 'lprMissionLv', // 任務管理
    ui: 'dropDown',
    options: options4,
    tooltip: {
      title: `role_lprMissionLv`,
      desc: [
        'role_lprMissionLv_desc1',
        'role_lprMissionLv_desc2',
        'role_lprMissionLv_desc3'
      ],
      level: [
        'role_L0',
        'role_lprMissionLv_L1',
        'role_lprMissionLv_L2',
        'role_lprMissionLv_L3'
      ]
    }
  },
  {
    group: 'lprPermission',
    param: 'lprMission', // 任務類型
    ui: 'dropDown',
    options: options2,
    tooltip: {
      title: `role_lprMission`,
      desc: ['role_lprMission_desc'],
      level: ['role_L0', 'role_lprMission_L1']
    }
  },
  {
    group: 'lprPermission',
    param: 'lprInfoLv', // 車輛資訊
    ui: 'dropDown',
    options: options2,
    tooltip: {
      title: `role_lprInfoLv`,
      desc: ['role_lprInfoLv_desc1', 'role_lprInfoLv_desc2'],
      level: ['role_L0', 'role_lprInfoLv_L1']
    }
  },
  {
    group: 'lprPermission',
    param: 'lprInfo', // 車輛標記
    ui: 'dropDown',
    options: options2,
    tooltip: {
      title: `role_lprInfo`,
      desc: ['role_lprInfo_desc1', 'role_lprInfo_desc2', 'role_lprInfo_desc3'],
      level: ['role_L0', 'role_lprInfo_L1']
    }
  },
  {
    group: 'lprPermission',
    param: 'lprAnnouncement', // 推送公告
    ui: 'dropDown',
    options: options2,
    tooltip: {
      title: `role_lprAnnouncement`,
      desc: [
        'role_lprAnnouncement_desc1',
        'role_lprAnnouncement_desc2',
        'role_lprAnnouncement_desc3'
      ],
      level: ['role_L0', 'role_lprAnnouncement_L1']
    }
  },
  {
    group: 'lprPermission',
    param: 'lprReport', // 績效報表
    ui: 'dropDown',
    options: options3,
    tooltip: {
      title: `role_lprReport`,
      desc: ['role_lprReport_desc1', 'role_lprReport_desc2'],
      level: ['role_L0', 'role_lprReport_L1', 'role_lprReport_L2']
    }
  },
  // {
  //   group: 'lprPermission',
  //   param: 'lprGroupInfoLv', // 群組車輛資訊
  //   ui: 'dropDown',
  //   options: options2,
  //   tooltip: ['role_lprGroupInfoLv_tooltip']
  // },
  // {
  //   group: 'lprPermission',
  //   param: 'lprGroupInfo', // 群組車輛標記
  //   ui: 'dropDown',
  //   options: options2,
  //   tooltip: ['role_lprGroupInfo_tooltip']
  // },
  // 物件辨識 =================
  // {
  //   param: 'orEventPermission',
  //   ui: null,
  //   options: [],
  //   tooltip: null
  // },
  // {
  //   group: 'orEventPermission',
  //   param: 'orDashboard', // 即時物件事件
  //   ui: 'dropDown',
  //   options: options2
  //   tooltip: [
  //     'role_orDashboard_tooltip1',
  //     'role_orDashboard_tooltip2'
  //   ]
  // },
  // {
  //   group: 'orEventPermission',
  //   param: 'orEventAccess', // 歷史物件事件
  //   ui: 'dropDown',
  //   options: options2,
  //   tooltip: [
  //     'role_orEventAccess_tooltip1',
  //     'role_orEventAccess_tooltip2'
  //   ]
  // },
  // 人臉辨識 =================
  {
    param: 'frEventPermission',
    ui: null,
    options: [],
    tooltip: null
  },
  {
    group: 'frEventPermission',
    param: 'frDashboard', // 即時人物事件
    ui: 'dropDown',
    options: options4,
    tooltip: {
      title: `role_frDashboard`,
      desc: ['role_frDashboard_desc'],
      level: [
        'role_L0',
        'role_frDashboard_L1',
        'role_frDashboard_L2',
        'role_frDashboard_L3'
      ]
    }
  },
  {
    group: 'frEventPermission',
    param: 'frEventAccess', // 歷史人物事件
    ui: 'dropDown',
    options: options2,
    tooltip: {
      title: `role_frEventAccess`,
      desc: ['role_frEventAccess_desc'],
      level: ['role_L0', 'role_frEventAccess_L1']
    }
  },
  // 人物管理 =================
  {
    param: 'frPermission',
    ui: null,
    options: [],
    tooltip: null
  },
  {
    group: 'frPermission',
    param: 'frInfoLv', // 人物資訊
    ui: 'dropDown',
    options: options2,
    tooltip: {
      title: `role_frInfoLv`,
      desc: ['role_frInfoLv_desc1', 'role_frInfoLv_desc2'],
      level: ['role_L0', 'role_frInfoLv_L1']
    }
  },
  {
    group: 'frPermission',
    param: 'frInfo', // 人物標記
    ui: 'dropDown',
    options: options2,
    tooltip: {
      title: `role_frInfo`,
      desc: ['role_frInfo_desc1', 'role_frInfo_desc2', 'role_frInfo_desc3'],
      level: ['role_L0', 'role_frInfo_L1']
    }
  },
  // 帳號與權限 =================
  {
    param: 'accounrPermission',
    ui: null,
    options: [],
    tooltip: null
  },
  {
    group: 'accounrPermission',
    param: 'account', // 使用者帳號
    ui: 'dropDown',
    options: options3,
    tooltip: {
      title: `role_account`,
      desc: ['role_account_desc1', 'role_account_desc2'],
      level: ['role_L0', 'role_account_L1', 'role_account_L2']
    }
  },
  {
    group: 'accounrPermission',
    param: 'device', // 設備帳號
    ui: 'dropDown',
    options: options5,
    tooltip: {
      title: `role_device`,
      desc: ['role_device_desc1', 'role_device_desc2'],
      level: [
        'role_L0',
        'role_device_L1',
        'role_device_L2',
        'role_device_L3',
        'role_device_L4'
      ]
    }
  },
  {
    group: 'accounrPermission',
    param: 'group', // 群組
    ui: 'dropDown',
    options: options3,
    tooltip: {
      title: `role_group`,
      desc: ['role_group_desc1', 'role_group_desc2', 'role_group_desc3'],
      level: ['role_L0', 'role_group_L1', 'role_group_L2']
    }
  },
  {
    group: 'accounrPermission',
    param: 'roleManagement', // 角色
    ui: 'dropDown',
    options: options3,
    tooltip: {
      title: `role_roleManagement`,
      desc: [
        'role_roleManagement_desc1',
        'role_roleManagement_desc2',
        'role_roleManagement_desc3',
        'role_roleManagement_desc4'
      ],
      level: ['role_L0', 'role_roleManagement_L1', 'role_roleManagement_L2']
    }
  },
  // 系統與服務 =================
  {
    param: 'systemAndServicePermission',
    ui: null,
    options: [],
    tooltip: null
  },
  {
    group: 'systemAndServicePermission',
    param: 'system', // 系統設定：全域設定 + 客製網頁 + 後台設定 + 伺服器能力
    ui: 'dropDown',
    options: options2,
    tooltip: {
      title: `role_system`,
      desc: ['role_system_desc1', 'role_system_desc2'],
      level: ['role_L0', 'role_system_L1']
    }
  },
  // {
  //   group: 'systemAndServicePermission',
  //   param: 'systemAibox', // 辨識盒
  //   ui: 'dropdown',
  //   options: options2,
  //   tooltip: ['role_systemAibox_tooltip']
  // },
  // {
  //   group: 'systemAndServicePermission',
  //   param: 'systemServer', // 伺服器
  //   ui: 'dropdown',
  //   options: options2,
  //   tooltip: ['role_systemServer_tooltip']
  // },
  // {
  //   group: 'systemAndServicePermission',
  //   param: 'systemDevice', // 設備
  //   ui: 'dropdown',
  //   options: options2,
  //   tooltip: ['role_systemDevice_tooltip']
  // },
  // {
  //   group: 'systemAndServicePermission',
  //   param: 'systemLicense', // 授權
  //   ui: 'dropdown',
  //   options: options2,
  //   tooltip: ['role_systemLicense_tooltip']
  // },
  // {
  //   group: 'systemAndServicePermission',
  //   param: 'systemLog',
  //   ui: 'dropdown',
  //   options: options2,
  //   tooltip: ['role_systemLog_tooltip']
  // },
]

const getPermissionList = (rawList) => {
  const list = rawList.map((permission) => {
    let { param, tooltip, options } = permission
    const newPermission = JSON.parse(JSON.stringify(permission))
    const newOptions = options.map((option, oIdx) => {
      const newOption = { ...option }

      newOption.param = param
      newOption.desc = tooltip.level[oIdx]

      return newOption
    })
    newPermission.options = JSON.parse(JSON.stringify(newOptions))
    return newPermission
  })

  return list
}

const getPermissionGroup = (permissionList) => {
  const groups = permissionList.filter((perm) => !perm.ui)
  const groupsObj = {}

  groups.forEach((item) => {
    groupsObj[item.param] = true
  })

  return groupsObj
}

export const permissionList = getPermissionList(rawPermissionList)
export const permissionGroup = getPermissionGroup(permissionList)

// checkRolePermission(被檢查權限, 允許權限)
export const checkRolePermission = (level, base) => {
  return level >= base
}
