import Vue from 'vue'
import i18n from "../../i18n/i18n"
import { setLprDefaultValue } from '@/config/configLpr.js'
import {
  apiGetTagList, 
  apiGetFrTagList,
  apiGetDefaultSetting,
  apiGetUserSetting,
  apiGetUserInfo,
  apiEditUser,
  apiPostUserSetting,
  apiPutUserSetting,
  apiGetUserSubscribers,
  apiPostUserSubscriber,                                       
  apiDeleteUserSubscriber,
} from '@/api/index.js'

const initialState = () => ({
  mode: 'view', // view, edit
  origDefaultSetting: null,
  currDefaultSetting: 1,
  faceSizeRatio: 10, // 透過此參數連動canvas與右側scroll bar 調整人臉框大小
  defaultSetting: {},
  userSetting: {},
  theSetting: {},
  tagList: [],
  origNote: '',
  note: '',
  subscribers: [],
  currentSubscribers: [],
  isEditRoi: false,
  camIndex: 0,
  origLprNotifyFilterMode: 2, // 記錄原始事件通報模式
  lprNotifyFilterMode: 2, // 事件通報模式 用 GET API /api/web/user-info 讀取
})

const state = initialState()

const getters = {
  userIndex(state, getters, rootState, rootGetters) {
    return rootGetters.rightClickUser.index
  },
  userId(state, getters, rootState, rootGetters) {
    return rootGetters.rightClickUser.id
  },
  deviceModelId(state, getters, rootState, rootGetters) {
    return rootGetters.rightClickUser.deviceModelId
  },
  isAiCam(state, getters) {
    return getters.deviceModelId === 131
  },
  isSettingReady(state) {
    return Object.keys(state.defaultSetting).length > 0 && Object.keys(state.theSetting).length > 0
  },
  currentSetting(state) {
    // defaultSetting = 0: userSetting 設備客製/可編輯, 
    // defaultSetting = 1: defaultSetting 系統預設/不可編輯
    return state.currDefaultSetting === 0 ? state.theSetting : state.defaultSetting
  },
  aiModelType(state, getters) {
    // tw, jp
    return getters.currentSetting.setting.aiModelType
  },
  lprModelType(state, getters) {
    // lprTw, lprJp
    return 'lpr' + getters.aiModelType.slice(0, 1).toUpperCase() + getters.aiModelType.slice(1)
  },
  Roi(state, getters) {
    return getters.currentSetting.setting.channel[state.camIndex][getters.lprModelType].roi
  },
  lprSizeRatio(state, getters) {
    return getters.currentSetting.setting.channel[state.camIndex][getters.lprModelType].lprSizeRatio
  },
  indent(state, getters) {
    return getters.currentSetting.setting.channel[state.camIndex][getters.lprModelType].indent
  },
  isSettingSrcModified(state) {
    return state.currDefaultSetting !== state.origDefaultSetting
  },
  isSettingModified(state) {
    if (JSON.stringify(state.theSetting.setting) === JSON.stringify(state.userSetting.setting)
      || (JSON.stringify(state.theSetting.setting) === JSON.stringify(state.defaultSetting.setting))) 
      return false
    return true
  },
  isNoteModified(state) {
    return state.note !== state.origNote
  },
  isNotifyFilterModeModified(state) {
    return state.lprNotifyFilterMode !== state.origLprNotifyFilterMode
  },
  isSubscribersModified(state) {
    const subscribers = state.subscribers.map(subscriber => subscriber.subscriberId)
    const currentSubscribers = state.currentSubscribers.map(subscriber => subscriber.subscriberId)
    const addIds = currentSubscribers.filter(subscriber => !subscribers.includes(subscriber))
    const minusIds = subscribers.filter(subscriber => !currentSubscribers.includes(subscriber))
    return addIds.length > 0 || minusIds.length > 0
  }
}

const mutations = {
  resetState(state) {
    Object.assign(state, initialState())
  },
  updateMode(state, value) {
    state.mode = value // view, edit
  },
  updateOrigDefaultSetting(state, value) {
    state.origDefaultSetting = value
  },
  updateCurrDefaultSetting(state, value) {
    state.currDefaultSetting = value
  },
  updateDefaultSetting(state, data) {
    state.defaultSetting = data
  },
  updateUserSetting(state, data) {
    state.userSetting = data
  },
  updateTheSetting(state, data) {
    state.theSetting = data
  },
  updateSetting(state, data) {
    state.theSetting.setting = data
  },
  updateAiModelType(state, data) {
    state.theSetting.setting.aiModelType = data
  },
  updateTagList(state, data) {
    state.tagList = data
  },
  updateOrigNote(state, data) {
    state.origNote = data
  },
  updateNote(state, data) {
    state.note = data
  },
  updateChannelAccount(state, account) {
    state.theSetting.setting.channel.forEach(channel => {
      if (channel.account === '') channel.account = account
    })
  },
  updateChannelServer(state, server) {
    state.theSetting.setting.channel.forEach(channel => {
      channel.server = server
    })
  },
  updateTagFilter(state, data) {
    const aiModelType = state.theSetting.setting.aiModelType
    const lprModelType = 'lpr' + aiModelType.slice(0, 1).toUpperCase() + aiModelType.slice(1)
    state.theSetting.setting.deviceConfig[lprModelType].tagFilter = data
  },
  updateSubscribers(state, data) {
    state.subscribers = data
  },
  updateCurrentSubscribers(state, data) {
    state.currentSubscribers = data
  },
  updateIsEditRoi(state, data) {
    state.isEditRoi = data
  },
  updateROI(state, data) {
    const aiModelType = state.theSetting.setting.aiModelType
    const lprModelType = 'lpr' + aiModelType.slice(0, 1).toUpperCase() + aiModelType.slice(1)
    state.theSetting.setting.channel[state.camIndex][lprModelType].roi = data
  },
  updateCamIndex(state, data) {
    state.camIndex = data
  },
  updateLprSizeRatio(state, data) {
    const aiModelType = state.theSetting.setting.aiModelType
    const lprModelType = 'lpr' + aiModelType.slice(0, 1).toUpperCase() + aiModelType.slice(1)
    state.theSetting.setting.channel[state.camIndex][lprModelType].lprSizeRatio = data
  }, 
  updateOrigLprNotifyFilterMode(state, data) {
    state.origLprNotifyFilterMode = data
  },
  updateLprNotifyFilterMode(state, data) {
    state.lprNotifyFilterMode = data
  },
}

const actions = {
  async getTagList({commit, rootState}) {
    try {
      let res = {}
      if (rootState.aibox.aiMode === 'lpr')
        res = await apiGetTagList()
      else if (rootState.aibox.aiMode === 'fr')
        res = await apiGetFrTagList()
      if (res.status === 200) {
        commit('updateTagList', res.data.tagList)
      }
    } catch (err) {
      console.log('發生錯誤：', err)
    }
  },
  
  async getDefaultSetting({ commit, getters }) {
    try {
      const res = await apiGetDefaultSetting(getters.deviceModelId)
      if (res.status === 200) {
        // 檢查參數是否為undefined，若為undefined則設定預設值
        setLprDefaultValue(res.data.setting)

        commit('updateDefaultSetting', res.data)
      }
    } catch (err) {
      commit('updateDefaultSetting', {})
    }
  },
  async getUserSetting({ commit, getters }) {
    try {
      const res = await apiGetUserSetting(getters.userIndex)
      if (res.status === 200) {
        // 檢查參數是否為undefined，若為undefined則設定預設值
        setLprDefaultValue(res.data.setting)

        commit('updateUserSetting', res.data)
      } else {
        commit('updateUserSetting', {})
      }
    } catch (err) {
      commit('updateUserSetting', {})
    }
  },
  async getUserInfo({ commit, getters, rootState }) {
    try {
      const res = await apiGetUserInfo(getters.userIndex)
      if (res.status === 200) {
        commit('updateOrigDefaultSetting', res.data.defaultSetting)
        commit('updateCurrDefaultSetting', res.data.defaultSetting)

        let note = ''
        switch (rootState.aibox.aiMode) {
          case 'fr':
            note = res.data.frNote
            break
          case 'lpr':
            note = res.data.lprNote
            break
        }  
        commit('updateNote', note)
        commit('updateOrigNote', note)

        if (getters.isAiCam) { // is AiCam
          commit('updateOrigLprNotifyFilterMode', res.data.lprNotifyFilterMode)
          commit('updateLprNotifyFilterMode', res.data.lprNotifyFilterMode)
        }
      }
    } catch (err) {
      console.log('發生錯誤：', err)
    }
  },
  initSetting({ state, commit }) {
    // defaultSetting = 0: userSetting 設備客製/可編輯, 
    // defaultSetting = 1: defaultSetting 系統預設/不可編輯
    if (state.origDefaultSetting === 1) {
      commit('updateTheSetting', JSON.parse(JSON.stringify(state.defaultSetting)))
    } else {
      if (Object.keys(state.userSetting).length === 0 || Object.keys(state.userSetting.setting).length === 0) {
        commit('updateTheSetting', JSON.parse(JSON.stringify(state.defaultSetting)))
      } else {
        commit('updateTheSetting', JSON.parse(JSON.stringify(state.userSetting)))
      }
    }
  },
  async editUser({ state, getters, dispatch }) {
    if (
      !getters.isSettingSrcModified && 
      !getters.isNoteModified && 
      !getters.isSettingModified &&
      !getters.isNotifyFilterModeModified
    ) return
    let type = '', title = '', message = ''
    try {
      const data = {
        id: getters.userId,
        lprNote: state.note,
      }
      if (getters.isSettingSrcModified) {
        data.defaultSetting = state.currDefaultSetting
        title = i18n.t('ai_param_source')/*參數來源*/
      }

      if (getters.isNoteModified) {
        title += title ? '、' : ''
        title += i18n.t('ai_remark')/*備註*/
      }
      
      if (getters.isNotifyFilterModeModified) {
        title += title ? '、' : ''
        title += i18n.t('ai_notify_filter_mode')/*事件通報模式*/
        data.lprNotifyFilterMode = state.lprNotifyFilterMode
      }

      await apiEditUser(data)

      // 若參數來源有修改，則需呼叫getUserInfo --> initSetting
      if (getters.isSettingSrcModified || getters.isNoteModified || getters.isNotifyFilterModeModified) {
        await dispatch('getUserInfo')
      }
      await dispatch('getUserSetting')
      dispatch('initSetting')
      type = 'success'
      message = i18n.t('ai_setting_success')/*設定成功*/
    } catch (err) {
      type = 'error'
      message = i18n.t('ai_setting_fail')/*設定失敗*/
    }

    if (title)
      Vue.prototype.$notify({
        type: type,
        title: title,
        message: message,
        // position: 'bottom-left'
      })
  },
  onUpdateCurrDefaultSetting({ commit }, value) {
    commit('updateCurrDefaultSetting', value)
    if (value === 1) {
      commit('updateTheSetting', JSON.parse(JSON.stringify(state.defaultSetting)))
    } else {
      if (Object.keys(state.userSetting).length === 0 || Object.keys(state.userSetting.setting).length === 0) {
        commit('updateTheSetting', JSON.parse(JSON.stringify(state.defaultSetting)))
      } else {
        commit('updateTheSetting', JSON.parse(JSON.stringify(state.userSetting)))
      }
    }
  },
  async editUserSetting({ state, getters, commit }) {
    let type, message
    try {
      if (!getters.isSettingModified) return
      if (getters.isAiCam) {
        commit('updateChannelAccount', getters.userId)
        const hostname = process.env.NODE_ENV === 'production' ? location.hostname : process.env.VUE_APP_API_HOSTNAME
        commit('updateChannelServer', hostname)
      }

      const data = {
        userId: String(getters.userIndex), // userId要帶入user.index
        setting: getters.currentSetting.setting,
      }
      // 判斷userSetting是否存在，存在則為修改，不存在則為新增
      if (Object.keys(state.userSetting).length === 0) 
        await apiPostUserSetting(data)
      else
        await apiPutUserSetting(data)

      type = 'success'
      message = i18n.t('ai_setting_success') /*設定成功*/
    } catch (err) {
      type = 'error'
      message = i18n.t('ai_setting_fail') /*設定失敗*/
    }

    Vue.prototype.$notify({
      title: i18n.t('ai_recognition_setting') /*辨識設定*/,
      message: message || '',
      type: type || 'error',
      // position: 'bottom-left'
    })
  },
  async getUserSubscribers({ commit, getters }) {
    try {
      const res = await apiGetUserSubscribers(getters.userIndex, 1) // 第2個參數 model => LPR: 1, OR: 2, FR: 3
      const data = res.status === 200 ? res.data : []
      commit('updateSubscribers', data)
      commit('updateCurrentSubscribers', data)
    } catch (err) {
      console.log('發生錯誤：', err)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async handleUserSubscribers({ state, getters, dispatch, rootState }) {
    const subscribers = state.subscribers.map(subscriber => subscriber.subscriberId)
    const currentSubscribers = state.currentSubscribers.map(subscriber => subscriber.subscriberId)
    const addIds = currentSubscribers.filter(subscriber => !subscribers.includes(subscriber))
    const minusIds = subscribers.filter(subscriber => !currentSubscribers.includes(subscriber))
    
    if (addIds.length !== 0 || minusIds.length !== 0) {
      let model = 1
      if (rootState.aibox.aiMode === 'fr') {
        model = 3
      } else if (rootState.aibox.aiMode === 'lpr') {
        model = 1
      }
      const promisesAdd = addIds.map(async(id) => {
        const data = {
          userId: String(getters.userIndex),
          subscriberId: id,
          model: model, // model: 1:LPR, 2:OR, 3:FR
        }
        await apiPostUserSubscriber(data)
      })

      const promisesDel = minusIds.map(async(id) => {
        const data = {
          userId: String(getters.userIndex),
          subscriberId: id,
          model: model, // model: 1:LPR, 2:OR, 3:FR
        }
        await apiDeleteUserSubscriber(data)
      })

      const promises = promisesAdd.concat(promisesDel)

      Promise.all(promises)
      .then(() => {
        dispatch('getUserSubscribers')
        Vue.prototype.$notify({
          title: i18n.t('ai_receiver') /*接收人*/,
          message: i18n.t('ai_setting_success') /*設定成功*/,
          type: 'success',
          // position: 'bottom-left'
        })
      })
      .catch(() => {
        dispatch('getUserSubscribers')
        Vue.prototype.$notify({
          title: i18n.t('ai_receiver') /*接收人*/,
          message: i18n.t('ai_setting_fail') /*設定失敗*/,
          type: 'error',
          // position: 'bottom-left'
        })
      })
    }
  }
}



export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}