// Note: 維持 Key: [string]; 不換行, 方便語系比對閱讀
const notify_style = 'font-size: 24px; font-style: italic; font-weight: 500; color: #303133'

const en = {
  /* project */
  system_name: '5G AI Patrol System',
  system_language: 'Language',
  system_timezone: 'Timezone',
  system_default: 'System presets',

  /* global */
  yes: 'Yes',
  no: 'No',
  cancel: 'Cancel',
  ok: 'OK',
  save: 'Save',
  close: 'Close',
  delete: 'Delete',
  confirm: 'Confirm',
  finish: 'Finish',
  error: 'Error',
  edit: 'Edit',
  view: 'View',
  none: 'None',
  custom: 'Custom',
  total: 'Total',
  go: 'Go',
  loading: 'Loading',
  selected: 'Selected',
  search: 'Search',
  pre: 'Previous',
  next: 'Next',
  stop: 'Stop',
  unknown: 'Unknown',
  tag: 'Tag',
  license_plate_example: 'ABC-1234',
  on: 'On',
  off: 'Off',
  device: 'Device',
  vehicle: 'Vehicle',
  mission: 'Mission',
  name: 'Name',
  attribute: 'Attribute',
  link: 'Link',
  update_time: 'Update time',
  all: 'All',
  adv_search: 'Adv. Search',
  search_result: 'Result：{count}',
  new_tag_hint: 'New Tag',
  event: 'Event',
  decibel_meter: 'Decibel meter',
  hint: 'Hint',
  collapse: 'Collapse',
  expand: 'Expand',
  search_fail_title: 'Search fail',
  no_subscription: '未登記',
  delete_data: 'Delete data',
  delete_data_confirm: 'Are you sure to delete {count} data(s)?',
  age: '{age}Y',

  /* alarm */
  data_error: '資料錯誤',

  /* language */
  lang_zh: '繁體中文',
  lang_en: 'English',
  lang_ja: '日本語',

  /* week & time */
  sun: 'Sun',
  mon: 'Mon',
  tue: 'Tue',
  wed: 'Wed',
  thu: 'Thu',
  fri: 'Fri',
  sat: 'Sat',
  month: 'months',
  day: 'days',
  one_day: 'day',
  hour: 'hours',
  min: 'minutes',
  one_min: 'minute',
  sec: 'seconds',
  month_ago: 'months ago',
  day_ago: 'days ago',
  hour_ago: 'hours ago',
  min_ago: 'minutes ago',
  sec_ago: 'seconds ago',
  one_min_later: 'minute later',
  min_later: 'minutes later',
  today: 'Today',
  yesterday: 'Yesterday',
  last_week: 'Last week',
  last_month: 'Last month',
  last_three_months: 'Last three months',
  half_year_ago: 'Half year ago',
  custom_time: 'Custom time',

  /* login */
  login_id: 'ID',
  login_pw: 'Password',
  login_hint: 'Please enter account and password!',
  login_account_password_error: 'Wrong username or password.',
  login_npa_hint: 'Please contact your system administrator',

  /* Api */
  api_400: 'Missing required fields.',
  api_401_login: `帳號或密碼錯誤`,
  api_403: `Permission denied`,
  api_404: `Not Found`,
  api_404_login: `帳號或密碼錯誤`,
  api_500: `Internal Server Error`,
  api_error: `Something Error`,
  api_econnaborted: 'Network connection has been lost.',
  api_timeout: 'The request had timed out.',

  /* left bar */
  left_bar_dashboard: 'Dashboard',
  left_bar_history: 'History Events',
  // left_bar_subscription: 'Subscription',
  left_bar_subscription: 'Comparison Database',
  left_bar_recognition: 'Comparison Database',
  left_bar_lpr_recognition: 'Vehicle number management',
  left_bar_fr_recognition: 'Character management',
  left_bar_account_management: 'Account and Permissions',
  left_bar_system_setting: 'System and Services',
  left_bar_download_app: 'Download Center',
  left_bar_export_center: 'Export Center',
  left_bar_account: 'Account',
  left_bar_call: 'Call',
  left_bar_joined_call: 'Joined the call',
  left_bar_left_call: 'left call',
  left_bar_end_call_before: 'Please end the current call before starting a new one',
  left_bar_cant_call_myself: 'can not call myself',
  left_bar_15_seconds_hangup: '15 seconds no answer, automatically hang up the call',
  left_bar_failed_sdp_speak: 'Failed to exchange SDP speak or send voice notification, disconnect the call connection',
  left_bar_another_webpage_call: 'You have opened a call on another web page, you must close it to reopen the call',
  left_bar_other_has_hungup: 'The other party has hung up the phone',
  left_ber_userinfo_change_password: 'Change Password',
  left_bar_userinfo_username_placeholder: 'User Name(Limit 20 words)',
  left_bar_userinfo_email_placeholder: 'Email',
  left_bar_userinfo_phone_placeholder: 'Mobile Number',

  /* top bar */
  top_bar_keep_login: 'Keep login',
  top_bar_online_devices: 'Online Devices',
  top_bar_calling_volume_off: 'During call, volume muted.',
  top_bar_calling_volume_reverse: 'The call ended and the volume has been restored.',

  /* 下載APP */
  download_notify_app: 'Notify APP',
  download_info: 'Please use your personal mobile phone to scan the QR code and download the notification APP',
  download_android: 'Android',
  download_ios: 'iOS',
  download_manual: 'Manual',
  download_manual_fn: 'Manual.pdf',
  download_tool: 'Tool',
  download_search_placeholder: 'Search',
  download_name: 'Name',
  download_version: 'Version',
  download_update_time: 'Update time',
  download_size: 'File size',
  download_os: 'OS',
  download: 'Download',
  download_no_info: 'No info',

  /* 匯出中心 */
  export_category: 'Category',
  export_filename: 'File name',
  export_expiration_time: 'Expiration time',
  export_file_size: 'File size',
  export_status_download: 'download',
  export_status_fail: 'Export failed',
  export_status_exporting: 'Exporting',
  export_status_deleting: 'Deleting',
  export_warning_title: 'Unable to export data',
  export_warning_content: 'The export center has reached the limit of 5 data, please clear the old data.',
  export_custom_filename: 'Custom Filename',
  export_search_criteria: 'Search Criteria',
  export_plate_number: 'Plate Number',
  export_time_interval: 'Time Interval',
  export_event_category: 'Event Category',
  export_mission: 'Mission',
  export_finished_condition: 'Finished Condition',
  export_category_basic: 'Basic Search',
  export_category_advanced: 'Advanced Search',
  export_category_performance: 'Performance Search',
  export_category_audit: 'Audit Search',

  /* 二因子認證-2FA */
  twoFactorAuth_title: 'Two-Factor Authentication',
  twoFactorAuth_installApp: 'Use Verification APP',
  twoFactorAuth_installApp_desc1: 'For extra protection,please use apps Google Authenticator or Duo Mobile to generate verification codes.',
  twoFactorAuth_installApp_desc2: `If you haven't installed the authentication application, please install it first to proceed to the next step.`,
  twoFactorAuth_scanQrCode: 'Setting by Third-Party Verification App',
  twoFactorAuth_scanQrCode_desc: 'Please scan the QR code with your authenticator app (such as DUO or Google Authenticator).',
  twoFactorAuth_inputCode: 'Please input 2FA code',
  twoFactorAuth_inputCode_login: 'Please input 2FA code',
  twoFactorAuth_inputCode_desc: 'Please input the 2FA code from the verification APP.',
  twoFactorAuth_done: 'Enabled',
  twoFactorAuth_done_desc: 'The Two-Factor Authentication of this account is already  enabled',
  twoFactorAuth_enabled_pass: 'Successed to enable',
  twoFactorAuth_enabled_fail: 'Failed to enable',
  twoFactorAuth_disabled_pass: 'Successed to disable',
  twoFactorAuth_disabled_fail: 'Failed to diabled',
  twoFactorAuth_validate_pass: 'Successed to validate',
  twoFactorAuth_validate_fail: 'Failed to validate',
  twoFactorAuth_error_getQrCode: 'Failed to get QR Code',
  twoFactorAuth_error_inputNumber: 'Please input the number',
  twoFactorAuth_error_fullCode: 'Please input completed 2FA code',

  /* account */
  account_title: 'Account',
  account_name: 'Name',
  account_role: 'Role',
  account_change_password: 'Change Password',
  account_disable_2fa: 'Disable Two-Factor Authentication',
  account_id_tip: '請輸入{min}-{max}個字元，包含英文、數字與任一特殊符號',
  account_id_length_error: '帳號長度需{min}-{max}個字元。',
  account_id_char_error: '帳號只允許英數，大小寫，及特殊字元',
  account_edit_password: 'Change password',
  account_edit_password_tip1: '1. The length of the password must be {min}～{max} characters.',
  account_edit_password_tip2: '2. Please enter any one special characters.',
  account_pw_tip: 'The length of the password must be {min}-{max} characters and contain any one special characters.',
  account_new_pw: 'New Password',
  account_reenter_pw: 'Re-enter Your New Password',
  account_pw_length_error: 'The length of the password must be {min}-{max} characters.',
  account_pw_char_error: '密碼只允許英數，大小寫，及特殊字元',
  account_pw_special_char_error: 'The password mush have any one special characters.',
  account_pw_error: 'The two passwords do not match, please confirm!',
  account_pw_null: 'Password cannot be empty!',
  account_pw_success: 'Password changed successfully.',
  account_pw_failed: 'Error changing password.',
  account_2fa: 'Two-Factor Authentication',
  account_logout: 'Logout',
  account_logout_hint: 'Are you sure you want to leave?',
  account_delete_confirm: 'Confirm delete',
  account_notify_edit_title: 'Edit account information',
  account_notify_edit_success: 'Account information modified successfully',
  account_notify_edit_failed: 'Error editing account',
  account_add_role: 'Add Role',
  account_edit_role: 'Edit Role',
  account_copy_role: 'Copy Role',
  account_delete_role: 'Delete Role',
  account_add: 'Add account',
  account_add_success: 'Account successfully added.',
  account_add_failed: 'Error adding account',
  account_required: '<span class="required">*</span> Required',
  account_detail_group_accound: 'Group and Account',
  account_detail_group: 'Group',
  account_detail_id: 'Account',
  account_detail_password: 'Password',
  account_required_info: 'required',
  account_viewing_quota: 'Viewing quota',
  account_multi_select: 'Multiple selection accounts',
  account_cancel_multi_select: 'Cancel multiple selection mode',
  account_selected: 'Selected account',
  account_batch_selected_account_only: 'Display Selected Account Only({count})',
  account_batch_edit_items: 'Batch edit items',
  account_add_items: 'Add items',
  account_abnormal_account: 'Abnormal account',
  account_filter_result: 'Quantity：{count}',
  account_batch_modified: 'The user have been modified in batches',
  account_batch_n: '{pass_count} user(s) successfully and {fail_count} user(s) failed.',
  account_n_success: 'successful updated',
  account_n_failed: 'failed',
  account_active: 'Active',
  account_not_activated: 'Disabled',
  account_enabled: 'Enabled',
  account_disabled_until: 'Disabled until',
  account_disabled: 'Disabled',
  account_suspend: 'Suspend',
  account_status: 'Used Status',
  account_status_enabled: '啟用',
  account_status_disable: '停用',
  account_status_locked: '暫停',
  account_group: 'Group',
  account_id: 'Account',
  account_password: 'Password',
  account_confirm_password: 'Confirm password',
  account_detail_name: 'Name',
  account_all: 'All',
  account_public_title: 'Public Status',
  account_public_all: 'All',
  account_public_0: 'Private',
  account_public_1: 'Public',
  account_default_setting: 'Config Source',
  account_defaultSetting_1: 'System Default',
  account_defaultSetting_0: 'Device Customize',
  account_watermark: 'Watermark',
  account_watermark_0: 'Disabled',
  account_watermark_1: 'Enabled',
  account_detail_linkmode_title: '勾稽',
  account_linkmode_title: 'Link Mode',
  account_linkmode_all: 'All',
  account_linkmode_0: 'Disabled',
  account_linkmode_1: 'BoviLive',
  account_linkmode_2: 'BoviLive - forced',
  account_linkmode_3: ' NPA',
  account_linkmode_4: 'NPA - forced',
  account_linkmode_group: 'Group',
  account_linkmode_user: 'User',
  account_type: 'Account type',
  account_kind_default: 'Default',
  account_kind_sso: 'SSO',
  account_kind_device: 'Device',
  account_kind_user: 'User',
  account_kind_0: 'Default',
  account_kind_1: 'SSO',
  account_kind_2: 'Device',
  account_kind_3: 'U',
  account_label: 'Account name',
  account_lang: 'Language',
  account_tz: 'Timezone',
  account_video_title: 'Video title',
  account_detail_property: '屬性',
  account_lpr_keep: 'Event keep',
  account_video_keep: 'Video keep',
  account_keep_day: 'Keey Day',
  account_device_model: 'Device type',
  account_device_model_0: 'Other',
  account_device_series_10: 'BoviCam Series',
  account_device_series_100: 'AiCam Series',
  account_device_series_200: 'Bodyworn Cam Series',
  account_device_series_500: 'FaceArk Series',
  account_device_series_1000: 'Android APP Series',
  account_device_series_2000: 'iOS APP Series',
  account_device_series_3000: 'Mac Series',
  account_ip_source: 'IP source',
  account_gps: 'GPS',
  account_from: 'Account source',
  account_last_action: '最新活動紀錄',
  account_time: 'Time',
  account_login_time: 'Time',
  account_keep_data: 'Keep Data',
  account_updated_time: 'Updated time',
  account_register_time: 'Register time',
  account_locked: 'Account is locked',
  account_15min_later: '15 min later',
  account_30min_later: '30 min later',
  account_1hr_later: '1 hour later',
  account_edit_title: 'Edit Account',
  account_edit_device_model_hint: '請注意, 變更設備類型時會一併清空既存設備參數及事件接收人清單',
  account_edit_failed: 'Failed to edit account',
  account_edit_success: 'Successfully updated',
  account_lock_success: 'Successfully locked',
  account_lock_failed: 'Lock error occurred.',
  account_unlock: 'Unlock account',
  account_unlock_success: 'Unlocked successfully.',
  account_unlock_failed: 'Unlock error occurred.',
  account_source_sso: 'SSO',
  account_source_self: 'Self Created',
  account_forceed_2fa: '2FA',
  account_forceed2fa_0: 'On Demand',
  account_forceed2fa_1: 'Forced',
  account_enabled_2fa: '已啟用雙重驗證',

  /* role permission */
  role_no_permission: 'No permission',
  role_general: 'General',
  role_advanced: 'Advanced',
  role_medium: 'Medium',
  role_higher: 'Higher',
  role_off: 'Off',
  role_on: 'On',
  role_L0: 'L0：No permission.',
  role_new_name: 'New role(1)',
  role_topScope: 'Top scope',
  role_topScope_tooltip: 'Select a group as the visible range',
  role_topScop_default: 'Default',
  role_topScope_desc1: 'Provide the setting for resource access range.',
  role_topScope_desc2: 'Groups are in a tree structure. By setting the "visible range," the highest-level group node that adjusts the access range is determined. The current role can access the resources of that group and all its subordinate groups.',
  role_topScope_desc3: 'All derivative resources generated from non-public accounts belong to the corresponding group.',
  role_topScope_desc4: `If not specifically indicated, the default access range is set to the "Top Scope".`,
  role_topScope_L0: `Default: Account's affiliated group.`,
  role_topScope_L1: 'Designated: Specified group.',
  role_audit: 'Usage log',
  role_audit_tooltip1: 'Off：You can only query your own records',
  role_audit_tooltip2: "On：You can check other people's records",
  role_audit_desc: 'Provide access to account log records.',
  role_audit_L0: `L0：Can only query the account's own usage record.`,
  role_audit_L1: 'L1：Can access usage records.',
  // 勤務派遣
  role_dashboardPermission: 'Mission dispatch',
  role_dashboard: 'Mission dispatch',
  role_dashboard_tooltip: 'Provide access to mission dispatch function to integrate and control on-site incidents and status.',
  role_dashboard_desc: 'Provide access to mission dispatch function to integrate and control on-site incidents and status.',
  role_dashboard_L0: 'L0：No permission. Cannot access mission dispatch page.',
  role_dashboard_L1: 'L1：Allow access mission dispatch.',
  role_dashboard_L2: 'L2：Allow access public equipment list.',
  // 影像管理
  role_videoPermission: 'Image management',
  role_liveView: 'Live view',
  role_liveView_tooltip1: 'General：You can watch real-time video',
  role_liveView_tooltip2: 'Advanced：You can watch real-time video, modify titles, control PTZ and other functions',
  role_liveView_desc: 'Provide access to live video, audio, GPS and other information',
  role_liveView_L1: 'L1：Allow access live video',
  role_liveView_L2: 'L2：Allow access public live video.',
  role_video: 'Historical video',
  role_video_title: 'Provides post-event image retrieval, playback and download.',
  role_video_desc: 'Provides post-event image retrieval, playback and download.',
  role_video_L1: 'L1：Allow access historical video.',
  role_video_L2: 'L2：Allow access public historical video',
  role_videoManagement: 'Video management',
  role_videoManagement_tooltip: 'Provides historical image management functions.',
  role_videoManagement_desc: 'Provides historical image management functions.',
  role_videoManagement_L1: 'L1：Allow modify historical video descriptions, retain/delete videos.',
  // 設備操控
  role_deviceCtrlPermission: 'Device Control',
  role_deviceCtrl: 'Device Control',
  role_deviceCtrl_tooltip: 'Provides device configuration and control functions.',
  role_deviceCtrl_desc: 'Provides device configuration and control functions.',
  role_deviceCtrl_L1: 'L1：Allow modify device descriptions such as video titles.',
  role_deviceCtrl_L2: 'L2：Allow adjust camera-related settings, PTZ control, quality settings, etc.',
  role_deviceCtrl_L3: 'L3：Allow set advanced parameters for the device.',
  role_deviceAISetting: 'Recognition Task',
  role_deviceAISetting_title: 'Provides device recognition task setting function.',
  role_deviceAISetting_desc: 'Provides device recognition task setting function.',
  role_deviceAISetting_L1: 'L1：Allow access recognition task settings.',
  // 語音通話
  role_voiceCallPermission: 'Voice Call',
  role_voiceCall: 'Voice intercom',
  role_voiceCall_tooltip: 'Can use voice call function',
  role_voiceCall_desc: 'Provides access to voice intercom service, including 1-to-1 and group call modes.',
  role_voiceCall_L1: 'L1：Allow access voice intercom, including 1-to-1 and group call.',
  role_voiceCall_L2: 'L2：Allow access public address book (user/device).',
  // 車辨事件
  role_lprEventPermission: 'Vehicle Recognition Event',
  // role_lprDashboard: 'Dashboard',
  role_lprDashboard: 'Real-time license plate event',
  role_lprDashboard_tooltip1:'General：Dashboard can display match/task/round-up events',
  role_lprDashboard_tooltip2: 'Advanced：Dashboard to display all events',
  role_lprDashboard_desc: 'Access to real-time license plate recognition events.',
  role_lprDashboard_L1: 'L1：Access to real-time matching events of license plates (including vehicle information, tasks, subscriptions, etc.).',
  role_lprDashboard_L2: 'L2：Access to publicly available real-time matching events of license plates (including vehicle information, tasks, subscriptions, etc.).',
  role_lprDashboard_L3: 'L3：Access to all real-time license plate events.',
  // role_lprEventAccess: 'Event query',
  role_lprEventAccess: 'Historical license plate events - General',
  role_lprEventAccess_tooltip1: 'General events that can query a single vehicle number',
  role_lprEventAccess_desc: 'Provide query to historical license plate event functions.',
  role_lprEventAccess_L1: 'L1：Access to general queries (only for public devices).',
  role_lprEventAdvancedAccess: 'Historical license plate events - Advanced',
  role_lprEventAdvancedAccess_tooltip1: 'You can use advanced conditions to query events',
  role_lprEventAdvancedAccess_desc: 'Provide query to historical license plate event functions.',
  role_lprEventAdvancedAccess_L1: 'L1：Access to advanced queries (only for private devices).',

  // 車號管理
  role_lprPermission: 'License Plate Management',
  // role_lprSummary: 'Basic enquiries',
  role_lprSummary: 'License Plate Inquiry',
  role_lprSummary_tooltip: 'Single vehicle information can be inquired',
  role_lprSummary_desc: 'Provide license plate query function for matching databases, including vehicle information, tasks, and subscriptions.',
  role_lprSummary_L1: 'L1：Access to global license plate data inquiry.',
  role_lprSummary_L2: 'L2：Access to group vehicle data inquiry.',
  role_lprSubscription: 'Subscription management',
  role_lprSubscription_tooltip: 'Can add/query/delete subscription content',
  role_lprSubscription_desc1: 'Provide subscription license plate management function.',
  role_lprSubscription_desc2: 'Subscriptions are grouped data.',
  role_lprSubscription_desc3: `When the front-end device recognizes the subscribed license plate, there will be no alert, but the system will immediately push a message to the notification app installed on the recipient's mobile phone.`,
  role_lprSubscription_L1: 'L1：Access to manage subscription license plates.',
  role_lprMissionLv: 'Mission management',
  role_lprMissionLv_tooltip1: 'General：General tasks can be set (assistance search)',
  role_lprMissionLv_tooltip2: 'Medium：tasks below medium can be set (interrogation/assistance search)',
  role_lprMissionLv_tooltip3: 'Advanced：The following tasks can be set (arrest/interrogation/assistance)',
  role_lprMissionLv_desc1: 'Provide task vehicle number management function.',
  role_lprMissionLv_desc2: 'The task is a global data.',
  role_lprMissionLv_desc3: `When the front-end device recognizes the task license plate, it will immediately trigger an alert and display a push message requesting assistance, which will also be pushed to the notification app installed on the recipient's mobile phone.`,
  role_lprMissionLv_L1: 'L1：Access to manage (search) tasks.',
  role_lprMissionLv_L2: 'L2：Access to manage (search/check) tasks.',
  role_lprMissionLv_L3: 'L3：Access to manage (search/check/arrest) tasks.',
  role_lprMission: 'Mission type',
  role_lprMission_title: '(預留，目前未使用)',
  role_lprMission_desc: '',
  role_lprMission_L1: 'L1：Access to manage mission typess.',
  // role_lprInfoLv: 'Vehicle information management',
  role_lprInfoLv: 'Vehicle information',
  role_lprInfoLv_tooltip: 'Manageable vehicle markings and notes',
  role_lprInfoLv_desc1: 'Provide vehicle information management function.',
  role_lprInfoLv_desc2: 'Vehicle information is global data.',
  role_lprInfoLv_L1: 'L1：Access to manage global vehicle information.',
  // role_lprInfo: 'Tag list management',
  role_lprInfo: 'Vehicle Tag',
  role_lprInfo_tooltip: 'Manageable tag list',
  role_lprInfo_desc1: 'Provide vehicle tag management function.',
  role_lprInfo_desc2: 'Vehicle tags are global data and serve as classification references for vehicle information.',
  role_lprInfo_desc3: 'A single vehicle information can have multiple vehicle tags set.',
  role_lprInfo_L1: 'L1：Access to manage global vehicle tags.',
  // role_lprAnnouncement: 'Announcement management',
  role_lprAnnouncement: 'Publish Notifications',
  role_lprAnnouncement_tooltip: 'Access to manage announcement content.',
  role_lprAnnouncement_desc1: 'Manageable announcement content',
  role_lprAnnouncement_desc2: 'Publish notifications are global data.',
  role_lprAnnouncement_desc3: 'Announcement messages are pushed to the front-end devices and played once by voice, with continuous display of scrolling text messages.',
  role_lprAnnouncement_L1: 'L1：Access to manage global publish notifications.',
  role_lprReport: 'Performance Reports',
  role_lprReport_tooltip: 'Provide performance report management function.',
  role_lprReport_desc1: 'Provide performance report management function.',
  role_lprReport_desc2: 'The system automatically generates performance reports every month based on the exported device list set.',
  role_lprReport_L1: 'L1：Access to performance reports.',
  role_lprReport_L2: 'L2：Access to manage performance report export devices.',
  // 物件辨識
  // role_orEventPermission: '物件辨識',
  // 人臉辨識
  role_frEventPermission: 'Facial Recognition',
  role_frDashboard: 'Real-time Person Events',
  role_frDashboard_desc: 'Provides real-time access to person events.',
  role_frDashboard_L1: 'L1：Can access real-time matched person events.',
  role_frDashboard_L2: 'L2：可存取公開人物即時比對符合事件。',
  role_frDashboard_L3: 'L3：可存取全部人物即時事件。',
  role_frEventAccess: 'Historical Person Events',
  role_frEventAccess_desc: 'Provides access to historical person events.',
  role_frEventAccess_L1: 'L1：Can access historical person events.',
  // 人物管理
  role_frPermission: 'Person Management',
  role_frInfoLv: 'Person Information',
  role_frInfoLv_desc1: 'Provides person information management functionality.',
  role_frInfoLv_desc2: 'Person information is global data.',
  role_frInfoLv_L1: 'L1：Can manage global person information.',
  role_frInfo: 'Person Tags',
  role_frInfo_desc1: 'Provides person tag management functionality.',
  role_frInfo_desc2: 'Person tags are global data and serve as a classification reference for vehicle information.',
  role_frInfo_desc3: 'Multiple person tags can be set for a single person information.',
  role_frInfo_L1: 'L1：Can manage global facial recognition tags.',
  // 帳號與權限
  role_accounrPermission: 'Account and Permissions',
  // role_account: 'Account management',
  role_account: 'User',
  role_account_tooltip: 'Manage user account related settings',
  role_account_desc1: 'Provide user account management function.',
  role_account_desc2: 'User accounts need to be verified and access levels to resources and functions are determined based on the role permissions configured.',
  role_account_L1: 'L1：View user accounts.',
  role_account_L2: 'L2：Manage user accounts.',
  role_device: 'Device',
  role_device_tooltip: 'Manage device account related settings',
  role_device_desc1: 'Provide device account management functions.',
  role_device_desc2: 'Device accounts need to be verified and the functions and resource reservation conditions provided are determined based on the authorization configured.',
  role_device_L1: 'L1：View device accounts.',
  role_device_L2: 'L2：Manage device accounts.',
  role_device_L3: 'L3：Configure device authorization.',
  role_device_L4: 'L4：Add device accounts.',
  // role_group: 'Group management',
  role_group: 'Group',
  role_group_tooltip: 'Manage group related settings',
  role_group_desc1: 'Provide group management functionality.',
  role_group_desc2: 'Groups are the basic unit of resource ownership and can be configured with users and devices, with derived resources (such as historical videos, events, logs, etc.) defaulting to the group.',
  role_group_desc3: 'Groups cannot be deleted.',
  role_group_L1: 'L1：View groups.',
  role_group_L2: 'L2：Manage groups.',
  // role_roleManagement: 'Role management',
  role_roleManagement: 'Role',
  role_roleManagement_tooltip: 'Managed roles',
  role_roleManagement_desc1: 'Provide role management functionality.',
  role_roleManagement_desc2: 'Roles are a collection of permissions that define the access scope and functional level of various system resources.',
  role_roleManagement_desc3: 'Operators with role management permissions can create or manage roles corresponding to access scopes or permission levels.',
  role_roleManagement_desc4: 'Roles belong to the global scope and cannot have the same name.',
  role_roleManagement_L1: 'L1：View roles.',
  role_roleManagement_L2: 'L2：Manage roles.',
  // 系統與服務
  role_systemAndServicePermission: 'System and services',
  // role_system: 'System management',
  role_system: 'System settings',
  role_system_tooltip: 'Modifiable system parameters',
  role_system_desc1: 'Provide system configuration management functionality.',
  role_system_desc2: 'Used to adjust system preferences and customize settings.',
  role_system_L1: 'L1：Allow manage system settings.',

  /* Role Notify / Message */
  role_notify_add_success: `Create new role successfully`,
  role_notify_add_info: `Create a new Role：<span style="${notify_style}">{name}</span>`,
  role_notify_add_fail: `Create new role fail`,
  role_notify_edit_success: 'Role is edited',
  // role_notify_edit_info: 'Edit role successfully',
  role_notify_edit_info: `Edit role <span style="${notify_style}">{name}</span> successfully`,
  role_notify_delete_confirm: 'Do you want to delete <span>{name}</span>?',
  role_notify_delete_success_title: 'Role deleted successfully.',
  role_notify_delete_success_msg: `Deleting role: <span style="${notify_style}">{name}</span>`,
  role_message_error_name_title: 'Unable to modify name',
  role_message_error_name_content: 'This name is already in use, please enter another name.',
  role_message_error_delete_title: 'Unable to delete role',
  role_message_error_delete_prefix: 'Please transfer first',
  role_message_error_delete_suffix: 'all accounts below to other roles',
  role_message_error_delete_transfer: 'Please transfer all users below <span>{name}</span> to other roles first.',

  /* 提示 */
  alarm_hint: 'Hint',
  alarm_announcement: 'Annoucement',
  alarm_info: 'Allowing the browser to play sound effects?',

  /* Dashboard: user tree */
  tree_search: 'Search Group / Device',
  tree_online: 'Online / follow devices',
  tree_online_all: 'Online/All',
  tree_select_group: 'Select only this group',
  tree_change_name: 'Change name',
  tree_change_video_title: 'Change video title',
  tree_change_group_name: 'Change group name',
  tree_push_talk: 'Push to Talk',
  tree_select_account: 'Select this device',
  tree_voice_call: 'Voice call',
  tree_view_device: 'View device',
  tree_view_user: 'View User',
  tree_view_account: 'View Account',
  tree_processing_info: 'processing ...',
  tree_no_data: 'No data',
  tree_contacts: 'contacts',

  /* Dashboard: video download */
  video_start_time: 'Start Time',
  video_end_time: 'End Time',
  video_length: 'Video length',
  video_size: 'Video size',
  video_date_placeholder: 'Please select a start and end time',
  video_select_all: 'Select All',
  video_unselect: 'Unselect Keep',
  video_keep_all: 'Keep All',
  video_keep_select: 'Keep selected',
  video_download_select: 'Download selected',
  video_delete_select: 'Delete selected',
  video_download: 'Download video',
  video_keep: 'Keep',
  video_none_delete: 'No videos to delete!',
  video_delete: 'Video deleted!',
  video_none_select: 'No selected videos!',
  video_set_keep: 'The video has been set to keep!',
  video_no_keep: 'The video no longer set to keep!',
  video_delete_data: 'Delete data',
  video_delete_checked: 'Are you sure you want to delete the checked data?',
  video_selected: 'Selected',
  video_total_of: 'A total of',
  video_of_records: 'records',

  /* Video Control Button */
  video_ctrlbtn_ptz: 'Remote lens control',
  video_ctrlbtn_time_axis: 'Historical video',
  video_search_none: 'Search no historical videos!',

  /* Dashboard: event cards */
  event_tab_all: 'All',
  event_tab_match: 'Match',
  event_tab_urgent: 'Urgent',
  event_tab_video: 'Video',
  event_show_position: 'Locate account in device list',
  evnet_show_history: 'History Record',
  event_source: 'Source',
  event_result: 'Matched result',
  event_notes: 'Notes',
  event_emergency: 'Emergency events',
  event_switch: 'Show all events',
  event_not_db: 'Not DB Events',
  event_chasing: 'Chased',
  event_sos: 'SOS',

  /* PTZ */
  ptz_move_speed: 'Moving Speed',
  ptz_zoom: 'Zoom',
  ptz_focus: 'Focus',
  ptz_preset: 'Preset List',
  ptz_preset_placeholder: 'New Preset',
  ptz_preset_setting_success: 'Preset setting successfully!',
  ptz_preset_setting_fail: 'Preset setting failed!',
  ptz_vertical_mirror: 'Vertical Mirror',
  ptz_horizontal_mirror: 'Horizontal Mirror',
  ptz_infrared: 'Infrared',
  ptz_high_sensitivity: 'High Sensitivity',
  ptz_tooltip_zoom_out: 'Zoom Out',
  ptz_tooltip_zoom_in: 'Zoom In',
  ptz_tooltip_open: 'Open',
  ptz_tooltip_close: 'Close',
  ptz_tooltip_auto: 'Auto',
  ptz_tooltip_auto_focus: 'Auto Focus',
  ptz_tooltip_near_focus: 'Near Focus',
  ptz_tooltip_far_focus: 'Far Focus',

  /* History: Search Bar */
  search_tab_history: 'Basic Search',
  search_tab_advanced_search: 'Adv. Search',
  search_cause: 'Reason for action',
  search_cause_placehold: "Investigating Li Xiaoming's drug case",
  search_plate_number_required: 'Plate Number',
  search_time_interval: 'Time Interval',
  search_select_date: 'Select Date',
  search_select_time: 'Select Time',
  search_time_placehold: 'Please select a start and end time',
  search_hint: 'Hint',
  search_hint_reason: 'Please enter the reason',
  search_hint_reason_length: 'The reason for the case must be less than or equal to 30 characters',
  search_hint_fill_number: 'Please fill in the "plate number"',
  search_hint_fill_device: 'Please select the "device"',
  search_hint_fill_time: 'Please select the "time interval"',
  search_hint_nodata: 'No data',
  search_export: 'Export Results',
  search_device: 'Device',
  search_device_required: 'Device(required)',
  search_device_placehold: 'Please Choose',
  search_device_unit: 'Department',
  search_plate_number: 'Plate Number',
  search_car_type: 'Car Type',
  search_car: 'Car',
  search_motocycle: 'Motocycle',
  search_event_category: 'Event Category',
  search_please_choose: 'Please Choose',
  search_all: 'All',
  search_subscription: 'Subscription',
  search_mission: 'Mission',
  search_mark: 'Mark',
  search_round_up: 'Round Up',
  search_not_specified: 'Not Specified',
  search_misjudgment: 'Misjudgment',
  search_non_misjudgment: 'Non-misjudgment',
  search_cofind: 'Co-find',
  search_check: 'Check',
  search_arrest: 'Arrest',
  search_vehicle_marking: 'Vehicle Marking',
  search_finished_status: 'Finished Status',
  search_finish: 'Finish',
  search_undone: 'Undone',
  search_total_of: 'Total',
  search_of_events: '',
  search_timeout_message: 'Too much content, please narrow your query',
  search_clear: 'Clear',
  search_adv_filter: 'Advanced Conditions',
  search_channel_id: 'Camera',

  /* History: History Events */
  history_event: 'History Events',
  history_event_lpr: 'License Plate',
  history_event_fr: 'Person',
  history_event_frid: 'Person ID',
  history_event_previous: 'Previous',
  history_event_next: 'Next',
  history_event_wheelzoom: 'Wheel Zoom',
  history_event_previous_tooltip: 'Previous Event (left key on keyboard)',
  history_event_next_tooltip: 'Next Event (right key on keyboard)',
  history_event_back_tooltip: 'Back to previous page (Esc)',
  history_event_map_goto: 'Open Google map',
  history_query_by_event: 'Query by event',
  history_device: 'Device',
  history_tag: 'Tag',
  history_mission: 'Mission',
  history_push_message: 'Push Message',
  history_channel: 'Channel',
  history_event_prev_page: 'Previous Page',
  history_event_next_page: 'Next Page',
  history_event_no: 'No.',
  history_event_pages: 'Pages',
  history_event_screen_capture: 'Screen Capture',

  /* History: 人物 */
  history_fr_name: 'Name',
  history_fr_id: 'ID',
  history_fr_age: 'Age',
  history_fr_tag: 'Tag',
  history_fr_remark: 'Remark',
  history_fr_group: 'Group',
  history_fr_device: 'Device',
  history_fr_gps: 'GPS position',
  history_fr_open_google_map: 'Open Google map',
  history_fr_event_category: 'Event Category',
  history_fr_cv: 'CV',

  /* History: 緊急 */
  history_device_group: 'Group',
  history_link_user: 'Link user',
  history_user_group: 'User group',

  /* QR Code Panel */
  qr_title: 'Download APP',

  /* Dashboard: tooltips */
  previous_video: 'Previous Video',
  next_video: 'Next Video',
  play: 'Play',
  pause: 'Pause',
  mute: 'Mute',
  unmute: 'Unmute',
  play_rate: 'Playback Rate',
  zoom_in: 'Zoom in',
  zoom_out: 'Zoom out',
  zoom_ratio: 'Zoom Ratio',
  show_only_events: 'Show only events for this device',
  download_video: 'Video download',
  full_screen: 'Full screen',
  jump_to_live: 'Jump to live screen',
  the_day_before: 'The day before',
  the_day_after: 'The day after',
  a_minute_ago: 'A minute ago',
  one_minute_after: 'One minute after',
  zoom_out_the_play: 'Zoom out the playback window',
  enlarge_the_play: 'Enlarge the playback window',
  mode_1x1: '1x1 mode',
  mode_2x2: '2x2 mode',
  mode_3x3: '3x3 mode',
  mode_4x4: '4x4 mode',
  reorder_device_list: 'Reorder',
  collapse_the_play: 'Collapse the playback window',
  expand_the_play: 'Expand the playback window',
  expand_event_list: 'Expand event list',
  collapse_event_list: 'Collapse event list',
  collapse_device_list: 'Collapse the device list',
  expand_device_list: 'Expand the device list',
  more: 'More',
  automatic_logout: 'Prevent automatic logout when idle',
  no_selected_user: 'No selected device',
  no_streaming: 'No Streaming Available',
  no_permission_all: 'No permission to watch all real-time video and hisotrical videos.',
  no_permission_live_view: 'No permission to watch real-time audio and video.',
  no_permission_video: 'No permission to watch historical videos.',
  no_live_stream: 'No live stream',
  no_video: 'No video available',
  video_pause: 'Video paused',
  mode_nxn_switch_hint: 'Please drop to device window for switch!',

  /* Account Management */
  am_title: 'Account and Permission',
  am_user: 'User',
  am_device: 'Device',
  am_group: 'Group',
  am_role: 'Role',
  am_search_account: 'Search account/account name',
  am_role_label: 'Role',
  am_edit_not_complete: 'Edit not complete',
  am_cancel_edit: 'Are you sure you want to cancel this edit?',
  am_map_search_hint: 'Search road or GPS',
  am_map_reset: 'Reset Coordinate',
  am_user_create: 'Create User',
  am_user_create_confirm: '使用者新增後將無法刪除, 您確定要新增嗎？',

  /* Account Management-device */
  am_search_device: 'Search device/device name',
  am_device_name: 'Name',
  am_device_video_name: 'Video Name',
  am_device_event_keep: 'Event Keep',
  am_device_video_keep: 'Video Keep',
  am_group_tree_search: 'Search group',
  am_device_keep_time: 'Keep Time',
  am_device_keep_time_tip: 'System default keep time.',
  am_device_keep_time_edit: 'Edit Keep Time',
  am_device_create: 'Create Device',
  am_device_create_confirm: 'Once devices are added, they cannot be deleted. Are you sure you want to add them?',
  am_device_type_default: 'General',
  am_device_type_bwc: 'Bodycam',
  am_device_type_patrol: 'Patrol',
  am_device_type_phone: 'Phone',
  am_device_type_ptz: 'PTZ Dome Camera',
  am_device_type_box: 'PTZ Box Camera',

  /* Account Management-group */
  am_search_group: 'Search group',
  am_group_title: 'Group',
  am_view_quota_title: 'View Quota',
  am_live_quota_title: 'Distribute Quota',
  am_quota_desc: 'Used / Distributed / ALL',
  am_quota_edit: 'Edit Quota',
  am_quota_editing: 'Editing',
  am_add_group: 'Add Group',
  am_parent_group_empty: 'Belonged group name',
  am_parent_group: 'Belonged group',
  am_parent_group_hint: 'Belonged group',
  am_group_name: 'Group Name',
  am_group_name_hint: 'New Group(1)',
  am_group_id: 'ID',
  am_group_updated_time: 'Updated Time',
  am_group_create_time: 'Created Time',
  am_add_group_fail: 'Create group fail.',
  am_add_group_pass: 'Group successfully created.',
  am_edit_group_fail: 'Update group fail',
  am_edit_group_pass: 'Group successfully updated.',
  am_quota_short_hint1: 'Group',
  am_quota_short_hint2: 'quota short',
  am_group_create_confirm: 'Group cannot be deleted once added. Are you sure you want to add it?',

  /* Account Management-role */
  am_role_helper_title: 'Role Management',
  am_role_helper_intro: 'Role is a collection of permissions designed to define the access scope and functional level of various system resources. Roles can be applied to user accounts to regulate the permission levels that the account can operate.',
  am_role_helper_desc1: 'Higher levels include access scope and functional items of lower levels.',
  am_role_helper_desc1_sub1: 'Access Scope (Scope): Levels range from low to high, with larger scope as the level increases.',
  am_role_helper_desc1_sub2: 'Functional Level (Level): Levels range from low to high, with more permissions as the level increases.',
  am_role_helper_desc2: '角色屬於全域，不可重名。',

  /* System Setting */
  system_global_setting: 'Global setting',
  system_custom_web: 'Custom web',
  system_backend_setting: 'Backend Setting',
  system_server_capability: 'Server Capability',
  system_aibox: 'AiBox Management',
  system_custom_system_name: 'System name',
  system_custom_idle_logout_time: 'Idle logout time',
  system_custom_logout_not: 'Do not logout',
  system_custom_app_download: 'Show APP download link',
  system_custom_manual_download: 'Show user manual loading point',
  system_custom_customize: 'Customer',
  system_custom_favicon: 'Tab favicon 32*32',
  system_custom_banner: 'Expanded banner 130*56',
  system_custom_logo: 'Collapse icon 56*56',
  system_custom_upload: 'Upload',
  system_custom_restore: 'Restore default',
  system_custom_restore_info: 'Restore the above icons setting to default values',

  /* System Setting - AiBoxMgr */
  setting_aibox_top_box: 'AiBox',
  setting_aibox_top_ip: 'IP',
  setting_aibox_top_count: 'Count',
  setting_aibox_top_abnormal_count: 'Abnormal',
  setting_aibox_page_start: 'Count',
  setting_aibox_page_end: 'record(s)',
  setting_aibox_page_total: 'Search Result',
  setting_aibox_total_of: 'DB has',
  setting_aibox_of_records: 'record(s)',
  setting_aibox_goto_page: 'Go to page',
  setting_aibox_state_normal: 'Normal',
  setting_aibox_state_abnormal: 'Abnormal',
  setting_aibox_table_name: 'AiBox',
  setting_aibox_table_ip: 'IP',
  setting_aibox_table_udid: 'UDID',
  setting_aibox_table_status: 'Task',
  setting_aibox_table_temp: 'Temperature',
  setting_aibox_table_cpu: 'CPU',
  setting_aibox_table_gpu: 'GPU',
  setting_aibox_table_mem: 'MEM',
  setting_aibox_table_hd: 'HDD',
  setting_aibox_table_recognition_src: 'Recognition',
  setting_aibox_table_updated_time: 'Updated Time',
  setting_aibox_delete_title: 'Delete AiBox',
  setting_aibox_task_device: 'Device',
  setting_aibox_task_recognition_type: 'Recognition Category',
  setting_aibox_task_recognition_state: 'Recognition Status',
  setting_aibox_task_status_0: 'In Recognition',
  setting_aibox_task_status_10: 'Device Not Published',
  setting_aibox_task_status_11: 'Streaming Connecting',
  setting_aibox_task_status_12: 'Insufficient Authorization',
  setting_aibox_task_status_13: 'System Error',
  setting_aibox_task_status_14: 'Configuration Profile Version Not Supported',
  setting_aibox_task_status_15: 'Recognition Function Not Supported',
  setting_aibox_task_recognition_or: 'Object',
  setting_aibox_task_recognition_lpr: 'Car',
  setting_aibox_delete_aibox: 'Do you want to delete the AiBox?',
  setting_aibox_delete_notify_title: 'Remove AiBox',
  setting_aibox_delete_pass: 'Remove AiBox success',
  setting_aibox_delete_fail: 'Remove AiBox failed',
  setting_aibox_delete_aibox_task: 'Do you want to delete the AiBox task?',
  setting_aibox_task_delete_notify_title: 'Remove AiBox task',
  setting_aibox_task_delete_pass: 'Remove AiBox task success',
  setting_aibox_task_delete_fail: 'Remove AiBox task failed',

  /* AI Box */
  ai_recognition_setting: 'Recognition Setting',
  ai_local_recognition: 'Local recognition',
  ai_person_recognition :'Person recognition',
  ai_could_recognition: 'Cloud recognition',
  ai_object_recognition: 'Object recognition',
  ai_object: 'Object',
  ai_license_plate_recognition: 'License plate recognition',
  ai_update_time: 'Update time',
  ai_box: 'AI Box',
  ai_task_add: 'Adding',
  ai_task_edit: 'Editing',
  ai_task_delete: 'deleting',
  ai_status_0: 'Recognizing',
  ai_status_10: 'Device not released',
  ai_status_11: 'streaming connection',
  ai_status_12: 'Insufficient number of authorizations',
  ai_status_13: 'systematic error',
  ai_status_14: 'Profile version not supported',
  ai_status_15: 'Recognition function is not supported',
  ai_try_connect: 'Try to connect to stream',
  ai_select_aibox: 'Please select AI box',
  ai_temperature: 'Temperature',
  ai_memory: 'MEM',
  ai_hard_disk: 'HDD',
  ai_resources: 'Recognition resources',
  ai_other_resources: 'Other resources',
  ai_add_notification_task: 'Add a notification task',
  ai_image_preview: 'Image preview',
  ai_set_time: 'Set time',
  ai_recg_fps_level: 'Recognition FPS level',
  ai_repeat_interval: 'Repeat interval',
  ai_stay_sec: 'Stay seconds',
  ai_category: 'Category',
  ai_setter: 'Setter',
  ai_notice: 'Remark',
  ai_receiver: 'Subscriber',
  ai_unit_name_placeholder: 'Department or name',
  ai_show_checked: 'Show checked',
  ai_edit_receiver: 'Edit subscriber',
  ai_mode: 'Mode',
  ai_object_detect: 'Object detect',
  ai_motion_detect: 'Motion detect',
  ai_detect_target_threshold: 'Detection target threshold',
  ai_motion_level: 'Motion level',
  ai_advanced_settings: 'Advanced settings',
  ai_person_level: 'Person',
  ai_car_level: 'Car',
  ai_bike_level: 'Motor',
  ai_truck_level: 'Truck',
  ai_bus_level: 'Bus',
  ai_warning_area: 'Warning area',
  ai_delete_data: 'Delete data',
  ai_sure_to_delete: 'Are you sure you want to delete this identification?',
  ai_device: 'Device',
  ai_recognition_category: 'Recognition category',
  ai_recognition_status: 'Recognition status',
  ai_level_0: 'Close',
  ai_level_1: 'Lowest',
  ai_level_2: 'Low',
  ai_level_3: 'Medium',
  ai_level_4: 'High',
  ai_level_5: 'Highest',
  ai_motion_level_1: 'Low',
  ai_motion_level_2: 'Medium',
  ai_motion_level_3: 'High',
  ai_insufficient_recognition_resources: 'Insufficient recognition resources.',
  ai_no_boxes_available: 'Sorry, there are no identification boxes available.',
  ai_edit_not_complete: 'Edit not complete',
  ai_cancel_edit: 'Are you sure you want to cancel this edit?',
  ai_area1: 'Block 1',
  ai_area2: 'Block 2',
  ai_area3: 'Block 3',
  ai_new_recognition: 'New recognition task',
  ai_recog_task_add_successfully: 'Recognition task added successfully',
  ai_roi_set: 'Adjust ROI range',
  ai_roi_lock: 'Lock ROI range',
  ai_tooltip_roi_reset: 'Return to default range',
  ai_tooltip_or: 'Object Detection: Send a notification after detecting the checked object.',
  ai_tooltip_md: 'Motion detection: Send a notification after detecting a moving object in the screen.',
  ai_tooltip_threshold: 'The lower the threshold, the more objects on the screen can be found, but there is also the possibility of reducing the accuracy.',
  ai_tooltip_motion: 'The higher the sensitivity, the more sensitive to moving changes in the screen.',
  ai_tooltip_recg_fps_level: 'The recognition frequency per second, it is recommended that the faster the object moves, the higher the recognition frequency setting.',
  ai_tooltip_repeat_interval: 'When the event occurs repeatedly, the notification will be sent again and the event will be uploaded after the interval of this set time.',
  ai_tooltip_stay_sec: 'How long does the object stay in the warning zone before reporting.',
  ai_tooltip_warning_area: 'A polygon with a maximum of 8 points, when the object touches the warning zone, a notification will be issued.',

  /* AI Box - recognition setting */
  ai_tag: 'Tags',
  ai_remark: 'Remark',
  ai_editor: 'Editor',
  ai_expert_mode: 'Export mode',
  ai_full_face_mode: 'Full face mode',
  ai_mask_mode: 'Mask mode',
  ai_param_source: 'Parameter source',
  ai_global_config: 'System default',
  ai_user_config: 'Device custom',
  ai_param_source_tip: '<strong style="color: #FFE99F;">System Default</strong>: The default recognition parameter values set by the system, which cannot be modified. <br/><strong style="color: #FFE99F;">Device Customization</strong>: Depending on the device usage requirements, the recognition parameter values can be customized.',
  ai_enable: 'Enable',
  ai_enable_tip: 'Face recognition functionality can be enabled or disabled.',
  ai_model_country: 'Applicable country',
  ai_model_country_tip: 'Select the country to support license plate recognition',
  ai_face_size_ratio: 'Face size ratio',
  ai_face_size_ratio_tip: 'The minimum detected face size as a percentage of the screen.',
  ai_detect_mode: 'Detect mode',
  ai_precise_detect: 'Precise detect',
  ai_quick_detect: 'Quick detect',
  ai_detect_mode_tip: '<strong style="color: #FFE99F;">Precise Matching</strong>: Can output results with higher accuracy; <br/><strong style="color: #FFE99F;">Fast Matching</strong>: Can quickly output recognition results.',
  ai_trigger_score: 'Event score',
  ai_candidate_score: 'Candidate score',
  ai_full_face_trigger_score_tip: '',
  ai_full_face_candidate_score_tip: 'Similarity threshold in full face mode. The lower the threshold, the more matching results there will be, but it may also potentially reduce accuracy.',
  ai_mask_trigger_score_tip: 'Similarity threshold in mask mode. The lower the threshold, the more matching results there will be, but it may also potentially reduce accuracy.',
  ai_mask_candidate_score_tip: 'Threshold for candidates in mask mode. The lower the threshold, the more candidates there will be for reference.',
  ai_detect_threshold_level: 'Noise Car threshold',
  ai_detect_threshold_level_tip: 'Face detection threshold. The lower the threshold, the more faces can be detected in the image, but it may also potentially reduce accuracy.',
  ai_event_post_mode: 'Event upload mode',
  ai_event_post_mode_tip: '<strong style="color: #FFE99F;">Matched Only</strong>: Only events that match will be uploaded; <br/><strong style="color: #FFE99F;">All Recognized</strong>: All recognition results will be uploaded.',
  ai_event_match: 'Event match',
  ai_event_all: 'All',
  ai_notify_filter_mode: 'Event notify mode',
  ai_notify_filter_mode_tip: '<strong style="color: #FFE99F;">Matched Only</strong>: Only events that match will be reported; <br/><strong style="color: #FFE99F;">All Recognized</strong>: All events will be reported. <br/><strong style="color: #FFE99F;">Not Matched</strong>: Only events that do not match will be reported. <br/><strong style="color: #FFE99F;">Not reported</strong>: No events will be reported.',
  ai_notify_match: 'Event match',
  ai_notify_all: 'All',
  ai_notify_not_match: 'Not match',
  ai_notify_none: 'Not notify',
  ai_event_duration: 'Event repeat interval',
  ai_noise_event_duration: '噪音事件重複間隔',
  ai_event_duration_tip: 'When an event recurs, an upload event will be triggered again only after this set time interval has elapsed.',
  ai_noise_event_duration_tip: '當噪音車輛事件重複發生時, 間隔此設定時間後才會再次發出上傳事件',
  ai_repost_data_store_limit: 'Repost event retention limit',
  ai_repost_data_store_limit_tip: 'Number of events retained by the device when unable to upload events.',
  ai_lpr_frequency: 'Recognition frequency',
  ai_lpr_frequency_tip: 'The number of frames recognized per second',
  ai_lpr_size_ratio: 'Minimum License Plate Ratio',
  ai_lpr_size_ratio_tip: 'The detected minimum license plate size as a percentage of the screen',
  ai_lpr_detect_mode: 'Detection mode',
  ai_noise_car_decibel_threshold_tip: '分貝值達閾值時, 發出噪音車事件',
  ai_confirm_count: 'Confirm count',
  ai_confirm_count_tip: 'The larger the value, the less likely it is to output the result, but the accuracy of the output result will be higher',
  ai_accept_diff_num: 'Acceptable number difference',
  ai_accept_diff_num_tip: 'The number of digits to correct the recognition result',
  ai_indent: 'Recoginition boundary',
  ai_indent_tip: 'If the detected license plate exceeds this boundary, the recognition result will not be output',
  ai_is_complete_mode: 'Complete mode',
  ai_is_complete_mode_tip: 'If enabled, only complete identification results will be output. Otherwise, only identification results will be output',
  ai_roi: 'Adjust recognition range.',
  ai_roi_tip: 'You can drag the borders to adjust the recognition range.',
  ai_event_unit: 'entry',
  ai_low: 'Low',
  ai_medium: 'Medium',
  ai_high: 'High',
  ai_setting_success: 'Settings saved successfully.',
  ai_setting_fail: 'Settings failed',

  /* AI Box - LPR */
  ai_lpr_tag: 'LPR Tags',

  /* 人辨資料庫 */
  fr_title: '人物管理',
  fr_info: '人物資訊',
  fr_filter_name: 'Name',
  fr_filter_name_hint: '王小明',
  fr_filter_tag: 'Tags',
  fr_filter_time: 'Time Interval',
  fr_filter_id: 'ID Card',
  fr_filter_id_hint: 'Z123456789',
  fr_filter_feature: 'Feature',
  fr_filter_enabled: 'FR Info',
  fr_filter_tooltip: 'Adv. Filter',
  frInfo_idCard_filter: 'ID NO.',
  frInfo_feature_filter:'FR Feature',
  frInfo_feature_filter_all: '全部',
  frInfo_feature_filter_0:'無啟用',
  frInfo_feature_filter_1:'任一啟用',
  frInfo_feature_filter_2:'任一停用',
  frInfo_enabled_filter:'FR Info',
  frInfo_enabled_filter_all: '全部',
  frInfo_enabled_filter_0:'停用',
  frInfo_enabled_filter_1:'啟用',
  fr_new_fr_tooltip: 'Create FR',
  fr_db_info_tooltip: 'DB Info',
  fr_tag_mgr_tooltip: 'FR Tags',

  fr_tag_mgr_title: 'FR Tag Manager',
  fr_tag_add: 'Create FR Tag',
  fr_tag_add_placeholder: 'Create Tag',
  fr_tag_duplicate_hint: 'The tag has existed, please input again.',
  fr_tag_total: '{total} tag(s)',
  fr_tag_total_count: '{count}/{total} tag(s)',
  fr_tag_add_hint: '新增後無法刪除標記，確定新增？',
  fr_tag_add_pass: 'Create FR tag successed.',
  fr_tag_add_fail: 'Create FR tag faile',

  fr_db_info_title: 'FR DB Info',
  fr_db_info_data_cnt: '{count}',
  fr_db_info_data_latest_sn: '最新序號：',
  fr_db_info_data_update_time: '更新時間：',
  fr_db_info_db: '資料庫',
  fr_db_info_data_fr_cnt: '人物筆數：',
  fr_db_info_feature: '特徵值',
  fr_db_info_data_used: '已使用{percent}%',
  fr_db_info_data_reg_cnt: '註冊筆數：',
  fr_db_info_data_quota: '授權配額：',
  fr_db_info_db_package: '資料庫封裝',
  fr_db_info_data_version: 'Version：',

  fr_table_photo: '註冊圖',
  fr_table_name: 'Name',
  fr_table_id: 'ID Card',
  fr_table_tag: 'Tag',
  fr_table_update_time: 'Update Time',
  fr_table_create_time: 'Create Time',
  fr_table_empty_hint: 'Please input query conditions',
  fr_table_delete_pass: 'Delete Succeed',
  fr_table_delete_fail: 'Delete Failed',

  fr_info_data_title: 'FR Info',
  fr_info_data_enable: 'Enable',
  fr_info_data_disable: 'Disable',
  fr_info_data_name: 'Name',
  fr_info_data_gender: 'Gender',
  fr_info_data_id: 'ID',
  fr_info_data_age: 'Age',
  fr_info_data_age_edit: 'Borthday',
  fr_info_data_tag: 'Tag',
  fr_info_data_note: 'Note',
  fr_more: 'MORE',
  fr_less: 'LESS',
  fr_info_data_phone: 'Phobe',
  fr_info_data_birth_place: 'Birth Place',
  fr_info_data_city: 'City',
  fr_info_data_address: 'Address',
  fr_info_data_job: 'Job',
  fr_info_photo_title: '註冊圖',
  fr_info_photo_add: '新增註冊圖',
  fr_info_other_title: 'Data',
  fr_info_other_src: 'Source',
  fr_info_other_src_0: '自建',
  fr_info_other_src_1: '匯入',
  fr_info_other_update_time: 'Update Time',
  fr_info_other_create_time: 'Create Time',
  fr_info_data_required: '必填',
  fr_info_data_phone_hint: '僅允許 數字、加號、減號、括弧',
  fr_info_data_gender_0: '其他',
  fr_info_data_gender_1: '男',
  fr_info_data_gender_2: '女',
  fr_info_data_gender_9: '未知',
  fr_feature_1: '特徵值無效',
  fr_feature_2: '特徵值不可用',
  fr_feature_3: '特徵值較差',
  fr_feature_4: '特徵值可用',
  fr_feature_5: '特徵值最佳',
  fr_mask_0: 'No mask',
  fr_mask_1: 'Had mask',
  fr_action_set_avatar: 'Set Acatar',
  fr_action_download_photo: 'Download Photo',
  fr_action_delete_feature: 'Delete Feature',
  fr_no_birthday: "(Year/Month/Day)",
}

// console.log(`en:`, Object.keys(en).length)

export default en
