// 帳號編輯模式
export const euEditMode = {
  none: 0,
  add: 1,
  multiSelect: 2,
  editView: 3,
  editing: 4,
  // delete: 5,
}

// 帳號使用狀態
export const euUsedState = {
  disable: 0,
  enabled: 1,
  locked: 2
}

// 帳號公開私有
export const euPublic = {
  public: 1, // 因應後台來的資料, 只有 0/1
  private: 0,
}
export const publicOptions = Object.keys(euPublic).map((key) => {
  const value = euPublic[key]
  return { label: `account_public_${value}`, value }
})

// 強制 2FA
export const euForced2fa = {
  on: 1, // 強制
  off: 0, // 按需 default
}
export const forced2faOptions = Object.keys(euForced2fa).map((key) => {
  const value = euForced2fa[key]
  return { label: `account_forced2fa_${value}`, value }
})

// 帳號勾稽
export const euLinkMode = {
  not_link: 0, // 不勾稽
  bovilive: 1, // BoviLive, 不強制
  bovilive_force: 2, // BoviLive, 強制
  npa_sso: 3, // NPA, 不強制
  npa_sso_force: 4 // NPA, 強制
}
export const linkedModeOptions = Object.keys(euLinkMode).map((linkMode) => {
  const value = euLinkMode[linkMode]
  return {
    label: `account_linkmode_${value}`,
    value,
  }
})

// 參數設定
export const euDefaultSetting = {
  global: 1, // default
  user: 0
}
export const defaultSettingOptions = Object.keys(euDefaultSetting).map(
  (setting) => {
    const value = euDefaultSetting[setting]
    return {
      label: `account_defaultSetting_${value}`,
      value
    }
})

// 浮水印
export const euWaterMark = {
  on: 1,
  off: 0, // default
}
export const watermarkOptions = Object.keys(euWaterMark).map((onOff) => {
  const value = euWaterMark[onOff]
  return {
    label: `account_watermark_${value}`,
    value
  }
})

// -----------------------------------------------------------------------------
// 帳號與權限：帳號各狀態
export const all = -1

// 公開狀態
const euPublicSt = {
  all,
  public: euPublic.public, // 因應後台來的資料, 只有 0/1
  private: euPublic.private
}
const publicSt = {
  key: 'public',
  options: Object.keys(euPublicSt).map((option) => {
    return {
      label: option,
      value: euPublicSt[option],
      str: euPublicSt[option] === all ? option : euPublicSt[option]
    }
  }),
  value: euPublicSt.all
}

// 勾稽
const euLinkModeSt = {
  all,
  bovilive_force: euLinkMode.bovilive_force, // BoviLive, 強制
  bovilive: euLinkMode.bovilive, // BoviLive, 不強制
  npa_sso_force: euLinkMode.npa_sso_force, // NPA, 強制
  npa_sso: euLinkMode.npa_sso, // NPA, 不強制
  not_link: euLinkMode.not_link // 不勾稽
}
const linkModeSt = {
  key: 'linkmode',
  options: Object.keys(euLinkModeSt).map((option) => {
    return {
      label: option,
      value: euLinkModeSt[option],
      str: euLinkModeSt[option] === all ? option : euLinkModeSt[option]
    }
  }),
  value: euPublicSt.all
}

export const userFilters = [publicSt]
export const deviceFilters = [publicSt, linkModeSt]

export const getGpsStr = (lat, lng) => {
  if (lat && lng) {
    return `${lat.toFixed(7)},${lng.toFixed(7)}`
  } else if (!lat && lng) {
    return `0,${lng.toFixed(7)}`
  } else if (lat && !lng) {
    return `${lat.toFixed(7)},0`
  } else {
    return `0,0`
  }
}

export const getDeviceModelIcon = (id) => {
  let icon = id

  // 處理共用 icon
  switch (Number(id)) {
    case 10: // BoviCam-BZ (箱型PTZ)
    case 20: // BoviCam-S (球型PTZ)
    case 30: // BoviCam-U (微型)
    case 40: // BoviCam-P (HDMI型)
    case 50: // BoviCam-X (偽裝型)
    case 100: // AiCam-BZ (箱型PTZ)
    case 110: // AiCam-S (球型PTZ)
    case 120: // AiCam-U (微型)
    case 130: // AiCam-BF (箱型)
    case 131: // AiCam-BFV (箱型) 分貝計
    case 140: // AiCam-X (偽裝型)
    case 500: // FaceArk-XX (FaceArk)
    case 2100: // Patrol-Car(iOS), 有 SOS
    case 2200: // Patrol-Moto(iOS), 有 SOS
      break
    case 200: // BWC-W240 (穿戴式-觸控款), 有 SOS
    case 210: // (穿戴式-觸控款5G)
    case 220: // BWC-W250 (穿戴式-OLED)
      icon = `200`
      break
    case 1000: // BoviCast (Android), 有 SOS
    case 2000: // BoviCast FR (iOS), 有 SOS
    case 2010: // BoviCast (iOS), 有 SOS
      icon = `2000`
      break
    case 3000: // Mac Patrol
    default:
      icon = '0' // 有 SOS
  }

  return icon
}

// -----------------------------------------------------------------------------

// 帳號與權限：詳細欄位
const accountItems = [
  // {key, title, forUserCreate, forUser, forDeviceCreate, forDevice, forDeviceMulti, value, editValue, canEdit, required, ui, options, icon, helper}
  // options: ui === 'dropdown' 才會生效
  // 群組與帳號
  {
    title: 'account_detail_group_accound' /*群組與帳號*/,
    forUserCreate: true,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    // forDevice: false,
    // forDeviceMulti: false
  },
  {
    key: 'group',
    title: 'account_detail_group' /*群組*/,
    forUserCreate: true,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    // forDevice: false,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'tree',
    icon: require(`@/assets/icons/Group.svg`)
  },
  {
    key: 'id',
    title: 'account_detail_id' /*ID 帳號*/,
    forUserCreate: true,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    // forDevice: false,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'text',
    icon: require(`@/assets/icons/id.svg`)
  },
  // 密碼
  {
    title: 'account_detail_password' /*密碼*/,
    forUserCreate: true,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    // forDevice: false,
    // forDeviceMulti: false,
  },
  {
    key: 'password',
    title: 'account_password' /*密碼*/,
    forUserCreate: true,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    // forDevice: false,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'password',
    icon: require(`@/assets/icons/key.svg`)
  },
  {
    key: 'password_confirm',
    title: 'account_confirm_password' /*確認密碼*/,
    forUserCreate: true,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    // forDevice: false,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'password',
    icon: require(`@/assets/icons/key.svg`)
  },
  // 名稱
  {
    title: 'account_detail_name' /*名稱*/,
    forUserCreate: true,
    forUser: true,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    // forDeviceMulti: false
  },
  {
    key: 'info.name',
    title: 'account_label' /*帳號名稱*/,
    forUserCreate: true,
    forUser: true,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: true,
    ui: 'text',
    icon: require(`@/assets/icons/account_detail_name.svg`)
  },
  {
    key: 'video.title',
    title: 'account_video_title' /*影片標題*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: true,
    ui: 'text',
    icon: require(`@/assets/icons/account_detail_video_title.svg`)
  },
  // 屬性
  {
    title: 'account_detail_property' /*屬性*/,
    forUserCreate: true,
    forUser: true,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    // forDeviceMulti: false
  },
  {
    key: 'public',
    title: 'account_public_title' /*公開狀態*/,
    forUserCreate: true,
    forUser: true,
    forUserMulti: true,
    forDeviceCreate: true,
    forDevice: true,
    forDeviceMulti: true,
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'dropdown',
    options: publicOptions,
    icon: require(`@/assets/icons/account_detail_public.svg`)
  },
  {
    key: 'kind',
    title: 'account_type' /*帳號類型*/,
    forUserCreate: true,
    forUser: true,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'dropdown',
    options: null, // 重頁面填
    icon: require(`@/assets/icons/account_detail_category.svg`)
  },
  {
    key: 'role',
    title: 'account_role' /*角色*/,
    forUserCreate: true,
    forUser: true,
    forUserMulti: true,
    // forDeviceCreate: false,
    // forDevice: false,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'dropdown',
    options: null, // 重頁面填
    icon: require(`@/assets/icons/account_detail_role.svg`),
    helper: true
  },
  {
    key: '2faMode',
    title: 'account_forced_2fa' /*(強制)雙重驗證*/,
    forUserCreate: true,
    forUser: true,
    forUserMulti: true,
    // forDeviceCreate: false,
    // forDevice: false,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: true,
    required: false,
    ui: 'dropdown',
    options: forced2faOptions,
    icon: require(`@/assets/icons/account_detail_2fa.svg`),
    helper: true
  },
  {
    key: 'deviceModelId',
    title: 'account_device_model' /*設備類型*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    forDeviceMulti: true,
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'dropdown',
    options: null, // 重頁面填
    icon: require(`@/assets/icons/account_detail_category.svg`)
  },
  {
    key: 'defaultSetting',
    title: 'account_default_setting' /*參數來源*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: true,
    ui: 'dropdown',
    options: defaultSettingOptions,
    icon: require(`@/assets/icons/account_detail_source.svg`)
  },
  {
    key: 'watermarkEnabled',
    title: 'account_watermark' /*浮水印*/,
    forUserCreate: true,
    forUser: true,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: true,
    ui: 'dropdown',
    options: watermarkOptions,
    icon: require(`@/assets/icons/watermark.svg`)
  },
  {
    key: 'language',
    title: 'account_lang' /*語系*/,
    forUserCreate: true,
    forUser: true,
    // forUserMulti: false,
    // forDeviceCreate: false,
    // forDevice: false,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: true,
    required: false,
    ui: 'dropdown',
    options: [],
    icon: require(`@/assets/icons/account_detail_lang.svg`)
  },
  {
    key: 'timezone',
    title: 'account_tz' /*時區*/,
    forUserCreate: true,
    forUser: true,
    // forUserMulti: false,
    // forDeviceCreate: false,
    // forDevice: false,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: true,
    required: false,
    ui: 'dropdown',
    options: [],
    icon: require(`@/assets/icons/account_detail_tz.svg`)
  },
  {
    key: 'kind',
    title: 'account_from' /*帳號來源*/,
    // forUserCreate: false,
    forUser: true,
    // forUserMulti: false,
    // forDeviceCreate: false,
    // forDevice: false,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: false,
    ui: 'text',
    icon: require(`@/assets/icons/account_detail_source.svg`)
  },
  // 資料
  {
    title: 'account_keep_data' /*資料*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    // forDeviceMulti: false
  },
  // {
  //   key: 'lprKeepDay',
  //   title: 'account_lpr_keep' /*(車辨)事件保留*/,
  //   // forUserCreate: false,
  //   // forUser: false,
  //   // forUserMulti: false,
  //   forDeviceCreate: true,
  //   forDevice: true,
  //   // forDeviceMulti: false,
  //   value: '',
  //   editValue: '',
  //   canEdit: true,
  //   ui: 'dropdown',
  //   options: [],
  //   icon: require(`@/assets/icons/clock-solid.svg`)
  // },
  {
    key: 'video.keepDay',
    title: 'account_keep_day' /*保留時間*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: true,
    required: true,
    ui: 'dropdown',
    options: [],
    icon: require(`@/assets/icons/clock-solid.svg`)
  },
  // 勾稽
  {
    title: 'account_detail_linkmode_title' /*勾稽*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    // forDeviceMulti: false
  },
  {
    key: 'linkedMode',
    title: 'account_linkmode_title' /*勾稽模式*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    forDeviceMulti: true,
    value: '',
    editValue: '',
    canEdit: true,
    ui: 'dropdown',
    options: linkedModeOptions,
    icon: require(`@/assets/icons/account_detail_category.svg`)
  },
  {
    key: 'linkedUserId',
    title: 'account_linkmode_group' /*群組*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    // forDeviceCreate: false,
    forDevice: true,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: false,
    ui: 'tree',
    icon: require(`@/assets/icons/Group.svg`)
  },
  {
    key: 'linkedUserId',
    title: 'account_linkmode_user' /*使用者*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    // forDeviceCreate: false,
    forDevice: true,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: false,
    ui: 'text',
    icon: require(`@/assets/icons/user.svg`)
  },
  // 最新活動紀錄
  {
    title: 'account_last_action' /*最新活動紀錄*/,
    // forUserCreate: false,
    forUser: true,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    // forDeviceMulti: false
  },
  {
    // key: '',
    title: 'account_ip_source' /*IP來源*/,
    // forUserCreate: false,
    forUser: true,
    // forUserMulti: false,
    // forDeviceCreate: false,
    forDevice: true,
    // forDeviceMulti: false,
    value: '',
    canEdit: false,
    ui: 'text',
    icon: require(`@/assets/icons/account_detail_source.svg`)
  },
  {
    key: 'lastLogin',
    title: 'account_login_time' /*(登入)時間*/,
    // forUserCreate: false,
    forUser: true,
    // forUserMulti: false,
    // forDeviceCreate: false,
    forDevice: true,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: false,
    ui: 'text',
    icon: require(`@/assets/icons/clock-solid.svg`)
  },
  {
    key: 'gps',
    title: 'account_gps' /*GPS位置*/,
    // forUserCreate: false,
    // forUser: false,
    // forUserMulti: false,
    forDeviceCreate: true,
    forDevice: true,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: true,
    ui: 'text',
    icon: require(`@/assets/icons/gps.svg`),
    helper: true
  },
  // 時間
  {
    title: 'account_time' /*時間*/,
    // forUserCreate: false,
    forUser: true,
    // forUserMulti: false,
    // forDeviceCreate: false,
    forDevice: true,
    // forDeviceMulti: false
  },
  {
    key: 'updatedTime',
    title: 'account_updated_time' /*修改時間*/,
    // forUserCreate: false,
    forUser: true,
    // forUserMulti: false,
    // forDeviceCreate: false,
    forDevice: true,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: false,
    ui: 'text',
    icon: require(`@/assets/icons/clock-solid.svg`)
  },
  {
    key: 'registerTime',
    title: 'account_register_time' /*創建時間*/,
    // forUserCreate: false,
    forUser: true,
    // forUserMulti: false,
    // forDeviceCreate: false,
    forDevice: true,
    // forDeviceMulti: false,
    value: '',
    editValue: '',
    canEdit: false,
    ui: 'text',
    icon: require(`@/assets/icons/clock-solid.svg`)
  }
]

// User
export const createUserItems = accountItems.filter(
  ({ forUserCreate }) => forUserCreate
)
export const userItems = accountItems.filter(({ forUser }) => forUser)
export const multiUserItems = accountItems.filter(
  ({ forUserMulti }) => forUserMulti
)

// Device
export const createDeviceItems = accountItems.filter(
  ({ forDeviceCreate }) => forDeviceCreate
)
export const deviceItems = accountItems.filter(({ forDevice }) => forDevice)
export const multiDeviceItems = accountItems.filter(
  ({ forDeviceMulti }) => forDeviceMulti
)
